import {Component, OnDestroy, OnInit} from '@angular/core';
import {ToolbarService} from "../../../../../core/services/toolbar.service";
import {AlertComponent} from "../../../../../dialogs/alert/alert.component";
import {MatDialog} from "@angular/material/dialog";
import {ApiService} from "../../../../../core/services/api.service";
import {Router} from "@angular/router";
import {OrganizationService} from "../../../../../core/services/organization.service";

@Component({
  selector: 'app-invite-member-by-email',
  templateUrl: './invite-member-by-email.component.html',
  styleUrls: ['./invite-member-by-email.component.scss']
})
export class InviteMemberByEmailComponent implements OnInit, OnDestroy {

  email = "";

  isProcessing = false;

  constructor(private toolbarService: ToolbarService, private dialog: MatDialog, private apiService: ApiService, private router: Router, private organizationService: OrganizationService) {
  }

  ngOnInit(): void {
    this.toolbarService.setHierarchy([
      {title: "Mitglieder", path: "/home/members"},
      {title: "Mitglied hinzufügen", path: "/home/members/add"}
    ]);
    this.toolbarService.setPageTitle("E-Mail Einladung");
  }

  ngOnDestroy(): void {
    this.toolbarService.resetHierarchy();
  }

  sendInvitation(): void {
    if (this.email.trim() == "") {
      this.showAlert("Eingabe ungültig", "Bitte überprüfe deine Eingabe und versuche es nochmal.");
      return;
    }

    const organizationId = this.organizationService.getSelectedOrganization()?.id ?? 0;

    this.isProcessing = true;

    this.apiService.memberAddEmailInvitation({
      organization_id: organizationId,
      email: this.email
    }, () => {
      this.showAlert("Einladung gesendet", "Die Einladung wurde erfolgreich gesendet.", () => {
        this.router.navigateByUrl("/home/members/add");
      });
    }, error => {

      switch (error) {
        case "permission-denied":
          this.showAlert("Zugriff verweigert", "Du hast nicht ausreichend Berechtigungen diese Aktion auszuführen.");
          break;
        case "not-licensed":
          this.showAlert("Kontingent aufgebraucht", "Dein Kontingent an Mitgliedern ist ausgeschöpft. Erweitere deinen Plan unter \"Abrechnung\".");
          break;
        case "invalid-argument":
          this.showAlert("Fehler", "Bitte überprüfe deine Eingabe und versuche es nochmal.");
          break;
        case "already-exists":
          this.showAlert("Bereits Mitglied", "Der Account mit dieser E-Mail-Adresse ist bereits Mitglied deiner Organisation.");
          break;

        default:
          this.showAlert("Unbekannter Fehler", "Bitte versuche es später nochmal.");
      }
      this.isProcessing = false;
    }, () => {
    });
  }

  private showAlert(title: string, text: string, afterClosed: (() => any) | null = null) {
    this.dialog.open(AlertComponent, {
      data: {
        title: title,
        text: text
      },
      disableClose: true
    }).afterClosed().subscribe(() => {
      if (afterClosed) afterClosed();
    });
  }
}
