<main>
  <div id="loading" *ngIf="isLoading; else content">
    <mat-spinner color="accent" diameter="32"></mat-spinner>
    <div id="loading-text">Zahlungsmethode wird geladen …</div>
  </div>
  <ng-template #content>
    <div class="line" *ngIf="invoiceAddress != null">
      <div>Adresse</div>
      <div>
        <div *ngIf="invoiceAddress.name != ''">{{ invoiceAddress.name }}</div>
        <div *ngIf="invoiceAddress.street != ''">{{ invoiceAddress.street }}</div>
        <div *ngIf="invoiceAddress.city != ''">{{ invoiceAddress.postal_code }} {{ invoiceAddress.city }}</div>
        <div *ngIf="invoiceAddress.country != ''">{{ invoiceAddress.country }}</div>
        <div *ngIf="invoiceAddress.vat_id != ''">{{ invoiceAddress.vat_id }}</div>
      </div>
    </div>
    <div class="line" *ngIf="invoiceAddress != null">
      <div>Rechnung senden an</div>
      <div>{{ invoiceAddress.email_address }}</div>
    </div>
    <div class="line" *ngIf="paymentMethod != null; else noPaymentMethod">
      <div>IBAN</div>
      <div>
        <div class="payment-method">
          <div class="payment-method-icon"></div>
          <div class="payment-method-title">•••• {{ paymentMethod.last4 }}</div>
        </div>
        <div class="payment-method-description">{{ paymentMethod.name }} ({{ paymentMethod.email }})</div>
      </div>
    </div>
    <ng-template #noPaymentMethod>
      <div class="line">
        <div>Methode</div>
        <div>
          <div class="payment-method-title no-icon">
            {{ (invoiceAddress?.is_e_rechnung_enabled ?? false) ? "e-Rechnung" : "Kauf auf Rechnung" }}
            <!-- todo: "Zahlungsziel 30 Tage" load pay target from server -->
          </div>
          <div class="clear"></div>
        </div>
      </div>
    </ng-template>

    <button (click)="onButtonClick()" [disabled]="disabled">
      <mat-icon>edit</mat-icon>
      Zahlungsmethode aktualisieren
    </button>
  </ng-template>
</main>
