import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {HttpClient} from "@angular/common/http";
import {OrganizationService} from "../../core/services/organization.service";
import {Api} from "../../core/network/Api";
import {ActionNotification} from "../../core/models/ActionNotification";
import {
  ActionNotificationRecipientEditDialogComponent, ActionNotificationRecipientEditDialogData
} from "../action-notification-recipient-edit-dialog/action-notification-recipient-edit-dialog.component";

@Component({
  selector: 'app-action-notification-dialog',
  templateUrl: './action-notification-dialog.component.html',
  styleUrls: ['./action-notification-dialog.component.scss']
})
export class ActionNotificationDialogComponent implements OnInit {

  isLoading = false;

  actionNotificationKey = '';
  actionNotification: ActionNotification | null = null;

  constructor(@Inject(MAT_DIALOG_DATA) public data: ActionNotificationDialogData,
              public dialogRef: MatDialogRef<ActionNotificationDialogComponent>, private httpClient: HttpClient,
              private organizationService: OrganizationService, public dialog: MatDialog) {
    this.actionNotificationKey = data.actionNotificationKey;
  }

  ngOnInit(): void {
    this.loadData();
  }

  onCloseClick(event: any): void {
    this.dialogRef.close();
  }

  onAddRecipientClick(event: any): void {
    this.dialog.open(ActionNotificationRecipientEditDialogComponent, {
      maxWidth: 500,
      data: {
        key: this.actionNotificationKey,
        recipient: null
      } as ActionNotificationRecipientEditDialogData,
      panelClass: 'sp-dialog-panel',
      disableClose: true
    }).afterClosed().subscribe(() => {
      // todo: if (afterClosed) afterClosed();
      this.loadData();
    });
  }

  onRecipientDeleted(event: any): void {
    this.loadData();
  }

  private loadData(): void {
    this.isLoading = true;

    Api.actionNotificationId(this.httpClient, `${this.organizationService.getSelectedOrganization()?.id ?? ''}`, this.actionNotificationKey, response => {
      this.actionNotification = response.item;
    }, error => {

    }, () => {
      this.isLoading = false;
    })
  }
}

export interface ActionNotificationDialogData {
  actionNotificationKey: string;
}
