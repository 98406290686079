import {Injectable} from '@angular/core';
import {ToolbarButton} from "../models/ToolbarButton";
import {BehaviorSubject, Observable} from "rxjs";
import {HierarchyItem} from "../models/HierarchyItem";

@Injectable({
  providedIn: 'root'
})
export class ToolbarService {
  private actions: BehaviorSubject<ToolbarButton[]>;
  private hierarchy: BehaviorSubject<HierarchyItem[]>;
  private pageTitle: BehaviorSubject<string>;

  constructor() {
    this.actions = new BehaviorSubject<ToolbarButton[]>([]);
    this.hierarchy = new BehaviorSubject<HierarchyItem[]>([]);
    this.pageTitle = new BehaviorSubject<string>("");
  }

  resetActions(): void {
    this.setActions([]);
  }

  setActions(actions: ToolbarButton[]): void {
    this.actions.next(actions);
  }

  getActions(): Observable<ToolbarButton[]> {
    return this.actions.asObservable();
  }

  resetHierarchy(): void {
    this.setHierarchy([]);
    this.setPageTitle();
  }

  setHierarchy(hierarchy: HierarchyItem[]): void {
    this.hierarchy.next(hierarchy);
  }

  getHierarchy(): Observable<HierarchyItem[]> {
    return this.hierarchy.asObservable();
  }

  setPageTitle(title: string = ""): void {
    this.pageTitle.next(title);
  }

  getPageTitle(): Observable<string> {
    return this.pageTitle.asObservable();
  }
}
