import {AfterViewInit, Component, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {OrganizationService} from '../../../../core/services/organization.service';
import {Api} from '../../../../core/network/Api';
import {PaymentMethod} from '../../../../core/models/PaymentMethod';
import {LicensePrice} from '../../../../core/models/LicensePrice';
import {MatDialog} from "@angular/material/dialog";
import {CheckoutSuccessComponent} from "../../../../dialogs/checkout-success/checkout-success.component";
import {AlertComponent} from "../../../../dialogs/alert/alert.component";
import {LicenseCheckoutPreview} from "../../../../core/models/LicenseCheckoutPreview";
import {LicenseType} from "../../../../core/constants/LicenseType";

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit, AfterViewInit {
  isLoadingLicense = false;
  isLoadingPricing = false;
  isLoadingSEPASession = false;
  isLoadingCheckoutPreview = false;
  isLoadingPaymentMethods = false;

  selectedPlan = 'smart';

  selectedBillingPeriod = 'month';

  price: LicensePrice | null = null;
  checkoutPreview: LicenseCheckoutPreview | null = null;
  paymentMethod: PaymentMethod | null = null;

  isNpo = false;
  isAcceptingTerms = false;

  isCheckingOut = false;

  isCheckoutAvailable = false;

  constructor(private formBuilder: FormBuilder, private httpClient: HttpClient,
              private organizationService: OrganizationService, private router: Router, public dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.loadLicense();
    this.loadPricing();
    this.loadPaymentMethod();
    this.loadCheckoutPreview();

    // check if private customer
    if (this.organizationService.getSelectedOrganization()?.organization_type_id != 4) {
      this.isCheckoutAvailable = false;
      this.showAlert('Fehler', 'Bestellungen für Privatkunden sind derzeit nicht möglich.', () => {
        this.router.navigateByUrl('/home/billing');
      });
    } else {
      this.isCheckoutAvailable = true;
    }
  }

  ngAfterViewInit(): void {
  }

  onBillingPeriodChanged(event: any) {
    this.loadPricing();
    this.loadCheckoutPreview();
  }

  onPlanChanged(event: any) {
    console.log('Selected plan', this.selectedPlan);
    if (this.selectedPlan == 'smart') {
      this.loadPricing();
      this.loadCheckoutPreview();
    }
  }

  onRequestOfferClick(event: any): any {
    let subject = 'Angebot: Plan Enterprise';
    window.location.href = `mailto:office@smartpager.at?subject=${encodeURI(subject)}`;
  }

  private loadLicense() {
    this.isLoadingLicense = true;

    Api.license(this.httpClient, {organization_id: this.organizationService.selectedOrganizationId}, response => {
      if (response.license.type == LicenseType.PAID || response.license.type == LicenseType.ENTERPRISE) {
        this.showAlert('Fehler', 'Du hast bereits einen bezahlten Plan.', () => {
          this.router.navigateByUrl('/home/billing');
        })
      }
      this.isLoadingLicense = false;
    }, (error: string) => {
      this.showAlert('Fehler beim Laden', 'Die Daten konnten nicht vollständig geladen werden. Bitte versuche es später nochmal.', () => {
        this.router.navigateByUrl('/home/billing');
      })
    }, () => {
      this.isLoadingLicense = false;
    });
  }

  loadPricing() {
    this.isLoadingPricing = true;

    Api.licensePricing(this.httpClient, {organization_id: this.organizationService.selectedOrganizationId}, licensePrice => {
      this.price = licensePrice;
    }, error => {
      // todo
    }, () => {
      this.isLoadingPricing = false;
    }, this.selectedBillingPeriod);
  }

  loadCheckoutPreview(): void {
    this.isLoadingCheckoutPreview = true;

    Api.licenseCheckoutPreview(this.httpClient, {organization_id: `${this.organizationService.selectedOrganizationId}`}, (preview) => {
      this.checkoutPreview = preview;
      this.isLoadingCheckoutPreview = false;
    }, (error) => {
      this.isLoadingCheckoutPreview = false;
      this.showAlert('Fehler beim Laden', 'Bitte versuche es später nochmal.', () => {
        this.router.navigateByUrl('/home/billing');
      })
    }, () => {
    }, this.selectedBillingPeriod)
  }

  loadPaymentMethod(): void {
    this.isLoadingPaymentMethods = true;

    Api.paymentMethod(this.httpClient, {organization_id: `${this.organizationService.selectedOrganizationId}`}, (invoiceAddress, paymentMethod) => {
      if (invoiceAddress.country_code == "ch") {
        alert("Vielen Dank für dein Interesse! Schreibe uns bitte an support@smartpager.at für die Bestellung eines Plans.");
        this.router.navigateByUrl('/home/billing');
        return
      }
      this.paymentMethod = paymentMethod;
      this.isLoadingPaymentMethods = false;
    }, (error) => {
      this.isLoadingPaymentMethods = false;
      this.showAlert('Fehler beim Laden', 'Bitte versuche es später nochmal.', () => {
        this.router.navigateByUrl('/home/billing');
      })
    }, () => {
    });
  }

  checkout(): void {
    // todo: check input values

    if (this.isCheckingOut) {
      this.showAlert('In Bearbeitung', 'Deine Bestellung wird gerade verarbeitet. Bitte warte einen Moment, bis diese abgeschlossen ist.')
      return;
    }

    this.isCheckingOut = true;

    Api.licensePurchase(this.httpClient, {
      organization_id: `${this.organizationService.selectedOrganizationId}`
    }, () => {
      this.dialog
        .open(CheckoutSuccessComponent, {disableClose: true})
        .afterClosed().subscribe(result => {
        this.router.navigateByUrl('/home/billing');
      });
    }, (error) => {
      this.isCheckingOut = false;
      switch (error) {
        case 'disabled':
          this.showAlert('Fehler', 'Bestellungen sind derzeit nicht möglich. Bitte kontaktiere unseren Support für weitere Informationen.')
          break;
        default:
          this.showAlert('Fehler', 'Interner Fehler beim Check-out! Der Kauf konnte nicht abgeschlossen werden! Bitte kontaktiere unseren Support für weitere Informationen!')
      }
    }, () => {
    }, this.selectedBillingPeriod);
  }

  private showAlert(title: string, text: string, afterClosed: (() => any) | null = null) {
    this.dialog.open(AlertComponent, {
      maxWidth: 500,
      data: {
        title: title,
        text: text
      },
      disableClose: true
    }).afterClosed().subscribe(() => {
      if (afterClosed) afterClosed();
    });
  }
}
