import {Component, OnDestroy, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ApiService} from "../../../core/services/api.service";
import {OrganizationService} from "../../../core/services/organization.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ToolbarService} from "../../../core/services/toolbar.service";
import {UserEvent} from "../../../core/models/UserEvent";
import {EventListRequest} from "../../../core/network/request/EventListRequest";
import {EventListResponse} from "../../../core/network/response/EventListResponse";

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit, OnDestroy {

  isLoading = false;

  isEventManager = false;

  events: UserEvent[] = [];

  constructor(private httpClient: HttpClient, private apiService: ApiService,
              private organizationService: OrganizationService, private router: Router, private route: ActivatedRoute,
              private toolbarService: ToolbarService) {
    this.isEventManager = organizationService.getSelectedOrganization()?.is_event_manager ?? false;
  }

  ngOnInit(): void {
    if (this.isEventManager) {
      this.toolbarService.setActions([{icon: "add", path: "/home/events/create", info: "Ereignis erstellen"}]);
    }

    this.loadData();
  }

  ngOnDestroy(): void {
    this.toolbarService.resetActions();
  }

  private loadData(): void {
    this.isLoading = true;

    const request = {organization_id: this.organizationService.getSelectedOrganization()?.id ?? 0} as EventListRequest;

    this.apiService.eventList(request, (response: EventListResponse) => {
      this.events = response.items.sort((a, b) => {
        return b.time_start - a.time_start
      });
    }, (error: string) => {
      // todo
    }, () => {
      this.isLoading = false;
    })
  }

  open(id: string): void {
    this.router.navigate([id], {relativeTo: this.route});
  }
}
