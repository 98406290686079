import {Component, Input, OnInit} from '@angular/core';
import {MemberUsage} from "../../core/network/models/MemberUsage";

@Component({
  selector: 'dynamic-member-usage',
  templateUrl: './dynamic-member-usage.component.html',
  styleUrls: ['./dynamic-member-usage.component.scss']
})
export class DynamicMemberUsageComponent implements OnInit {

  @Input() memberUsage: MemberUsage | null = null;

  constructor() {
  }

  ngOnInit(): void {
  }

}
