<main>
  <h1>Mitglied hinzufügen</h1>

  <div id="form">
    <div class="loading" *ngIf="isLoading; else content">
      <mat-spinner color="accent" diameter="32"></mat-spinner>
    </div>

    <ng-template #content>
      <div id="content">
        Wähle eine Methode aus.
        <ul>
          <li routerLink="/home/members/add/sign-up">
            <div class="icon">
              <mat-icon>add</mat-icon>
            </div>
            <div class="text">
              <div class="title">Mitglied registrieren</div>
              <div class="description">Registrierung für ein Mitglied durchführen</div>
            </div>
          </li>
          <li routerLink="/home/members/add/email-invitation">
            <div class="icon">
              <mat-icon>mail</mat-icon>
            </div>
            <div class="text">
              <div class="title">E-Mail Einladung</div>
              <div class="description">Einladung per E-Mail senden</div>
            </div>
          </li>
        </ul>
      </div>
    </ng-template>
  </div>
</main>
