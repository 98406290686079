<div *ngIf="invoices.length <= 0; else list">Keine Rechnungen vorhanden.</div>
<ng-template #list>
  <main>
    <div class="line" *ngFor="let i of invoices">
      <div class="bill-date">{{ i.date * 1000 | date:'dd. MMMM yyyy' }}</div>
      <div class="bill-number" (click)="openUrlInNewTab(i.link)" title="Rechnung öffnen">{{ i.number }}</div>
      <div class="bill-amount">{{ i.amount }}</div>
      <div class="bill-paid" [title]="i.status_text" [ngClass]="i.status">
        <ng-container [ngSwitch]="i.status">
          <mat-icon *ngSwitchCase="'open'">{{ 'pending' }}</mat-icon>
          <mat-icon *ngSwitchCase="'past-due'">{{ 'error' }}</mat-icon>
          <mat-icon *ngSwitchCase="'paid'">{{ 'check_circle' }}</mat-icon>
          <mat-icon *ngSwitchCase="'void'">{{ 'cancel' }}</mat-icon>
          <mat-icon *ngSwitchDefault>{{ 'question_mark' }}</mat-icon>
        </ng-container>
      </div>
      <div class="action">
        <a (click)="openUrlInNewTab(i.link)"
           *ngIf="(i.status === 'open' || i.status == 'past-due') && i.collection_method === 'send'">Rechnung
          zahlen</a>
      </div>
    </div>
  </main>
</ng-template>
