<main>
  <div class="icon">
    <mat-icon *ngIf="recipient?.transmission_type === 'email'">mail</mat-icon>
    <mat-icon *ngIf="recipient?.transmission_type === 'sms'">sms</mat-icon>
  </div>
  <div class="details">
    <div>{{recipient?.notice}}</div>
    <div>{{recipient?.address}}</div>
    <div class="options" *ngIf="options.length > 0">
      <span *ngFor="let o of options">{{o}}</span>
    </div>
  </div>
  <div class="actions">
    <mat-icon id="btn-delete" matRipple (click)="onDeleteClick($event)">cancel</mat-icon>
  </div>
</main>
