import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from "./pages/home/home.component";
import {AuthGuard} from "./core/guards/auth.guard";
import {BillingComponent} from "./pages/home/subscriptions/billing.component";
import {UpdatePaymentMethodComponent} from "./pages/home/billing/update-payment-method/update-payment-method.component";
import {BindingsComponent} from "./pages/home/bindings/bindings.component";
import {BindingLwzooeComponent} from "./pages/home/bindings/binding-lwzooe/binding-lwzooe.component";
import {OrganizationUpdateComponent} from "./pages/home/organization/organization-update/organization-update.component";
import {LoginComponent} from "./pages/login/login.component";
import {SignInComponent} from "./pages/login/sign-in/sign-in.component";
import {SignUpComponent} from "./pages/login/sign-up/sign-up.component";
import {E404Component} from "./pages/errors/e404/e404.component";
import {CheckoutComponent} from "./pages/home/subscriptions/checkout/checkout.component";
import {LicenseRenewComponent} from "./pages/home/subscriptions/license-renew/license-renew.component";
import {LicenseCancelComponent} from "./pages/home/subscriptions/license-cancel/license-cancel.component";
import {LicenseUsageComponent} from "./pages/home/billing/license-usage/license-usage.component";
import {OperationsComponent} from "./pages/home/operations/operations.component";
import {CreateOperationComponent} from "./pages/home/operations/create-operation/create-operation.component";
import {MembersComponent} from "./pages/home/members/members.component";
import {AdminGuard} from "./core/guards/admin.guard";
import {OperationManagerGuard} from "./core/guards/operation-manager.guard";
import {GetStartedComponent} from "./pages/home/get-started/get-started.component";
import {BindingLsTirolComponent} from "./pages/home/bindings/binding-ls-tirol/binding-ls-tirol.component";
import {InterfaceComponent} from "./pages/home/bindings/interface/interface.component";
import {
  BusinessConfirmationComponent
} from "./pages/home/billing/business-confirmation/business-confirmation.component";
import {BindingConnectComponent} from "./pages/home/bindings/binding-connect/binding-connect.component";
import {OperationDetailComponent} from "./pages/home/operations/operation-detail/operation-detail.component";
import {WasserkarteComponent} from "./pages/home/bindings/wasserkarte/wasserkarte.component";
import {BindingMapTripComponent} from "./pages/home/bindings/binding-maptrip/binding-map-trip.component";
import {AddMemberComponent} from "./pages/home/members/add-member/add-member.component";
import {
  AddMemberByEmailComponent
} from "./pages/home/members/add-member/add-member-by-email/add-member-by-email.component";
import {
  InviteMemberByEmailComponent
} from "./pages/home/members/add-member/invite-member-by-email/invite-member-by-email.component";
import {EventsComponent} from "./pages/home/events/events.component";
import {CreateEventComponent} from "./pages/home/events/create-event/create-event.component";
import {EventManagerGuard} from "./core/guards/event-manager.guard";
import {MonitorsComponent} from "./pages/home/monitors/monitors.component";
import {AddMonitorComponent} from "./pages/home/monitors/add-monitor/add-monitor.component";
import {MonitorDetailsComponent} from "./pages/home/monitors/monitor-details/monitor-details.component";
import {NewsComponent} from "./pages/home/news/news.component";
import {
  BindingExternalIcalendarComponent
} from "./pages/home/bindings/binding-external-icalendar/binding-external-icalendar.component";
import {BindingSmsComponent} from "./pages/home/bindings/binding-sms/binding-sms.component";
import {ActionNotificationsComponent} from "./pages/home/action-notifications/action-notifications.component";
import {PrivacyComponent} from "./pages/home/privacy/privacy.component";
import {LwzOoeBindingComponent} from "./pages/home/bindings/lwz-ooe-binding/lwz-ooe-binding.component";
import {DeletionComponent} from "./pages/privacy/deletion/deletion.component";
import {RescueTabletComponent} from "./pages/home/bindings/rescue-tablet/rescue-tablet.component";

const routes: Routes = [
  {path: '', redirectTo: 'login', pathMatch: 'full'},
  {
    path: 'home', component: HomeComponent, canActivate: [AuthGuard], children:
      [
        // {path: '', redirectTo: 'subscriptions', pathMatch: 'full'},
        {path: '', redirectTo: 'operations', pathMatch: 'full'},
        {path: 'get-started', component: GetStartedComponent},
        // {path: 'start', component: StartComponent},
        {path: 'operations', component: OperationsComponent},
        {path: 'operations/create', component: CreateOperationComponent, canActivate: [OperationManagerGuard]},
        {path: 'operations/:id', component: OperationDetailComponent},
        {path: 'events', component: EventsComponent},
        {path: 'events/create', component: CreateEventComponent, canActivate: [EventManagerGuard]},
        {path: 'members', component: MembersComponent, canActivate: [AdminGuard]},
        {path: 'members/add', component: AddMemberComponent, canActivate: [AdminGuard]},
        {path: 'members/add/sign-up', component: AddMemberByEmailComponent, canActivate: [AdminGuard]},
        {path: 'members/add/email-invitation', component: InviteMemberByEmailComponent, canActivate: [AdminGuard]},
        {path: 'billing', component: BillingComponent, canActivate: [AdminGuard]},
        {path: 'billing/payment-method/update', component: UpdatePaymentMethodComponent, canActivate: [AdminGuard]},
        {path: 'billing/plan/checkout', component: CheckoutComponent, canActivate: [AdminGuard]},
        {path: 'billing/plan/renew', component: LicenseRenewComponent, canActivate: [AdminGuard]},
        {path: 'billing/plan/change', component: LicenseCancelComponent, canActivate: [AdminGuard]},
        {path: 'billing/plan/usage', component: LicenseUsageComponent, canActivate: [AdminGuard]},
        {path: 'billing/business-confirmation', component: BusinessConfirmationComponent, canActivate: [AdminGuard]},
        // {path: 'subscriptions/payment-methods/add', component: AddPaymentMethodComponent},
        // {path: 'subscriptions/checkout', component: CheckoutComponent},
        // {path: 'subscriptions/checkout/success', component: CheckoutSuccessComponent},
        // {path: 'subscriptions/license/cancel', component: LicenseCancelComponent},
        // {path: 'subscriptions/license/renew', component: LicenseRenewComponent},
        // {path: 'subscriptions/license/update', component: LicenseUpdateComponent},
        // {path: 'subscriptions/invoice-recipient/update', component: InvoiceRecipientUpdateComponent},
        // {path: 'organization/update', component: OrganizationUpdateComponent},
        {path: 'monitors', component: MonitorsComponent, canActivate: [AdminGuard]},
        {path: 'monitors/add', component: AddMonitorComponent, canActivate: [AdminGuard]},
        {path: 'monitors/:id', component: MonitorDetailsComponent, canActivate: [AdminGuard]},
        {path: 'bindings', component: BindingsComponent, canActivate: [AdminGuard]},
        {path: 'bindings/connect', component: BindingConnectComponent, canActivate: [AdminGuard]},
        {path: 'bindings/lwz-ooe', component: BindingLwzooeComponent, canActivate: [AdminGuard]},
        {path: 'bindings/ls-tirol', component: BindingLsTirolComponent, canActivate: [AdminGuard]},
        {path: 'bindings/interface', component: InterfaceComponent, canActivate: [AdminGuard]},
        {path: 'bindings/wasserkarte', component: WasserkarteComponent, canActivate: [AdminGuard]},
        {path: 'bindings/rescue-tablet', component: RescueTabletComponent, canActivate: [AdminGuard]},
        {path: 'bindings/maptrip', component: BindingMapTripComponent, canActivate: [AdminGuard]},
        {path: 'bindings/external-icalendar', component: BindingExternalIcalendarComponent, canActivate: [AdminGuard]},
        {path: 'bindings/sms', component: BindingSmsComponent, canActivate: [AdminGuard]},
        {path: 'action-notifications', component: ActionNotificationsComponent, canActivate: [AdminGuard]},
        {path: 'organization/update', component: OrganizationUpdateComponent, canActivate: [AdminGuard]},
        {path: 'privacy', component: PrivacyComponent, canActivate: [AdminGuard]},
        {path: 'news', component: NewsComponent}
      ]
  },

  {
    path: 'login', component: LoginComponent, children:
      [
        {path: '', component: SignInComponent},
        {path: 'sign-up', component: SignUpComponent}
      ]
  },

  {path: 'privacy/deletion', component: DeletionComponent},

  {path: '**', component: E404Component}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
