import {Component, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {OrganizationService} from "../../../core/services/organization.service";
import {Api} from "../../../core/network/Api";
import {ActionNotification} from "../../../core/models/ActionNotification";
import {MatDialog} from "@angular/material/dialog";
import {AlertComponent} from "../../../dialogs/alert/alert.component";
import {
  ActionNotificationDialogComponent, ActionNotificationDialogData
} from "../../../dialogs/action-notification-dialog/action-notification-dialog.component";
import {ApiService} from "../../../core/services/api.service";

@Component({
  selector: 'app-action-notifications',
  templateUrl: './action-notifications.component.html',
  styleUrls: ['./action-notifications.component.scss']
})
export class ActionNotificationsComponent implements OnInit {
  isLoading = false;

  actionNotifications: ActionNotification[] = [];

  constructor(private httpClient: HttpClient, private router: Router, private organizationService: OrganizationService,
              public dialog: MatDialog, private apiService: ApiService) {
  }

  ngOnInit(): void {
    this.loadData();

    this.openDialog('operation.create');
  }

  private loadData(): void {
    this.isLoading = true;

    this.apiService.actionNotificationGetAll(`${this.organizationService.selectedOrganizationId}`, data => {
      // todo: handle if data.bindings is null
      if (data.items != null) {
        this.actionNotifications = data.items.sort((a, b) => a.title > b.title ? 1 : -1);
      }
    }, (error: string) => {
      // todo: handle error
      this.isLoading = false;
    }, () => {
      this.isLoading = false;
    });
  }

  openDialog(key: string): void {
    if (key == '') return;

    this.dialog.open(ActionNotificationDialogComponent, {
      maxWidth: 600,
      data: {
        actionNotificationKey: key
      } as ActionNotificationDialogData,
      panelClass: 'sp-dialog-panel',
      disableClose: true
    }).afterClosed().subscribe(() => {
      this.loadData();
      // todo: if (afterClosed) afterClosed();
    });
  }

}
