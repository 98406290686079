<main>
  <h1>Monitore</h1>

  <div class="loading" *ngIf="isLoading; else content">
    <mat-spinner color="accent" diameter="32"></mat-spinner>
  </div>

  <ng-template #content>
    <ul class="list">
      <li *ngFor="let monitor of monitors" (click)="onListItemClick(monitor.id, $event)">
        <div class="data">
          <div class="title">{{monitor.label}}</div>
          <div class="status">{{monitor.status_text}}</div>
        </div>
        <div class="indicator"><span [ngClass]="'status-' + monitor.status"></span></div>
      </li>
    </ul>

    <div *ngIf="monitors.length <= 0">
      Keine Monitore vorhanden.
    </div>
  </ng-template>
</main>
