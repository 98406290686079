<main>
  <h1>Ereignis-Benachrichtigungen</h1>

  <div class="loading" *ngIf="isLoading; else items">
    <mat-spinner color="accent" diameter="32"></mat-spinner>
  </div>

  <ng-template #items>
    <ul class="items">
      <li *ngFor="let an of actionNotifications" (click)="openDialog(an.key)">
        <div class="data">
          <div class="title">{{an.title}}</div>
          <div class="status">{{an.recipient_count ?? 0}} Empfänger</div>
        </div>
      </li>
    </ul>

    <div *ngIf="actionNotifications.length <= 0" id="empty">
      Keine Ereignis-Benachrichtigungen verfügbar.
    </div>
  </ng-template>
</main>
