import {Component, OnInit} from '@angular/core';
import {OrganizationService} from "../../../core/services/organization.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-get-started',
  templateUrl: './get-started.component.html',
  styleUrls: ['./get-started.component.scss']
})
export class GetStartedComponent implements OnInit {

  constructor(private router: Router, private organizationService: OrganizationService) {
  }

  ngOnInit(): void {
    if (this.organizationService.organizations.length > 0) {
      this.router.navigateByUrl('/home');
    }
  }

}
