<main [ngClass]="isSelected ? 'selected': ''" *ngIf="plan != null">
  <header>
    <div id="name">{{ plan.name }} <span id="billing-period">{{ plan.billing_period_text }}</span></div>
    <div id="badge-active" *ngIf="plan.is_active">AKTIV</div>
  </header>
  <div id="price">{{ plan.basic_rate }}</div>
  <ul id="price-details">
    <li *ngFor="let i of plan.additional_items">{{ i.label }}: {{ i.amount }}</li>
  </ul>
</main>
