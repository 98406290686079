import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {ToolbarService} from "../../../../core/services/toolbar.service";

@Component({
  selector: 'app-add-member',
  templateUrl: './add-member.component.html',
  styleUrls: ['./add-member.component.scss']
})
export class AddMemberComponent implements OnInit, OnDestroy {

  isLoading = false;

  constructor(private router: Router, private toolbarService: ToolbarService) {
  }

  ngOnInit(): void {
    this.toolbarService.setHierarchy([{title: "Mitglieder", path: "/home/members"}]);
    this.toolbarService.setPageTitle("Mitglied hinzufügen");
  }

  ngOnDestroy(): void {
    this.toolbarService.resetHierarchy();
  }

  navigateTo(route: string) {
    this.router.navigateByUrl(route);
  }

}
