<main>
  <h1>E-Mail Einladung</h1>

  <div id="form">
    <div id="content">
      <mat-form-field appearance="fill">
        <mat-label>E-Mail-Adresse</mat-label>
        <input matInput required type="email" [(ngModel)]="email" [disabled]="isProcessing">
      </mat-form-field>

      <button class="btn-action btn-primary" [disabled]="isProcessing" (click)="sendInvitation()">
        Einladung senden
      </button>
    </div>
  </div>
</main>
