import {Injectable, isDevMode} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {OrganizationService} from "../services/organization.service";

@Injectable({
  providedIn: 'root'
})
export class EventManagerGuard implements CanActivate {
  constructor(private router: Router, private organizationService: OrganizationService) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (isDevMode()) {
      // todo: remove
      return true;
    }

    const org = this.organizationService.getSelectedOrganization();
    const isAllowed = org?.is_event_manager ?? false;
    if (!isAllowed) {
      this.router.navigateByUrl('/home');
    }
    return isAllowed;
  }
}
