<header *ngIf="false">
  <ul>
    <li><img src="assets/logo_dashboard.svg" alt="Dashboard"></li>
    <li></li>
  </ul>
</header>

<mat-drawer-container class="site" autosize>
  <mat-drawer #drawer class="sidenav" [mode]="screenWidth < drawerSideLimit ? 'push' : 'side'" [opened]="isDrawerOpen">
    <div id="drawer-header">
      <div id="profile">
        <img src="assets/images/user-icon.webp">
        <div id="profile-details">
          Hallo <b>{{ firstName }}</b>!<br>
          <a (click)="onSignOutClick($event)" [ngClass]="isSigningOut ? 'in-progress' : ''">Abmelden</a>
        </div>
      </div>

      <mat-form-field appearance="fill" *ngIf="organizationService.organizations.length > 0"
                      id="organization-selection">
        <mat-label>Organisation</mat-label>
        <mat-select [(ngModel)]="organizationService.selectedOrganizationId"
                    (selectionChange)="onOrganizationChange($event)">
          <mat-option *ngFor="let organization of organizationService.organizations"
                      [value]="organization.id">{{ organization.name }}
          </mat-option>
        </mat-select>
        <mat-hint>{{ organizationType }}</mat-hint>
      </mat-form-field>

      <div id="customer-id" *ngIf="organizationService.organizations.length > 0">
        Kundennummer: {{ organizationService.selectedOrganizationId.toString().padStart(6, '0') }}
      </div>
    </div>

    <ul>
      <li *ngFor="let link of mainMenu" [routerLink]="'/home/'+link.link" class="noselect"
          (mousedown)="onMenuItemClick($event)"
          routerLinkActive="active" mat-ripple style="position: relative;">
        <mat-icon>{{ link.icon }}</mat-icon>
        <span>{{ link.text }}</span>
        <div class="clear"></div>
      </li>
    </ul>
  </mat-drawer>

  <div id="view">
    <mat-toolbar color="primary">
      <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon"
              (click)="drawer.toggle()" *ngIf="screenWidth < drawerSideLimit">
        <mat-icon>menu</mat-icon>
      </button>
      <span>Dashboard</span>
      <span class="spacer"></span>
      <!--<button mat-icon-button class="example-icon favorite-icon" aria-label="Example icon-button with heart icon">
        <mat-icon>favorite</mat-icon>
      </button>-->
      <button mat-icon-button *ngFor="let a of toolbarActions" [routerLink]="a.path" [matTooltip]="a.info">
        <mat-icon>{{ a.icon }}</mat-icon>
      </button>
    </mat-toolbar>

    <nav *ngIf="hierarchy.length > 0">
      <ul>
        <li *ngFor="let h of hierarchy" class="clickable">
          <span [routerLink]="h.path">{{ h.title }}</span>
          <mat-icon>chevron_right</mat-icon>
        </li>
        <li><span>{{ pageTitle }}</span></li>
      </ul>
    </nav>

    <main>
      <router-outlet *ngIf="!isLoading"></router-outlet>

      <div class="content" *ngIf="isLoading">
        <div id="loading">
          <mat-spinner diameter="32" color="accent"></mat-spinner>
          <span>Daten werden geladen ...</span>
          <div class="clear"></div>
        </div>
      </div>
    </main>
  </div>

</mat-drawer-container>
