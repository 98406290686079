import {Component, OnDestroy, OnInit} from '@angular/core';
import {ToolbarService} from "../../../core/services/toolbar.service";
import {OrganizationService} from "../../../core/services/organization.service";
import {ApiService} from "../../../core/services/api.service";
import {Monitor} from "../../../core/network/models/Monitor";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-monitors',
  templateUrl: './monitors.component.html',
  styleUrls: ['./monitors.component.scss']
})
export class MonitorsComponent implements OnInit, OnDestroy {

  isLoading = false;

  monitors: Monitor[] = [];

  constructor(private toolbarService: ToolbarService, private organizationService: OrganizationService,
              private apiService: ApiService, private route: ActivatedRoute, private router: Router) {
  }

  ngOnInit(): void {
    // todo: this.toolbarService.setActions([{icon: "add", path: "/home/monitors/add", info: "Monitor hinzufügen"}]);

    this.loadData();
  }

  ngOnDestroy(): void {
    this.toolbarService.resetActions();
  }

  onListItemClick(id: string, event: any): void {
    this.router.navigate([id], {relativeTo: this.route});
  }

  private loadData(): void {
    this.isLoading = true;

    const organizationId = this.organizationService.getSelectedOrganization()?.id ?? 0;
    this.apiService.monitorList({organization_id: organizationId.toString()}, response => {
      this.monitors = response.items.sort((a: Monitor, b: Monitor) => {
        if (a.label.toUpperCase() > b.label.toUpperCase()) return 1;
        if (a.label.toUpperCase() < b.label.toUpperCase()) return -1;
        // names must be equal
        return 0;
      });
      this.isLoading = false;
    }, error => {
      // todo: show error
      this.isLoading = false;
    }, () => {
      this.isLoading = false;
    });
  }
}
