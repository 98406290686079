import {Component, Input, OnInit} from '@angular/core';
import {UpcomingInvoice} from "../../core/network/models/UpcomingInvoice";

@Component({
  selector: 'upcoming-invoice',
  templateUrl: './upcoming-invoice.component.html',
  styleUrls: ['./upcoming-invoice.component.scss']
})
export class UpcomingInvoiceComponent implements OnInit {

  @Input() upcomingInvoice: UpcomingInvoice | null = null;

  constructor() {
  }

  ngOnInit(): void {
  }

}
