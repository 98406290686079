import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {isDevMode} from '@angular/core';
import {ApiUrl} from './ApiUrl';
import {environment} from '../../../environments/environment';
import {SignInResponse} from './response/SignInResponse';
import {SignInParams} from './request/SignInParams';
import {UserResponse} from './response/UserResponse';
import {InvoicesResponse} from './response/InvoicesResponse';
import {InvoicesParams} from './request/InvoicesParams';
import {LicenseCancelResponse} from './response/LicenseCancelResponse';
import {LicenseCancelParams} from './request/LicenseCancelParams';
import {LicenseRenewParams} from './request/LicenseRenewParams';
import {AddOnsAvailableParams} from './request/AddOnsAvailableParams';
import {AddOnsAvailableResponse} from './response/AddOnsAvailableResponse';
import {InvoiceAddressUpdateRequest} from './request/InvoiceAddressUpdateRequest';
import {PurchaseLicenseParams} from './request/PurchaseLicenseParams';
import {LicenseUpdateParams} from './request/LicenseUpdateParams';
import {PaymentMethodsSetupIntentCreateResponse} from './response/PaymentMethodsSetupIntentCreateResponse';
import {PaymentMethodsSetupIntentCreateParams} from './request/PaymentMethodsSetupIntentCreateParams';
import {LicenseResponse} from './response/LicenseResponse';
import {LicensePricingResponse} from './response/LicensePricingResponse';
import {LicenseCheckoutPreviewResponse} from './response/LicenseCheckoutPreviewResponse';
import {LicensePricingParams} from './request/LicensePricingParams';
import {LicenseParams} from './request/LicenseParams';
import {LicensePrice} from '../models/LicensePrice';
import {BindingsLwzOoeInfoParams} from './request/BindingsLwzOoeInfoParams';
import {BindingsLwzOoeInfoResponse} from './response/BindingsLwzOoeInfoResponse';
import {BindingsLwzOoeEnableParams} from './request/BindingsLwzOoeEnableParams';
import {BindingsLwzOoeDisableParams} from './request/BindingsLwzOoeDisableParams';
import {BindingsParams} from './request/BindingsParams';
import {BindingsResponse} from './response/BindingsResponse';
import {OrganizationInformationParams} from './request/OrganizationInformationParams';
import {OrganizationInformationResponse} from './response/OrganizationInformationResponse';
import {OrganizationTypesResponse} from './response/OrganizationTypesResponse';
import {CountriesResponse} from './response/CountriesResponse';
import {OrganizationInformationUpdateParams} from './request/OrganizationInformationUpdateParams';
import {InterfaceCreateParams} from './request/InterfaceCreateParams';
import {InterfaceActiveParams} from './request/InterfaceActiveParams';
import {InterfaceActiveResponse} from './response/InterfaceActiveResponse';
import {InterfaceCreateResponse} from './response/InterfaceCreateResponse';
import {InterfaceDeleteParams} from './request/InterfaceDeleteParams';
import {InvoiceAddress} from './models/InvoiceAddress';
import {PaymentMethodParams} from './request/PaymentMethodParams';
import {PaymentMethodResponse} from './response/PaymentMethodResponse';
import {PaymentMethod} from '../models/PaymentMethod';
import {PaymentMethodUpdateDefaultRequest} from './request/PaymentMethodUpdateDefaultRequest';
import {Invoice} from "../models/Invoice";
import {UpcomingInvoiceResponse} from "./response/UpcomingInvoiceResponse";
import {UpcomingInvoiceRequest} from "./request/UpcomingInvoiceRequest";
import {LicenseMemberUsageRequest} from "./request/LicenseMemberUsageRequest";
import {LicenseMemberUsageResponse} from "./response/LicenseMemberUsageResponse";
import {PlanUsageResponse} from "./response/PlanUsageResponse";
import {PlanUsageParams} from "./request/PlanUsageParams";
import {BindingsLsTirolInfoParams} from "./request/BindingsLsTirolInfoParams";
import {BindingsLsTirolInfoResponse} from "./response/BindingsLsTirolInfoResponse";
import {BindingsLsTirolEnableParams} from "./request/BindingsLsTirolEnableParams";
import {BindingsLsTirolDisableParams} from "./request/BindingsLsTirolDisableParams";
import {OrganizationTypeUpdateParams} from "./request/OrganizationTypeUpdateParams";
import {BindingsConnectInfoResponse} from "./response/BindingsConnectInfoResponse";
import {BindingsConnectInfoParams} from "./request/BindingsConnectInfoParams";
import {BindingsConnectEnableParams} from "./request/BindingsConnectEnableParams";
import {BindingsConnectEnableResponse} from "./response/BindingsConnectEnableResponse";
import {BindingsConnectDisableParams} from "./request/BindingsConnectDisableParams";
import {BindingsWasserkarteEnableParams} from "./request/BindingsWasserkarteEnableParams";
import {BindingsWasserkarteDisableParams} from "./request/BindingsWasserkarteDisableParams";
import {BindingsWasserkarteInfoParams} from "./request/BindingsWasserkarteInfoParams";
import {BindingsWasserkarteInfoResponse} from "./response/BindingsWasserkarteInfoResponse";
import {LicenseCheckoutPreview} from "../models/LicenseCheckoutPreview";
import {LicenseCheckoutPreviewParams} from "./request/LicenseCheckoutPreviewParams";
import {BindingsConnectInfoSettingsUpdateParams} from "./request/BindingsConnectInfoSettingsUpdateParams";
import {BindingsMapTripInfoRequest} from "./request/BindingsMapTripInfoRequest";
import {BindingsMapTripInfoResponse} from "./response/BindingsMapTripInfoResponse";
import {BindingsMapTripEnableRequest} from "./request/BindingsMapTripEnableRequest";
import {BindingsMapTripDisableRequest} from "./request/BindingsMapTripDisableRequest";
import {BindingsExternalICalendarsRequest} from "./request/BindingsExternalICalendarsRequest";
import {BindingsExternalICalendarsResponse} from "./response/BindingsExternalICalendarsResponse";
import {BindingsExternalICalendarsAddRequest} from "./request/BindingsExternalICalendarsAddRequest";
import {BindingsExternalICalendarsDeleteRequest} from "./request/BindingsExternalICalendarsDeleteRequest";
import {AddOnSmsEnableRequest} from "./request/AddOnSmsEnableRequest";
import {AddOnSmsDisableRequest} from "./request/AddOnSmsDisableRequest";
import {AddOnSmsHistoryResponse} from "./response/AddOnSmsHistoryResponse";
import {AddOnSmsResponse} from "./response/AddOnSmsResponse";
import {AddOnSmsPricingRequest} from "./request/AddOnSmsPricingRequest";
import {AddOnPrice} from "../models/AddOnPrice";
import {AddOnSmsPricingResponse} from "./response/AddOnSmsPricingResponse";
import {AddOnSmsPricingPreviewRequest} from "./request/AddOnSmsPricingPreviewRequest";
import {AddOnSmsPricingPreviewResponse} from "./response/AddOnSmsPricingPreviewResponse";
import {ActionNotificationGetResponse} from "./response/ActionNotificationGetResponse";
import {ActionNotificationIdGetResponse} from "./response/ActionNotificationIdGetResponse";
import {PrivacyGetSettingResponseData} from "./response/PrivacyGetSettingResponseData";
import {PrivacyPostSettingRequest} from "./request/PrivacyPostSettingRequest";
import {EventReminderScheduleTypeResponse} from "./response/EventReminderScheduleTypeResponse";
import {PlanAvailableResponse} from "./response/PlanAvailableResponse";
import {LicenseChangePreview} from "../models/LicenseChangePreview";
import {LicenseChangePreviewResponse} from "./response/LicenseChangePreviewResponse";
import {BindingsLwzOoe2InfoParams} from "./request/BindingsLwzOoe2InfoParams";
import {BindingsLwzOoe2InfoResponse} from "./response/BindingsLwzOoe2InfoResponse";
import {BindingsLwzOoeRequestParams} from "./request/BindingsLwzOoeRequestParams";
import {LicenseScheduleParams} from "./request/LicenseScheduleParams";
import {LicenseScheduleResponse} from "./response/LicenseScheduleResponse";
import {PrivacyDeletionRequest} from "./request/PrivacyDeletionRequest";
import {BindingsRescueTabletInfoParams} from "./request/BindingsRescueTabletInfoParams";
import {BindingsRescueTabletInfoResponse} from "./response/BindingsRescueTabletInfoResponse";
import {BindingsRescueTabletEnableParams} from "./request/BindingsRescueTabletEnableParams";
import {BindingsRescueTabletDisableParams} from "./request/BindingsRescueTabletDisableParams";
import {OrganizationOperationCategoriesResponse} from "./response/OrganizationOperationCategoriesResponse";

export class Api {
  public static readonly ERROR_CONNECTION = 'connection';

  private static getHeader(withCredentials: boolean = false): any {
    if (withCredentials) {
      return {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('jwt')}`,
        })// todo: , withCredentials: true
      };
    }

    return {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    };
  }

  private static prepareURL(url: string): string {
    return (isDevMode() ? ApiUrl.DOMAIN_DEV : (environment.isDev ? ApiUrl.DOMAIN_TEST : ApiUrl.DOMAIN_PROD)) + url;
  }

  private static handleError(error: any, onError: (error: string) => any): void {
    let err = error !== undefined && error != null && error !== ''
      ? error
      : Api.ERROR_CONNECTION;
    onError(err);
  }

  private static handleCommonError(error: string): boolean {
    console.log(`### handleCommonError: ${error}`);
    switch (error) {
      case 'jwt_invalid':
      case 'jwt_expired':
      case 'device_logged_out':
        localStorage.clear();
        window.location.reload(); // todo
        return true;
    }
    return false;
  }

  public static signIn(httpClient: HttpClient,
                       parameter: SignInParams,
                       onSuccess: (response: SignInResponse) => any,
                       onError: (error: string) => any,
                       doAlways: () => any): Subscription {

    parameter.email = encodeURIComponent(parameter.email);
    parameter.password = encodeURIComponent(parameter.password);

    return httpClient
      .post(Api.prepareURL(ApiUrl.SIGN__IN), parameter, this.getHeader())
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            onSuccess(response.data as SignInResponse);
          } else {
            if (!Api.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error?.error?.error, onError);
        },
        () => {
          doAlways();
        });
  }

  public static privacyDeletion(httpClient: HttpClient,
                                parameter: PrivacyDeletionRequest,
                                onSuccess: () => any,
                                onError: (error: string) => any,
                                doAlways: () => any): Subscription {

    parameter.email = encodeURIComponent(parameter.email);
    parameter.notes = encodeURIComponent(parameter.notes);

    return httpClient
      .post(Api.prepareURL(ApiUrl.PRIVACY__DELETION), parameter, this.getHeader())
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            onSuccess();
          } else {
            if (!Api.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error?.error?.error, onError);
        },
        () => {
          doAlways();
        });
  }

  public static user(httpClient: HttpClient,
                     onSuccess: (response: UserResponse) => any,
                     onError: (error: string) => any,
                     doAlways: () => any): Subscription {

    return httpClient
      .post(Api.prepareURL(ApiUrl.USER), {}, this.getHeader(true))
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            onSuccess(response.data as UserResponse);
          } else {
            if (!Api.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error?.error?.error, onError);
        },
        () => {
          doAlways();
        });
  }

  public static invoices(httpClient: HttpClient,
                         parameter: InvoicesParams,
                         onSuccess: (response: Invoice[]) => any,
                         onError: (error: string) => any,
                         doAlways: () => any): Subscription {

    return httpClient
      .post(Api.prepareURL(ApiUrl.INVOICES), parameter, this.getHeader(true))
      .pipe(map(response => response as any))
      .subscribe((response: InvoicesResponse) => {
          if (response.status === 200) {
            onSuccess(response.data!.invoices);
          } else {
            if (!Api.handleCommonError(response.error ?? 'unknown')) {
              onError(response.error ?? 'unknown');
            }
          }
        },
        error => {
          this.handleError(error?.error?.error, onError);
        },
        () => {
          doAlways();
        });
  }

  public static upcomingInvoice(httpClient: HttpClient,
                                parameter: UpcomingInvoiceRequest,
                                onSuccess: (response: UpcomingInvoiceResponse) => any,
                                onError: (error: string) => any,
                                doAlways: () => any): Subscription {

    return httpClient
      .post(Api.prepareURL(ApiUrl.UPCOMING_INVOICE), parameter, this.getHeader(true))
      .pipe(map(response => response as any))
      .subscribe((response: UpcomingInvoiceResponse) => {
          if (response.status === 200) {
            onSuccess(response);
          } else {
            if (!Api.handleCommonError(response.error ?? 'unknown')) {
              onError(response.error ?? 'unknown');
            }
          }
        },
        error => {
          this.handleError(error?.error?.error, onError);
        },
        () => {
          doAlways();
        });
  }

  public static licenseMemberUsage(httpClient: HttpClient,
                                   parameter: LicenseMemberUsageRequest,
                                   onSuccess: (response: LicenseMemberUsageResponse) => any,
                                   onError: (error: string) => any,
                                   doAlways: () => any): Subscription {

    return httpClient
      .post(Api.prepareURL(ApiUrl.LICENSE__MEMBER_USAGE), parameter, this.getHeader(true))
      .pipe(map(response => response as any))
      .subscribe((response: LicenseMemberUsageResponse) => {
          if (response.status === 200) {
            onSuccess(response);
          } else {
            if (!Api.handleCommonError(response.error ?? 'unknown')) {
              onError(response.error ?? 'unknown');
            }
          }
        },
        error => {
          this.handleError(error?.error?.error, onError);
        },
        () => {
          doAlways();
        });
  }

  public static license(httpClient: HttpClient,
                        parameter: LicenseParams,
                        onSuccess: (response: LicenseResponse) => any,
                        onError: (error: string) => any,
                        doAlways: (() => any) | null = null): Subscription {

    return httpClient
      .post(Api.prepareURL(ApiUrl.LICENSE), parameter, this.getHeader(true))
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            onSuccess(response.data as LicenseResponse);
          } else {
            if (!Api.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error?.error?.error, onError);
          if (doAlways) doAlways();
        },
        () => {
          if (doAlways) doAlways();
        });
  }

  public static planUsage(httpClient: HttpClient,
                          parameter: PlanUsageParams,
                          onSuccess: (response: PlanUsageResponse) => any,
                          onError: (error: string) => any,
                          doAlways: (() => any) | null = null): Subscription {

    return httpClient
      .post(Api.prepareURL(ApiUrl.PLAN__USAGE), parameter, this.getHeader(true))
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            onSuccess(response as PlanUsageResponse);
          } else {
            if (!Api.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error?.error?.error, onError);
          if (doAlways) doAlways();
        },
        () => {
          if (doAlways) doAlways();
        });
  }

  public static licensePricing(httpClient: HttpClient,
                               parameter: LicensePricingParams,
                               onSuccess: (response: LicensePrice) => any,
                               onError: (error: string) => any,
                               doAlways: () => any, billingPeriod: string | null = null): Subscription {

    let url = Api.prepareURL(ApiUrl.PLAN__PRICING);
    if (billingPeriod) {
      url += `?billing_period=${billingPeriod}`
    }

    return httpClient
      .post(url, parameter, this.getHeader(true))
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            let data = response.data as LicensePricingResponse;
            onSuccess({
              billing_period: data.billing_period,
              plan_name: data.plan_name,
              price_plan: data.price_plan,
              price_member: data.price_member,
              taxes: data.taxes,
              highlights: data.highlights
            });
          } else {
            if (!Api.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error?.error?.error, onError);
        },
        () => {
          doAlways();
        });
  }

  public static planAvailable(httpClient: HttpClient,
                              parameter: LicensePricingParams,
                              onSuccess: (response: PlanAvailableResponse) => any,
                              onError: (error: string) => any,
                              doAlways: () => any): Subscription {

    return httpClient
      .post(Api.prepareURL(ApiUrl.PLAN__AVAILABLE), parameter, this.getHeader(true))
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            let data = response.data as PlanAvailableResponse;
            onSuccess(data);
          } else {
            if (!Api.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error?.error?.error, onError);
        },
        () => {
          doAlways();
        });
  }

  public static addOnSmsPricing(httpClient: HttpClient,
                                parameter: AddOnSmsPricingRequest,
                                onSuccess: (response: AddOnPrice) => any,
                                onError: (error: string) => any,
                                doAlways: () => any): Subscription {

    return httpClient
      .post(Api.prepareURL(ApiUrl.ADD_ON__SMS__PRICING), parameter, this.getHeader(true))
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            let data = response.data as AddOnSmsPricingResponse;
            onSuccess({
              price_per_unit: data.price_per_unit,
              unit: data.unit,
              billing_period: data.billing_period
            });
          } else {
            if (!Api.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error?.error?.error, onError);
        },
        () => {
          doAlways();
        });
  }

  public static addOnSmsPricingPreview(httpClient: HttpClient,
                                       parameter: AddOnSmsPricingPreviewRequest,
                                       onSuccess: (response: LicenseCheckoutPreview) => any, // todo: response
                                       onError: (error: string) => any,
                                       doAlways: () => any): Subscription {

    return httpClient
      .post(Api.prepareURL(ApiUrl.ADD_ON__SMS__PRICING__PREVIEW), parameter, this.getHeader(true))
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            let data = response.data as AddOnSmsPricingPreviewResponse;
            onSuccess({
              tax_rate_percentage: data.tax_rate_percentage,
              tax_rate_name: data.tax_rate_name,
              tax_rate_amount: data.tax_rate_amount,
              is_reverse_charge: data.is_reverse_charge,
              subtotal: data.subtotal,
              total: data.total,
              is_credit_advice: false, // todo
              discounts: data.discounts
            });
          } else {
            if (!Api.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error?.error?.error, onError);
        },
        () => {
          doAlways();
        });
  }

  public static licenseCheckoutPreview(httpClient: HttpClient,
                                       parameter: LicenseCheckoutPreviewParams,
                                       onSuccess: (response: LicenseCheckoutPreview) => any,
                                       onError: (error: string) => any,
                                       doAlways: () => any, billingPeriod: string | null = null): Subscription {

    let url = Api.prepareURL(ApiUrl.LICENSE__CHECKOUT__PREVIEW);
    if (billingPeriod) {
      url += `?billing_period=${billingPeriod}`
    }

    return httpClient
      .post(url, parameter, this.getHeader(true))
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            let data = response.data as LicenseCheckoutPreviewResponse;
            onSuccess({
              tax_rate_percentage: data.tax_rate_percentage,
              tax_rate_name: data.tax_rate_name,
              tax_rate_amount: data.tax_rate_amount,
              is_reverse_charge: data.is_reverse_charge,
              subtotal: data.subtotal,
              total: data.total,
              is_credit_advice: false, // todo
              discounts: data.discounts
            });
          } else {
            if (!Api.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error?.error?.error, onError);
        },
        () => {
          doAlways();
        });
  }

  public static licenseChangePreview(httpClient: HttpClient,
                                     parameter: LicenseCheckoutPreviewParams,
                                     onSuccess: (response: LicenseChangePreview) => any,
                                     onError: (error: string) => any,
                                     doAlways: () => any, billingPeriod: string | null = null): Subscription {

    let url = Api.prepareURL(ApiUrl.LICENSE__CHANGE__PREVIEW);
    if (billingPeriod) {
      url += `?billing_period=${billingPeriod}`
    }

    return httpClient
      .post(url, parameter, this.getHeader(true))
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            let data = response.data as LicenseChangePreviewResponse;
            onSuccess({
              tax_rate_percentage: data.tax_rate_percentage,
              tax_rate_name: data.tax_rate_name,
              tax_rate_amount: data.tax_rate_amount,
              is_reverse_charge: data.is_reverse_charge,
              subtotal: data.subtotal,
              total: data.total,
              is_credit_advice: data.is_credit_advice ?? false,
              discounts: data.discounts,
              lines: data.lines
            });
          } else {
            if (!Api.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error?.error?.error, onError);
        },
        () => {
          doAlways();
        });
  }

  public static licenseCancel(httpClient: HttpClient,
                              parameter: LicenseCancelParams,
                              onSuccess: (response: LicenseCancelResponse) => any,
                              onError: (error: string) => any,
                              doAlways: (() => any) | null = null): Subscription {

    return httpClient
      .post(Api.prepareURL(ApiUrl.LICENSE__CANCEL), parameter, this.getHeader(true))
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            onSuccess(response as LicenseCancelResponse);
          } else {
            if (!Api.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error?.error?.error, onError);
        },
        () => {
          if (doAlways) doAlways();
        });
  }

  public static licenseSchedule(httpClient: HttpClient,
                                parameter: LicenseScheduleParams,
                                onSuccess: (response: LicenseScheduleResponse) => any,
                                onError: (error: string) => any,
                                doAlways: (() => any) | null = null): Subscription {

    return httpClient
      .post(Api.prepareURL(ApiUrl.LICENSE__SCHEDULE), parameter, this.getHeader(true))
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            onSuccess(response as LicenseScheduleResponse);
          } else {
            if (!Api.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error?.error?.error, onError);
        },
        () => {
          if (doAlways) doAlways();
        });
  }

  public static licenseRenew(httpClient: HttpClient,
                             parameter: LicenseRenewParams,
                             onSuccess: () => any,
                             onError: (error: string) => any,
                             doAlways: (() => any) | null = null): Subscription {

    return httpClient
      .post(Api.prepareURL(ApiUrl.LICENSE__RENEW), parameter, this.getHeader(true))
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            onSuccess();
          } else {
            if (!Api.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error?.error?.error, onError);
        },
        () => {
          if (doAlways) doAlways();
        });
  }

  public static addOnsAvailable(httpClient: HttpClient,
                                parameter: AddOnsAvailableParams,
                                onSuccess: (response: AddOnsAvailableResponse) => any,
                                onError: (error: string) => any,
                                doAlways: () => any): Subscription {

    return httpClient
      .post(Api.prepareURL(ApiUrl.ADD_ONS__AVAILABLE), parameter, this.getHeader(true))
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            onSuccess(response.data as AddOnsAvailableResponse);
          } else {
            if (!Api.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error?.error?.error, onError);
        },
        () => {
          doAlways();
        });
  }

  public static invoiceAddressUpdate(httpClient: HttpClient,
                                     parameter: InvoiceAddressUpdateRequest,
                                     onSuccess: () => any,
                                     onError: (error: string) => any,
                                     doAlways: () => any): Subscription {

    return httpClient
      .post(Api.prepareURL(ApiUrl.INVOICE_ADDRESS__UPDATE), parameter, this.getHeader(true))
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            onSuccess();
          } else {
            if (!Api.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error?.error?.error, onError);
          doAlways();
        },
        () => {
          doAlways();
        });
  }

  public static paymentMethodUpdateDefault(httpClient: HttpClient,
                                           parameter: PaymentMethodUpdateDefaultRequest,
                                           onSuccess: () => any,
                                           onError: (error: string) => any,
                                           doAlways: (() => any) | null = null): Subscription {

    return httpClient
      .post(Api.prepareURL(ApiUrl.PAYMENT_METHOD__UPDATE__DEFAULT), parameter, this.getHeader(true))
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            onSuccess();
          } else {
            if (!Api.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error?.error?.error, onError);
          if (doAlways) doAlways();
        },
        () => {
          if (doAlways) doAlways();
        });
  }

  public static licensePurchase(httpClient: HttpClient,
                                parameter: PurchaseLicenseParams,
                                onSuccess: () => any,
                                onError: (error: string) => any,
                                doAlways: () => any, billingPeriod: string | null = null): Subscription {

    let url = Api.prepareURL(ApiUrl.LICENSE__CHECKOUT__PURCHASE);
    if (billingPeriod) {
      url += `?billing_period=${billingPeriod}`
    }

    return httpClient
      .post(url, parameter, this.getHeader(true))
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            onSuccess();
          } else {
            if (!Api.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error?.error?.error, onError);
        },
        () => {
          doAlways();
        });
  }

  public static licenseUpdate(httpClient: HttpClient,
                              parameter: LicenseUpdateParams,
                              onSuccess: () => any,
                              onError: (error: string) => any,
                              doAlways: () => any): Subscription {

    return httpClient
      .post(Api.prepareURL(ApiUrl.LICENSE__UPDATE), parameter, this.getHeader(true))
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            onSuccess();
          } else {
            if (!Api.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error?.error?.error, onError);
        },
        () => {
          doAlways();
        });
  }

  public static paymentMethodsSetupIntentCreate(httpClient: HttpClient,
                                                parameter: PaymentMethodsSetupIntentCreateParams,
                                                onSuccess: (response: PaymentMethodsSetupIntentCreateResponse) => any,
                                                onError: (error: string) => any,
                                                doAlways: () => any): Subscription {

    return httpClient
      .post(Api.prepareURL(ApiUrl.PAYMENT_METHODS__SETUP_INTENT__CREATE), parameter, this.getHeader(true))
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            onSuccess(response.data as PaymentMethodsSetupIntentCreateResponse);
          } else {
            if (!Api.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error?.error?.error, onError);
        },
        () => {
          doAlways();
        });
  }

  public static paymentMethod(httpClient: HttpClient,
                              parameter: PaymentMethodParams,
                              onSuccess: (invoiceAddress: InvoiceAddress, paymentMethod: PaymentMethod | null) => any,
                              onError: (error: string) => any,
                              doAlways: () => any): Subscription {

    return httpClient
      .post(Api.prepareURL(ApiUrl.PAYMENT_METHOD), parameter, this.getHeader(true))
      .pipe(map(response => response as any))
      .subscribe((response: PaymentMethodResponse) => {
          if (response.status === 200) {
            onSuccess(response.data.invoice_address, response.data.payment_method);
          } else {
            if (!Api.handleCommonError(response.error ?? 'unknown')) {
              onError(response.error ?? 'unknown');
            }
          }
        },
        error => {
          this.handleError(error?.error?.error, onError);
          doAlways();
        },
        () => {
          doAlways();
        });
  }

  public static bindings(httpClient: HttpClient,
                         parameter: BindingsParams,
                         onSuccess: (response: BindingsResponse) => any,
                         onError: (error: string) => any,
                         doAlways: () => any): Subscription {

    return httpClient
      .post(Api.prepareURL(ApiUrl.BINDINGS), parameter, this.getHeader(true))
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            onSuccess(response.data as BindingsResponse);
          } else {
            if (!Api.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error?.error?.error, onError);
        },
        () => {
          doAlways();
        });
  }

  public static actionNotification(httpClient: HttpClient,
                                   organizationId: string,
                                   onSuccess: (response: ActionNotificationGetResponse) => any,
                                   onError: (error: string) => any,
                                   doAlways: () => any): Subscription {

    // todo: urlencode organizationId
    let url = `${Api.prepareURL(ApiUrl.ACTION_NOTIFICATION)}?organizationId=${organizationId}`;

    return httpClient
      .get(url, this.getHeader(true))
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            onSuccess(response.data as ActionNotificationGetResponse);
          } else {
            if (!Api.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error?.error?.error, onError);
        },
        () => {
          doAlways();
        });
  }

  public static actionNotificationId(httpClient: HttpClient,
                                     organizationId: string,
                                     actionNotificationType: string,
                                     onSuccess: (response: ActionNotificationIdGetResponse) => any,
                                     onError: (error: string) => any,
                                     doAlways: () => any): Subscription {

    // todo: urlencode organizationId, actionNotificationType
    let url = `${Api.prepareURL(ApiUrl.ACTION_NOTIFICATION)}/${actionNotificationType}?organizationId=${organizationId}`;

    return httpClient
      .get(url, this.getHeader(true))
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            onSuccess(response.data as ActionNotificationIdGetResponse);
          } else {
            if (!Api.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error?.error?.error, onError);
        },
        () => {
          doAlways();
        });
  }

  public static bindingsLwzOoeInfo(httpClient: HttpClient,
                                   parameter: BindingsLwzOoeInfoParams,
                                   onSuccess: (response: BindingsLwzOoeInfoResponse) => any,
                                   onError: (error: string) => any,
                                   doAlways: () => any): Subscription {

    return httpClient
      .post(Api.prepareURL(ApiUrl.BINDINGS__LWZOOE__INFO), parameter, this.getHeader(true))
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            onSuccess(response.data as BindingsLwzOoeInfoResponse);
          } else {
            if (!Api.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error?.error?.error, onError);
        },
        () => {
          doAlways();
        });
  }

  public static bindingsLwzOoe2Info(httpClient: HttpClient,
                                    parameter: BindingsLwzOoe2InfoParams,
                                    onSuccess: (response: BindingsLwzOoe2InfoResponse) => any,
                                    onError: (error: string) => any,
                                    doAlways: () => any): Subscription {

    return httpClient
      .post(Api.prepareURL(ApiUrl.BINDINGS__LWZ_OOE__INFO), parameter, this.getHeader(true))
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            onSuccess(response.data as BindingsLwzOoe2InfoResponse);
          } else {
            if (!Api.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error?.error?.error, onError);
        },
        () => {
          doAlways();
        });
  }

  public static bindingsLwzOoeRequest(httpClient: HttpClient,
                                      parameter: BindingsLwzOoeRequestParams,
                                      onSuccess: () => any,
                                      onError: (error: string) => any,
                                      doAlways: () => any): Subscription {

    return httpClient
      .post(Api.prepareURL(ApiUrl.BINDINGS__LWZ_OOE__REQUEST), parameter, this.getHeader(true))
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            onSuccess();
          } else {
            if (!Api.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error?.error?.error, onError);
        },
        () => {
          doAlways();
        });
  }

  public static bindingsConnectInfo(httpClient: HttpClient,
                                    parameter: BindingsConnectInfoParams,
                                    onSuccess: (response: BindingsConnectInfoResponse) => any,
                                    onError: (error: string) => any,
                                    doAlways: () => any): Subscription {

    return httpClient
      .post(Api.prepareURL(ApiUrl.BINDINGS__CONNECT__INFO), parameter, this.getHeader(true))
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            onSuccess(response.data as BindingsConnectInfoResponse);
          } else {
            if (!Api.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error?.error?.error, onError);
        },
        () => {
          doAlways();
        });
  }

  public static bindingsConnectEnable(httpClient: HttpClient,
                                      parameter: BindingsConnectEnableParams,
                                      onSuccess: (response: BindingsConnectEnableResponse) => any,
                                      onError: (error: string) => any,
                                      doAlways: () => any): Subscription {

    return httpClient
      .post(Api.prepareURL(ApiUrl.BINDINGS__CONNECT__ENABLE), parameter, this.getHeader(true))
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            onSuccess(response.data as BindingsConnectEnableResponse);
          } else {
            if (!Api.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error?.error?.error, onError);
        },
        () => {
          doAlways();
        });
  }

  public static bindingsLsTirolInfo(httpClient: HttpClient,
                                    parameter: BindingsLsTirolInfoParams,
                                    onSuccess: (response: BindingsLsTirolInfoResponse) => any,
                                    onError: (error: string) => any,
                                    doAlways: () => any): Subscription {

    return httpClient
      .post(Api.prepareURL(ApiUrl.BINDINGS__LS_TIROL__INFO), parameter, this.getHeader(true))
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            onSuccess(response.data as BindingsLsTirolInfoResponse);
          } else {
            if (!Api.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error?.error?.error, onError);
        },
        () => {
          doAlways();
        });
  }

  public static bindingsWasserkarteInfo(httpClient: HttpClient,
                                        parameter: BindingsWasserkarteInfoParams,
                                        onSuccess: (response: BindingsWasserkarteInfoResponse) => any,
                                        onError: (error: string) => any,
                                        doAlways: () => any): Subscription {

    return httpClient
      .post(Api.prepareURL(ApiUrl.BINDINGS__WASSERKARTE__INFO), parameter, this.getHeader(true))
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            onSuccess(response.data as BindingsWasserkarteInfoResponse);
          } else {
            if (!Api.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error?.error?.error, onError);
        },
        () => {
          doAlways();
        });
  }

  public static bindingsRescueTabletInfo(httpClient: HttpClient,
                                         parameter: BindingsRescueTabletInfoParams,
                                         onSuccess: (response: BindingsRescueTabletInfoResponse) => any,
                                         onError: (error: string) => any,
                                         doAlways: () => any): Subscription {

    return httpClient
      .post(Api.prepareURL(ApiUrl.BINDINGS__RESCUE_TABLET__INFO), parameter, this.getHeader(true))
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            onSuccess(response.data as BindingsRescueTabletInfoResponse);
          } else {
            if (!Api.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error?.error?.error, onError);
        },
        () => {
          doAlways();
        });
  }

  public static bindingsMapTripInfo(httpClient: HttpClient,
                                    parameter: BindingsMapTripInfoRequest,
                                    onSuccess: (response: BindingsMapTripInfoResponse) => any,
                                    onError: (error: string) => any,
                                    doAlways: () => any): Subscription {

    return httpClient
      .post(Api.prepareURL(ApiUrl.BINDINGS__MAPTRIP__INFO), parameter, this.getHeader(true))
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            onSuccess(response.data as BindingsMapTripInfoResponse);
          } else {
            if (!Api.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error?.error?.error, onError);
        },
        () => {
          doAlways();
        });
  }

  public static bindingsLwzOoeEnable(httpClient: HttpClient,
                                     parameter: any,
                                     onSuccess: () => any,
                                     onError: (error: string) => any,
                                     doAlways: () => any): Subscription {

    return httpClient
      .post(Api.prepareURL(ApiUrl.BINDINGS__LWZOOE__ENABLE), parameter, this.getHeader(true))
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            onSuccess();
          } else {
            if (!Api.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error?.error?.error, onError);
        },
        () => {
          doAlways();
        });
  }

  public static bindingsLsTirolEnable(httpClient: HttpClient,
                                      parameter: BindingsLsTirolEnableParams,
                                      onSuccess: () => any,
                                      onError: (error: string) => any,
                                      doAlways: () => any): Subscription {

    return httpClient
      .post(Api.prepareURL(ApiUrl.BINDINGS__LS_TIROL__ENABLE), parameter, this.getHeader(true))
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            onSuccess();
          } else {
            if (!Api.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error?.error?.error, onError);
        },
        () => {
          doAlways();
        });
  }

  public static bindingsWasserkarteEnable(httpClient: HttpClient,
                                          parameter: BindingsWasserkarteEnableParams,
                                          onSuccess: () => any,
                                          onError: (error: string) => any,
                                          doAlways: () => any): Subscription {

    return httpClient
      .post(Api.prepareURL(ApiUrl.BINDINGS__WASSERKARTE__ENABLE), parameter, this.getHeader(true))
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            onSuccess();
          } else {
            if (!Api.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error?.error?.error, onError);
        },
        () => {
          doAlways();
        });
  }

  public static bindingsRescueTabletEnable(httpClient: HttpClient,
                                           parameter: BindingsRescueTabletEnableParams,
                                           onSuccess: () => any,
                                           onError: (error: string) => any,
                                           doAlways: () => any): Subscription {

    return httpClient
      .post(Api.prepareURL(ApiUrl.BINDINGS__RESCUE_TABLET__ENABLE), parameter, this.getHeader(true))
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            onSuccess();
          } else {
            if (!Api.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error?.error?.error, onError);
        },
        () => {
          doAlways();
        });
  }

  public static bindingsMapTripEnable(httpClient: HttpClient,
                                      parameter: BindingsMapTripEnableRequest,
                                      onSuccess: () => any,
                                      onError: (error: string) => any,
                                      doAlways: () => any): Subscription {

    return httpClient
      .post(Api.prepareURL(ApiUrl.BINDINGS__MAPTRIP__ENABLE), parameter, this.getHeader(true))
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            onSuccess();
          } else {
            if (!Api.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error?.error?.error, onError);
        },
        () => {
          doAlways();
        });
  }

  public static bindingsLwzOoeDisable(httpClient: HttpClient,
                                      parameter: any,
                                      onSuccess: () => any,
                                      onError: (error: string) => any,
                                      doAlways: () => any): Subscription {

    return httpClient
      .post(Api.prepareURL(ApiUrl.BINDINGS__LWZOOE__DISABLE), parameter, this.getHeader(true))
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            onSuccess();
          } else {
            if (!Api.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error?.error?.error, onError);
        },
        () => {
          doAlways();
        });
  }

  public static bindingsLsTirolDisable(httpClient: HttpClient,
                                       parameter: BindingsLsTirolDisableParams,
                                       onSuccess: () => any,
                                       onError: (error: string) => any,
                                       doAlways: () => any): Subscription {

    return httpClient
      .post(Api.prepareURL(ApiUrl.BINDINGS__LS_TIROL__DISABLE), parameter, this.getHeader(true))
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            onSuccess();
          } else {
            if (!Api.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error?.error?.error, onError);
        },
        () => {
          doAlways();
        });
  }

  public static bindingsWasserkarteDisable(httpClient: HttpClient,
                                           parameter: BindingsWasserkarteDisableParams,
                                           onSuccess: () => any,
                                           onError: (error: string) => any,
                                           doAlways: () => any): Subscription {

    return httpClient
      .post(Api.prepareURL(ApiUrl.BINDINGS__WASSERKARTE__DISABLE), parameter, this.getHeader(true))
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            onSuccess();
          } else {
            if (!Api.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error?.error?.error, onError);
        },
        () => {
          doAlways();
        });
  }

  public static bindingsRescueTabletDisable(httpClient: HttpClient,
                                            parameter: BindingsRescueTabletDisableParams,
                                            onSuccess: () => any,
                                            onError: (error: string) => any,
                                            doAlways: () => any): Subscription {

    return httpClient
      .post(Api.prepareURL(ApiUrl.BINDINGS__RESCUE_TABLET__DISABLE), parameter, this.getHeader(true))
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            onSuccess();
          } else {
            if (!Api.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error?.error?.error, onError);
        },
        () => {
          doAlways();
        });
  }

  public static bindingsMapTripDisable(httpClient: HttpClient,
                                       parameter: BindingsMapTripDisableRequest,
                                       onSuccess: () => any,
                                       onError: (error: string) => any,
                                       doAlways: () => any): Subscription {

    return httpClient
      .post(Api.prepareURL(ApiUrl.BINDINGS__MAPTRIP__DISABLE), parameter, this.getHeader(true))
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            onSuccess();
          } else {
            if (!Api.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error?.error?.error, onError);
        },
        () => {
          doAlways();
        });
  }

  public static bindingsConnectDisable(httpClient: HttpClient,
                                       parameter: BindingsConnectDisableParams,
                                       onSuccess: () => any,
                                       onError: (error: string) => any,
                                       doAlways: () => any): Subscription {

    return httpClient
      .post(Api.prepareURL(ApiUrl.BINDINGS__CONNECT__DISABLE), parameter, this.getHeader(true))
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            onSuccess();
          } else {
            if (!Api.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error?.error?.error, onError);
        },
        () => {
          doAlways();
        });
  }

  public static bindingsConnectInfoSettingsUpdate(httpClient: HttpClient,
                                                  parameter: BindingsConnectInfoSettingsUpdateParams,
                                                  onSuccess: () => any,
                                                  onError: (error: string) => any,
                                                  doAlways: () => any): Subscription {

    return httpClient
      .post(Api.prepareURL(ApiUrl.BINDINGS__CONNECT__INFO__SETTINGS__UPDATE), parameter, this.getHeader(true))
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            onSuccess();
          } else {
            if (!Api.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error?.error?.error, onError);
        },
        () => {
          doAlways();
        });
  }

  public static organizationInformation(httpClient: HttpClient,
                                        parameter: OrganizationInformationParams,
                                        onSuccess: (response: OrganizationInformationResponse) => any,
                                        onError: (error: string) => any,
                                        doAlways: () => any): Subscription {

    return httpClient
      .post(Api.prepareURL(ApiUrl.ORGANIZATION__INFORMATION), parameter, this.getHeader(true))
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            onSuccess(response.data as OrganizationInformationResponse);
          } else {
            if (!Api.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error?.error?.error, onError);
        },
        () => {
          doAlways();
        });
  }

  public static privacyGetSetting(httpClient: HttpClient,
                                  organizationId: string,
                                  onSuccess: (data: PrivacyGetSettingResponseData) => any,
                                  onError: (error: string) => any,
                                  doAlways: () => any): Subscription {

    // todo: urlencode
    let url = `${Api.prepareURL(ApiUrl.PRIVACY__SETTING)}?organizationId=${organizationId}`;

    return httpClient
      .get(url, this.getHeader(true))
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            onSuccess(response.data as PrivacyGetSettingResponseData);
          } else {
            if (!Api.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error?.error?.error, onError);
        },
        () => {
          doAlways();
        });
  }

  public static privacyPostSetting(httpClient: HttpClient,
                                   organizationId: string,
                                   request: PrivacyPostSettingRequest,
                                   onSuccess: () => any,
                                   onError: (error: string) => any,
                                   doAlways: () => any): Subscription {

    // todo: urlencode
    let url = `${Api.prepareURL(ApiUrl.PRIVACY__SETTING)}?organizationId=${organizationId}`;

    return httpClient
      .post(url, request, this.getHeader(true))
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            onSuccess();
          } else {
            if (!Api.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error?.error?.error, onError);
        },
        () => {
          doAlways();
        });
  }

  public static organizationTypes(httpClient: HttpClient,
                                  onSuccess: (response: OrganizationTypesResponse) => any,
                                  onError: (error: string) => any,
                                  doAlways: () => any): Subscription {

    return httpClient
      .get(Api.prepareURL(ApiUrl.ORGANIZATION_TYPES))
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            onSuccess(response.data as OrganizationTypesResponse);
          } else {
            if (!Api.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error?.error?.error, onError);
        },
        () => {
          doAlways();
        });
  }

  public static countries(httpClient: HttpClient,
                          onSuccess: (response: CountriesResponse) => any,
                          onError: (error: string) => any,
                          doAlways: () => any): Subscription {

    return httpClient
      .get(Api.prepareURL(ApiUrl.COUNTRIES))
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            onSuccess(response.data as CountriesResponse);
          } else {
            if (!Api.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error?.error?.error, onError);
          doAlways();
        },
        () => {
          doAlways();
        });
  }

  public static organizationInformationUpdate(httpClient: HttpClient,
                                              parameter: OrganizationInformationUpdateParams,
                                              onSuccess: () => any,
                                              onError: (error: string) => any,
                                              doAlways: () => any): Subscription {

    return httpClient
      .post(Api.prepareURL(ApiUrl.ORGANIZATION__INFORMATION__UPDATE), parameter, this.getHeader(true))
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            onSuccess();
          } else {
            if (!Api.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error?.error?.error, onError);
          doAlways();
        },
        () => {
          doAlways();
        });
  }

  public static organizationTypeUpdate(httpClient: HttpClient,
                                       parameter: OrganizationTypeUpdateParams,
                                       onSuccess: () => any,
                                       onError: (error: string) => any,
                                       doAlways: () => any): Subscription {

    return httpClient
      .post(Api.prepareURL(ApiUrl.ORGANIZATION__TYPE__UPDATE), parameter, this.getHeader(true))
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            onSuccess();
          } else {
            if (!Api.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error?.error?.error, onError);
          doAlways();
        },
        () => {
          doAlways();
        });
  }

  public static interfaceActive(httpClient: HttpClient,
                                parameter: InterfaceActiveParams,
                                onSuccess: (response: InterfaceActiveResponse) => any,
                                onError: (error: string) => any,
                                doAlways: () => any): Subscription {

    return httpClient
      .post(Api.prepareURL(ApiUrl.INTERFACE__ACTIVE), parameter, this.getHeader(true))
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            onSuccess(response.data as InterfaceActiveResponse);
          } else {
            if (!Api.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error?.error?.error, onError);
          doAlways();
        },
        () => {
          doAlways();
        });
  }

  public static interfaceCreate(httpClient: HttpClient,
                                parameter: InterfaceCreateParams,
                                onSuccess: (response: InterfaceCreateResponse) => any,
                                onError: (error: string) => any,
                                doAlways: () => any): Subscription {

    return httpClient
      .post(Api.prepareURL(ApiUrl.INTERFACE__CREATE), parameter, this.getHeader(true))
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            onSuccess(response.data as InterfaceCreateResponse);
          } else {
            if (!Api.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error?.error?.error, onError);
          doAlways();
        },
        () => {
          doAlways();
        });
  }

  public static interfaceDelete(httpClient: HttpClient,
                                parameter: InterfaceDeleteParams,
                                onSuccess: () => any,
                                onError: (error: string) => any,
                                doAlways: () => any): Subscription {

    return httpClient
      .post(Api.prepareURL(ApiUrl.INTERFACE__DELETE), parameter, this.getHeader(true))
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            onSuccess();
          } else {
            if (!Api.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error?.error?.error, onError);
          doAlways();
        },
        () => {
          doAlways();
        });
  }

  public static organizationOperationCategories(httpClient: HttpClient,
                                                organizationId: string,
                                                onSuccess: (response: OrganizationOperationCategoriesResponse) => any,
                                                onError: (error: string) => any,
                                                doAlways: () => any): Subscription {

    let url = Api.prepareURL(ApiUrl.ORGANIZATION__OPERATION_CATEGORIES)
      .replace("{organizationId}", organizationId);

    return httpClient
      .get(url, this.getHeader(true))
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            onSuccess(response.data as OrganizationOperationCategoriesResponse);
          } else {
            if (!Api.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
          doAlways();
        },
        error => {
          this.handleError(error?.error?.error, onError);
        },
        () => {
          doAlways();
        });
  }

  public static bindingsExternalICalendars(httpClient: HttpClient,
                                           parameter: BindingsExternalICalendarsRequest,
                                           onSuccess: (response: BindingsExternalICalendarsResponse) => any,
                                           onError: (error: string) => any,
                                           doAlways: () => any): Subscription {

    return httpClient
      .post(Api.prepareURL(ApiUrl.BINDINGS__EXTERNAL_ICALENDARS), parameter, this.getHeader(true))
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            onSuccess(response.data as BindingsExternalICalendarsResponse);
          } else {
            if (!Api.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error?.error?.error, onError);
        },
        () => {
          doAlways();
        });
  }

  public static bindingsExternalICalendarsAdd(httpClient: HttpClient,
                                              parameter: BindingsExternalICalendarsAddRequest,
                                              onSuccess: () => any,
                                              onError: (error: string) => any,
                                              doAlways: () => any): Subscription {

    return httpClient
      .post(Api.prepareURL(ApiUrl.BINDINGS__EXTERNAL_ICALENDARS__ADD), parameter, this.getHeader(true))
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            onSuccess();
          } else {
            if (!Api.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error?.error?.error, onError);
          doAlways();
        },
        () => {
          doAlways();
        });
  }

  public static bindingsExternalICalendarsDelete(httpClient: HttpClient,
                                                 parameter: BindingsExternalICalendarsDeleteRequest,
                                                 onSuccess: () => any,
                                                 onError: (error: string) => any,
                                                 doAlways: () => any): Subscription {

    return httpClient
      .post(Api.prepareURL(ApiUrl.BINDINGS__EXTERNAL_ICALENDARS__DELETE), parameter, this.getHeader(true))
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            onSuccess();
          } else {
            if (!Api.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error?.error?.error, onError);
          doAlways();
        },
        () => {
          doAlways();
        });
  }

  public static addOnSmsEnable(httpClient: HttpClient,
                               parameter: AddOnSmsEnableRequest,
                               onSuccess: () => any,
                               onError: (error: string) => any,
                               doAlways: () => any): Subscription {

    return httpClient
      .post(Api.prepareURL(ApiUrl.ADD_ON__SMS__ENABLE), parameter, this.getHeader(true))
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            onSuccess();
          } else {
            if (!Api.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error?.error?.error, onError);
        },
        () => {
          doAlways();
        });
  }

  public static addOnSmsDisable(httpClient: HttpClient,
                                parameter: AddOnSmsDisableRequest,
                                onSuccess: () => any,
                                onError: (error: string) => any,
                                doAlways: () => any): Subscription {

    return httpClient
      .post(Api.prepareURL(ApiUrl.ADD_ON__SMS__DISABLE), parameter, this.getHeader(true))
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            onSuccess();
          } else {
            if (!Api.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error?.error?.error, onError);
        },
        () => {
          doAlways();
        });
  }

  public static addOnSms(httpClient: HttpClient,
                         organizationId: string,
                         onSuccess: (response: AddOnSmsResponse) => any,
                         onError: (error: string) => any,
                         doAlways: () => any): Subscription {

    let url = `${Api.prepareURL(ApiUrl.ADD_ON__SMS)}?organizationId=${organizationId}`;

    return httpClient
      .get(url, this.getHeader(true))
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            onSuccess(response.data as AddOnSmsResponse);
          } else {
            if (!Api.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error?.error?.error, onError);
        },
        () => {
          doAlways();
        });
  }

  public static addOnSmsHistory(httpClient: HttpClient,
                                organizationId: string,
                                onSuccess: (response: AddOnSmsHistoryResponse) => any,
                                onError: (error: string) => any,
                                doAlways: () => any): Subscription {

    // todo: urlencode
    let url = `${Api.prepareURL(ApiUrl.ADD_ON__SMS__HISTORY)}?organizationId=${organizationId}`;

    return httpClient
      .get(url, this.getHeader(true))
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            onSuccess(response.data as AddOnSmsHistoryResponse);
          } else {
            if (!Api.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error?.error?.error, onError);
        },
        () => {
          doAlways();
        });
  }

  public static eventReminderScheduleType(httpClient: HttpClient,
                                          onSuccess: (response: EventReminderScheduleTypeResponse) => any,
                                          onError: (error: string) => any,
                                          doAlways: () => any): Subscription {

    return httpClient
      .get(Api.prepareURL(ApiUrl.EVENT__REMINDER__SCHEDULE_TYPE), this.getHeader(true))
      .pipe(map(response => response as any))
      .subscribe(response => {
          if (response.status === 200) {
            onSuccess(response.data as EventReminderScheduleTypeResponse);
          } else {
            if (!Api.handleCommonError(response.error)) {
              onError(response.error);
            }
          }
        },
        error => {
          this.handleError(error?.error?.error, onError);
        },
        () => {
          doAlways();
        });
  }
}
