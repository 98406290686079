<main>
  <h1>Mitglied registrieren</h1>

  <div id="form">
    <div id="content">
      <mat-form-field appearance="fill">
        <mat-label>Vorname</mat-label>
        <input matInput required [(ngModel)]="firstName" [disabled]="isProcessing">
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Nachname</mat-label>
        <input matInput required [(ngModel)]="lastName" [disabled]="isProcessing">
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>E-Mail-Adresse</mat-label>
        <input matInput required type="email" [(ngModel)]="email" [disabled]="isProcessing">
      </mat-form-field>

      <mat-checkbox [(ngModel)]="hasAcceptedTerms" [disabled]="isProcessing">
        Ich bestätige, dass ich die Erlaubnis des Mitglieds habe, die Registrierung durchzuführen.
      </mat-checkbox>

      <p><br>Nach der Registrierung erhält das Mitglied eine E-Mail mit den nächsten Schritten.</p>

      <button class="btn-action btn-primary" [disabled]="!hasAcceptedTerms || isProcessing" (click)="signUpMember()">
        Mitglied registrieren
      </button>
    </div>
  </div>
</main>
