import {Component, OnDestroy, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {OrganizationService} from "../../../../core/services/organization.service";
import {Router} from "@angular/router";
import {Api} from "../../../../core/network/Api";
import {BindingsLsTirolInfo} from "../../../../core/models/BindingsLsTirolInfo";
import {ToolbarService} from "../../../../core/services/toolbar.service";

@Component({
  selector: 'app-binding-ls-tirol',
  templateUrl: './binding-ls-tirol.component.html',
  styleUrls: ['./binding-ls-tirol.component.scss']
})
export class BindingLsTirolComponent implements OnInit, OnDestroy {
  isLoading = false;

  info: BindingsLsTirolInfo | null = null;

  username: string = "";
  password: string = "";

  constructor(private httpClient: HttpClient, private organizationService: OrganizationService, private router: Router, private toolbarService: ToolbarService) {
  }

  ngOnInit(): void {
    this.toolbarService.setHierarchy([
      {title: "Anbindungen", path: "/home/bindings"}
    ]);
    this.toolbarService.setPageTitle("Leitstelle Tirol (Beta)");

    this.loadData();
  }

  ngOnDestroy() {
    this.toolbarService.resetHierarchy();
  }

  private loadData() {
    this.isLoading = true;
    Api.bindingsLsTirolInfo(this.httpClient, {organization_id: this.organizationService.selectedOrganizationId}, response => {
      this.info = response.info;
    }, (error: string) => {
      // todo: handle error
      this.isLoading = false;
    }, () => {
      this.isLoading = false;
    });
  }

  enableBinding() {
    let username = this.username.trim();
    let password = this.password;

    if (username == '' || password == '') {
      alert('Ungültige Eingaben! Bitte überprüfe deine Eingaben und versuche es nochmal.');
      return;
    }

    Api.bindingsLsTirolEnable(this.httpClient, {
      organization_id: this.organizationService.selectedOrganizationId,
      username: this.username,
      password: this.password
    }, () => {
      this.username = "";
      this.password = "";

      this.loadData();
    }, error => {
      console.log(error);
      switch (error) {
        case 'invalid-credentials':
          alert('Überprüfung der Zugangsdaten fehlgeschlagen. Bitte überprüfe deine Eingaben und versuche es nochmal.');
          break;
        default:
          alert('Unbekannter Fehler beim Aktivieren! Bitte versuche es später nochmal.');
      }
    }, () => {
      // todo
    });
  }

  disableBinding() {
    Api.bindingsLsTirolDisable(this.httpClient, {organization_id: this.organizationService.selectedOrganizationId}, () => {
      this.loadData();
    }, error => {
      // todo
    }, () => {
      // todo
    });
  }

  navigateTo(route: string) {
    this.router.navigateByUrl(route);
  }
}
