import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {OrganizationService} from "../../../../core/services/organization.service";
import {ApiService} from "../../../../core/services/api.service";
import {MatDialog} from "@angular/material/dialog";
import {ToolbarService} from "../../../../core/services/toolbar.service";
import {AlertComponent} from "../../../../dialogs/alert/alert.component";
import {CreateEventModel} from "../../../../core/models/component/CreateEventModel";
import {EventCreateRequest} from "../../../../core/network/request/EventCreateRequest";
import {EventReminderScheduleType} from "../../../../core/network/models/EventReminderScheduleType";
import {Api} from "../../../../core/network/Api";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-create-event',
  templateUrl: './create-event.component.html',
  styleUrls: ['./create-event.component.scss']
})
export class CreateEventComponent implements OnInit, OnDestroy {

  now: Date;

  isLoading = false;
  isCreating = false;

  dateStart: Date;
  dateEnd: Date;

  timeStart: string = '00:00';
  timeEnd: string = '00:00';

  reminderScheduleTypeKeys: EventReminderScheduleType[] = [];

  input: CreateEventModel = {
    title: '',
    text: '',
    alarm: {
      userSelectionTypeId: 1
    },
    address: {
      name: '',
      street: '',
      streetNumber: '',
      floor: '',
      door: '',
      postcode: '',
      city: '',
      country: '',
      freeText: '',
      latitude: 0.0,
      longitude: 0.0
    }
  };

  constructor(private router: Router, private organizationService: OrganizationService, private apiService: ApiService,
              public dialog: MatDialog, private toolbarService: ToolbarService, private httpClient: HttpClient) {
    this.now = new Date();

    this.dateStart = new Date();
    this.dateStart.setSeconds(0);
    this.dateEnd = new Date();
    this.dateEnd.setHours(this.dateEnd.getHours() + 1);
    this.dateEnd.setSeconds(0);

    this.timeStart = this.dateStart.toLocaleTimeString('de-at');
    this.timeEnd = this.dateEnd.toLocaleTimeString('de-at');
  }

  ngOnInit(): void {
    this.toolbarService.setHierarchy([{title: "Ereignisse", path: "/home/events"}]);
    this.toolbarService.setPageTitle("Ereignis erstellen");

    this.loadData();
  }

  ngOnDestroy(): void {
    this.toolbarService.resetHierarchy();
  }

  onCalendarSelectionChange(event: any): void {
    if (this.dateStart > this.dateEnd)
      this.dateEnd = new Date(this.dateStart);
  }

  create(): void {
    console.log('create');
    this.isCreating = true;

    let timeStartParts = this.timeStart.split(':');
    let timeEndParts = this.timeEnd.split(':');

    const timeStart = new Date(this.dateStart);
    timeStart.setHours(parseInt(timeStartParts[0]));
    timeStart.setMinutes(parseInt(timeStartParts[1]));
    const timeEnd = new Date(this.dateEnd);
    timeEnd.setHours(parseInt(timeEndParts[0]));
    timeEnd.setMinutes(parseInt(timeEndParts[1]));
    let reminderScheduleTypeKeys = this.reminderScheduleTypeKeys
      .filter(x => x.isChecked)
      .map(x => x.key);

    let request: EventCreateRequest = {
      organization_id: this.organizationService.getSelectedOrganization()?.id ?? 0,
      title: this.input.title,
      user_selection_type_id: this.input.alarm.userSelectionTypeId,
      text: this.input.text,
      time_start: Math.round(timeStart.getTime() / 1000),
      time_end: Math.round(timeEnd.getTime() / 1000),
      address_firm: this.input.address.name,
      address_street: this.input.address.street,
      address_street_number: this.input.address.streetNumber,
      address_floor: this.input.address.floor,
      address_door: this.input.address.door,
      address_postcode: this.input.address.postcode,
      address_city: this.input.address.city,
      address_country: this.input.address.country,
      address_free_text: this.input.address.freeText,
      is_answerable: true, // todo
      reminder_schedule_type_keys: reminderScheduleTypeKeys
    };

    this.apiService.eventCreate(request, response => {
      this.router.navigateByUrl('/home/events');
    }, error => {
      switch (error) {
        case "not-licensed":
          this.showAlert("Limit erreicht", "Das Limit für die Erstellung von Ereignissen im aktuellen Zeitraum wurde erreicht. Weitere Informationen findest du unter \"Abrechnung\".");
          break;
        default:
          this.showAlert("Unbekannter Fehler", "Unbekannter Fehler beim Erstellen des Ereignisses! Bitte versuche es später nochmal.");
      }
      this.isCreating = false;
    }, () => {
      this.isCreating = false;
    })
  }

  private showAlert(title: string, text: string, afterClosed: (() => any) | null = null) {
    this.dialog.open(AlertComponent, {
      data: {
        title: title,
        text: text
      },
      disableClose: true
    }).afterClosed().subscribe(() => {
      if (afterClosed) afterClosed();
    });
  }

  private loadData(): void {
    Api.eventReminderScheduleType(this.httpClient, response => {
      this.reminderScheduleTypeKeys = response.items.sort((a, b) => {
        return a.sort_priority > b.sort_priority ? 1 : -1;
      });
    }, error => {
      // todo: handle
    }, () => {

    })
  }
}
