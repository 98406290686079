import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {OrganizationService} from '../../../../core/services/organization.service';
import {Router} from '@angular/router';
import {environment} from '../../../../../environments/environment';
import {Api} from '../../../../core/network/Api';
import {Country} from '../../../../core/models/Country';
import {PaymentMethod} from '../../../../core/models/PaymentMethod';
import {InvoiceAddress} from '../../../../core/network/models/InvoiceAddress';
import {MatDialog} from '@angular/material/dialog';
import {AlertComponent} from '../../../../dialogs/alert/alert.component';
import {PaymentMethodParams} from '../../../../core/network/request/PaymentMethodParams';
import {InvoiceAddressUpdateRequest} from '../../../../core/network/request/InvoiceAddressUpdateRequest';
import {PaymentMethodUpdateDefaultRequest} from '../../../../core/network/request/PaymentMethodUpdateDefaultRequest';
import {loadStripe, Stripe, StripeIbanElement} from "@stripe/stripe-js";
import {Location} from "@angular/common";

@Component({
  selector: 'app-update-payment-method',
  templateUrl: './update-payment-method.component.html',
  styleUrls: ['./update-payment-method.component.css']
})
export class UpdatePaymentMethodComponent implements OnInit, AfterViewInit {
  @ViewChild('ibanElement') ibanElement: ElementRef | null = null;

  isLoadingCountries = false;
  isLoadingPaymentMethod = false;
  isLoadingSEPASession = false;

  get isLoading(): boolean {
    return this.isLoadingCountries
      || this.isLoadingPaymentMethod
      || this.isLoadingSEPASession;
  }

  invoiceAddress: InvoiceAddress | null = null;
  countries: Country[] = [];
  paymentMethod: PaymentMethod | null = null;
  invoiceEmailReceiver: string = '';

  paymentMethodType = '';
  bankAccountName: string = '';
  bankAccountEmail: string = '';
  bankName: string = '';
  sepaErrorMessage: string = '';
  acceptSepaConditions = false;

  isSaving = false;

  private stripe: Stripe | null = null;
  private iban: StripeIbanElement | undefined = undefined;
  private clientSecret = '';

  constructor(private httpClient: HttpClient, private organizationService: OrganizationService, private router: Router,
              public dialog: MatDialog, private location: Location) {
  }

  ngOnInit(): void {
    this.loadSEPASession();

    this.loadData();
  }

  ngAfterViewInit(): void {
    loadStripe(environment.isDev ? environment.stripe.keyTest : environment.stripe.key)
      .then(x => {
        this.stripe = x;
        this.setupStripe();
      });
  }

  private setupStripe() {
    const elements = this.stripe?.elements();

    const style = {
      base: {
        color: '#000000',
        fontSize: '16px',
        '::placeholder': {
          color: '#626262'
        },
        fontFamily: 'Inter, sans-serif',
        ':-webkit-autofill': {
          color: '#000000',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
        ':-webkit-autofill': {
          color: '#fa755a',
        },
      },
    };

    const options = {style, supportedCountries: ['SEPA'], placeholderCountry: 'AT'};

    this.iban = elements?.create('iban', options);
    this.iban?.mount(this.ibanElement?.nativeElement);


    this.iban?.on('change', (event) => {
      if (event.error) {
        this.sepaErrorMessage = event.error.message;
      } else {
        this.sepaErrorMessage = '';
      }

      this.bankName = event.bankName ?? '';
    });
  }

  private loadData(): void {
    this.loadCountries();
    this.loadPaymentMethod();
  }

  private loadCountries(): void {
    this.isLoadingCountries = true;

    Api.countries(this.httpClient, response => {
      this.countries = response.countries;
    }, error => {
      this.showAlert("Fehler beim Laden", "Die Seite konnte aufgrund eines Fehlers nicht vollständig geladen werden. Bitte versuche es später nochmal.", () => {
        this.router.navigateByUrl("/home/billing");
      });
    }, () => {
      this.isLoadingCountries = false;
    });
  }

  private loadPaymentMethod(): void {
    this.isLoadingPaymentMethod = true;

    const parameter: PaymentMethodParams = {organization_id: `${this.organizationService.selectedOrganizationId}`};
    Api.paymentMethod(this.httpClient, parameter, (invoiceAddress, paymentMethod) => {
      this.invoiceAddress = invoiceAddress;
      this.paymentMethod = paymentMethod;
      this.paymentMethodType = this.invoiceAddress.is_e_rechnung_enabled
        ? "e-rechnung"
        : (this.paymentMethod != null ? "sepa" : "invoice");
    }, error => {
      this.showAlert("Fehler beim Laden", "Die Seite konnte aufgrund eines Fehlers nicht vollständig geladen werden. Bitte versuche es später nochmal.", () => {
        this.router.navigateByUrl("/home/billing");
      });
    }, () => {
      this.isLoadingPaymentMethod = false;
    });
  }

  loadSEPASession(): void {
    this.isLoadingSEPASession = true;

    Api.paymentMethodsSetupIntentCreate(this.httpClient, {organization_id: `${this.organizationService.selectedOrganizationId}`},
      (response) => {
        this.clientSecret = response.client_secret;
        this.isLoadingSEPASession = false;
      }, (error) => {
        this.showAlert("Fehler beim Laden", "Die Seite konnte aufgrund eines Fehlers nicht vollständig geladen werden. Bitte versuche es später nochmal.", () => {
          this.router.navigateByUrl("/home/billing");
          this.isLoadingSEPASession = false;
        });
      }, () => {
      });
  }

  update() {
    if ((this.invoiceAddress?.name ?? "") == ""
      || (this.invoiceAddress?.street ?? "") == ""
      || (this.invoiceAddress?.postal_code ?? "") == ""
      || (this.invoiceAddress?.city ?? "") == ""
      || (this.invoiceAddress?.country_code ?? "") == ""
      || this.invoiceAddress?.email_address == "") {
      this.showAlert("Eingabe ungültig", "Bitte überprüfe deine Eingaben und versuche es nochmal.");
      return;
    }

    if (this.paymentMethodType == 'sepa_new' && (this.bankAccountName == "" || this.bankAccountEmail == "")) {
      this.showAlert("Lastschrift-Eingaben ungültig", "Bitte überprüfe deine Eingaben und versuche es nochmal.");
      return;
    }

    this.isSaving = true;

    const parameter: InvoiceAddressUpdateRequest = {
      organization_id: this.organizationService.selectedOrganizationId,
      name: this.invoiceAddress!.name,
      street: this.invoiceAddress!.street,
      postal_code: this.invoiceAddress!.postal_code,
      city: this.invoiceAddress!.city,
      country_code: this.invoiceAddress!.country_code,
      vat_id: this.invoiceAddress!.vat_id,
      email_address: this.invoiceAddress!.email_address
    };
    Api.invoiceAddressUpdate(this.httpClient, parameter, () => {
      if (this.invoiceAddress?.is_e_rechnung_enabled ?? false) {
        this.navigateBack();
        return;
      }
      if (this.paymentMethodType == 'sepa_new') {
        this.stripe!.confirmSepaDebitSetup(
          this.clientSecret,
          {
            payment_method: {
              sepa_debit: this.iban!,
              billing_details: {
                name: this.bankAccountName,
                email: this.bankAccountEmail
              }
            }
          }
        ).then((result) => {
          let error = '';
          if (result.error != null) {
            switch (result.error.code) {
              case 'incomplete_iban':
                error = 'Bitte überprüfe deine IBAN auf Richtigkeit und versuche es nochmal.';
                break;
              case 'invalid_owner_name':
                error = 'Bitte überprüfe das Feld "Name" auf Richtigkeit und versuche es nochmal.';
                break;
              case 'email_invalid':
                error = 'Bitte überprüfe deine E-Mail-Adresse auf Richtigkeit und versuche es nochmal.';
                break;
              case 'parameter_invalid_empty':
                error = 'Bitte überprüfe deine Eingaben auf Richtigkeit und versuche es nochmal.';
                break;
              default:
                error = `Fehler: ${result.error.code}`;
            }

            this.showAlert('Fehler', error);
            this.isSaving = false;
          } else {
            const setupIntent = result.setupIntent;

            const parameter: PaymentMethodUpdateDefaultRequest = {
              organization_id: this.organizationService.selectedOrganizationId,
              pm_stripe_id: setupIntent.payment_method!.toString()
            };
            Api.paymentMethodUpdateDefault(this.httpClient, parameter, () => {
              this.navigateBack();
            }, error => {
              this.showAlert("Fehler beim Speichern", "Bitte versuche es später nochmal.", () => {
                this.router.navigateByUrl("/home/billing");
              });
            }, () => {
            });
          }
        });
      } else {
        let pmStripeId = "";
        if (this.paymentMethodType == 'sepa') pmStripeId = this.paymentMethod?.id ?? ""
        else if (this.paymentMethodType == 'e-rechnung') pmStripeId = "e-rechnung";

        const parameter: PaymentMethodUpdateDefaultRequest = {
          organization_id: this.organizationService.selectedOrganizationId,
          pm_stripe_id: pmStripeId
        };
        Api.paymentMethodUpdateDefault(this.httpClient, parameter, () => {
          this.navigateBack();
        }, error => {
          this.showAlert("Fehler beim Speichern", "Bitte versuche es später nochmal.", () => {
            this.router.navigateByUrl("/home/billing");
          });
        }, () => {
        });
      }
    }, error => {
      this.isSaving = false;
      switch (error) {
        case 'invalid-parameter':
          this.showAlert("Eingabe ungültig", "Bitte überprüfe deine Eingaben und versuche es nochmal.")
          break;
        default:
          this.showAlert("Interner Fehler", "Die Zahlungsmethode konnte aufgrund eines internen Problems nicht aktualisiert werden. Bitte versuche es später nochmal.");
      }
    }, () => {
    });
  }

  private showAlert(title: string, text: string, afterClosed: (() => any) | null = null) {
    this.dialog.open(AlertComponent, {
      data: {
        title: title,
        text: text
      },
      disableClose: true
    }).afterClosed().subscribe(() => {
      if (afterClosed) afterClosed();
    });
  }

  navigateBack() {
    this.location.back();
  }
}
