import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ActionNotificationRecipient} from "../../core/models/ActionNotificationRecipient";
import {ApiService} from "../../core/services/api.service";
import {OrganizationService} from "../../core/services/organization.service";

@Component({
  selector: 'app-action-notification-recipient-edit-dialog',
  templateUrl: './action-notification-recipient-edit-dialog.component.html',
  styleUrls: ['./action-notification-recipient-edit-dialog.component.scss']
})
export class ActionNotificationRecipientEditDialogComponent implements OnInit {

  private readonly actionTypeKey: string | null = null;

  isOptionRawContentEnabled = false;
  isOptionExtendedInformationEnabled = false;

  actionNotificationRecipient: ActionNotificationRecipient | null = null;

  constructor(public dialogRef: MatDialogRef<ActionNotificationRecipientEditDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ActionNotificationRecipientEditDialogData | null,
              private apiService: ApiService, private organizationService: OrganizationService) {
    this.actionNotificationRecipient = data?.recipient ?? {
      id: null,
      notice: '',
      transmission_type: 'email',
      address: ''
    } as ActionNotificationRecipient;
    this.actionTypeKey = data?.key ?? null;

    console.log(this.actionTypeKey)
  }

  ngOnInit(): void {
  }

  onCloseClick(event: any): void {
    this.dialogRef.close();
  }

  onSaveClick(event: any): void {
    this.actionNotificationRecipient!.options = [];
    if (this.isOptionRawContentEnabled)
      this.actionNotificationRecipient?.options.push("raw_content");
    if (this.isOptionExtendedInformationEnabled)
      this.actionNotificationRecipient?.options.push("extended_information");

    this.apiService.actionNotificationAdd(`${this.organizationService.getSelectedOrganization()?.id ?? ''}`, this.actionTypeKey ?? '', this.actionNotificationRecipient!!, () => {
      this.dialogRef.close();
    }, error => {
      // todo: show error
    }, () => {

    });
  }
}

export interface ActionNotificationRecipientEditDialogData {
  key: string;
  recipient: ActionNotificationRecipient | null;
}
