import {Component, OnDestroy, OnInit} from '@angular/core';
import {ToolbarService} from "../../../../core/services/toolbar.service";

@Component({
  selector: 'app-add-monitor',
  templateUrl: './add-monitor.component.html',
  styleUrls: ['./add-monitor.component.scss']
})
export class AddMonitorComponent implements OnInit, OnDestroy {

  constructor(private toolbarService: ToolbarService) {
  }

  ngOnInit(): void {
    this.toolbarService.setHierarchy([{title: "Monitore", path: "/home/monitors"}]);
    this.toolbarService.setPageTitle("Monitor hinzufügen");
  }

  ngOnDestroy() {
    this.toolbarService.resetHierarchy();
  }
}
