<main class="sp-dialog">
  <header>
    <div class="header-icon">
      <mat-icon>event</mat-icon>
    </div>
    <div class="header-title">Externen Kalender bearbeiten</div>
    <div class="header-actions">
      <mat-icon matRipple (click)="onCloseClick($event)">close</mat-icon>
    </div>
  </header>
  <div class="content">
    <div class="sp-dialog-loading" *ngIf="isLoading || isSaving">
      <mat-spinner diameter="32" strokeWidth="3"></mat-spinner>
    </div>
    <div id="url">
      <b>Webadresse:</b><br>
      {{ externalICalendar?.url }}
    </div>
    <mat-form-field appearance="fill" color="accent">
      <mat-label>Name</mat-label>
      <input matInput type="text" placeholder="" [(ngModel)]="externalICalendar!.name" [disabled]="isLoading">
    </mat-form-field>

    <mat-checkbox [(ngModel)]="externalICalendar!.send_notifications" [disabled]="isLoading">Benachrichtigung bei
      Erstellung
    </mat-checkbox>
    <mat-checkbox [(ngModel)]="externalICalendar!.sync_tagged_events_only" [disabled]="isLoading">Neue Termine nur mit
      "#smartpager" im Titel oder Beschreibung berücksichtigen
    </mat-checkbox>
    <br><br>
    <mat-form-field appearance="fill">
      <mat-label>Empfängerauswahl</mat-label>
      <mat-select [(ngModel)]="externalICalendar!.user_selection_type">
        <mat-option [value]="''">Keine Empfänger</mat-option>
        <mat-option [value]="'all'">Sammelruf</mat-option>
      </mat-select>
    </mat-form-field>
    <br>
    <b>Erinnerungen</b><br>
    <ul id="reminders">
      <li *ngFor="let st of eventReminderScheduleTypes">
        <mat-checkbox [(ngModel)]="st.isChecked">{{ st.name }}</mat-checkbox>
      </li>
    </ul>
  </div>
  <footer>
    <button class="btn-action btn-danger" (click)="onDeleteClick($event)" [disabled]="isLoading || isSaving">Löschen
    </button>&ensp;
    <button class="btn-action btn-primary" (click)="onSaveClick($event)" [disabled]="isLoading || isSaving">Speichern
    </button>
    <div id="footer-notice" *ngIf="isSaving">
      Der Speichervorgang kann bei vielen Ereignissen einen Moment in Anspruch nehmen.
    </div>
  </footer>
</main>
