import {Injectable} from '@angular/core';
import {ApiService} from "./api.service";
import {Router} from "@angular/router";
import {OrganizationService} from "./organization.service";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public jwt = '';

  constructor(private apiService: ApiService, private organizationService: OrganizationService, private router: Router) {
  }

  isSignedIn(): boolean {
    return localStorage.getItem('jwt') !== null && localStorage.getItem('jwt') !== '';
  }

  signOut(onError: (error: string) => any): void {
    this.apiService.signOut(() => {
      this.clearUserStorage();

      this.router.navigateByUrl('/login?signout=1').then(() => {
        this.clearUserCache();
      });
    }, error => {
      onError(error);
    }, () => {
    })
  }

  clearUserCache(): void {
    this.organizationService.selectedOrganizationId = 0;
    this.organizationService.organizations = [];
  }

  clearUserStorage(): void {
    localStorage.removeItem('jwt');
    localStorage.removeItem('first_name');
    localStorage.removeItem('last_name');
    localStorage.removeItem('organizationId');
  }


}
