<main>
  <h1>Landes-Warnzentrale Oberösterreich</h1>

  <div>
    Automatische Einsatzerstellung verfügbar für alle Feuerwehren in Oberösterreich.
  </div>

  <div class="loading" *ngIf="isLoadingIntegration || isLoadingAddress; else content">
    <mat-spinner color="accent" diameter="32"></mat-spinner>
  </div>

  <ng-template #content>
    <div id="content">
      <div class="disclaimer">
        Diese Anbindungen ersetzen keine Alarmierungssysteme wie Pager oder Sirene. Die Nutzung erfolgt auf eigene
        Verantwortung!
      </div>

      <ul id="options">
        <li *ngIf="info != null">
          <div class="option-header">
            <div>
              <div class="option-header-title">Einfach</div>
              <div class="option-header-subtitle">{{ info.station_name }}, {{ info.district }}</div>
            </div>
            <div>
              <div class="option-status" *ngIf="!(info.type == 'els' && info.status == 'active')">
                {{ (info.type == 'public' && info.status == 'active') || info.type == 'els' ? 'AKTIV' : 'INAKTIV' }}
                <span
                  [class.is_active]="info?.status == 'active' || info?.type == 'els'"></span>
              </div>
            </div>
          </div>
          <div class="option-body">
            <b>Anbindung an die öffentliche Schnittstelle</b>
            <ul>
              <li>Einsatzerstellung zeitverzögert (ca. 2min)</li>
              <li>Keine genauen Einsatzinformationen</li>
              <li>Quelle: einsaetze.ooelfv.at</li>
            </ul>
          </div>
          <div class="option-actions" *ngIf="info.type == 'public'">
            <button class="btn-action btn-primary" *ngIf="info.status != 'active'"
                    (click)="enableBinding('public')" [disabled]="isProcessing">
              Anbindung aktivieren
            </button>
            <button class="btn-action btn-secondary" *ngIf="info.status == 'active'"
                    (click)="disableBinding('public')" [disabled]="isProcessing">
              Anbindung deaktivieren
            </button>
          </div>
        </li>
        <li>
          <div class="option-header">
            <div>
              <div class="option-header-title">Erweitert</div>
              <div class="option-header-subtitle">
                <ng-container *ngIf="info?.type != 'els' ||info?.status == 'disabled'">Aktivierung jetzt beantragen
                </ng-container>
                <ng-container *ngIf="info?.type == 'els' && info?.status == 'active'">Anbindung aktiviert</ng-container>
                <ng-container *ngIf="info?.type == 'els' && info?.status == 'pending'">Auftragsverarbeitungsvertrag
                  ausständig
                </ng-container>
                <ng-container *ngIf="info?.type == 'els' && info?.status == 'activating'">Aktivierung wird beantragt
                </ng-container>
                <ng-container *ngIf="info?.type == 'els' && info?.status == 'unknown'">Unbekannter Status</ng-container>
              </div>
            </div>
            <div>
              <div class="option-status">
                <ng-container *ngIf="info?.type != 'els' || info?.status == 'disabled'">INAKTIV</ng-container>
                <ng-container *ngIf="info?.type == 'els' && info?.status == 'active'">AKTIV</ng-container>
                <ng-container *ngIf="info?.type == 'els' && info?.status == 'pending'">INAKTIV</ng-container>
                <ng-container *ngIf="info?.type == 'els' && info?.status == 'activating'">INAKTIV</ng-container>
                <ng-container *ngIf="info?.type == 'els' && info?.status == 'unknown'">UNBEKANNT</ng-container>
                <span [class.is_active]="info?.type == 'els' && info?.status == 'active'"></span>
              </div>
            </div>
          </div>
          <div class="option-body">
            <b>Anbindung an das Einsatzleitsystem (ELS)</b>
            <ul>
              <li>Einsatzerstellung ohne Verzögerung (<a href="https://www.smartpager.at/connect" target="_blank">Connect</a>
                erforderlich)
              </li>
              <li>Genaue Einsatzinformationen<br>(Einsatzort, Anrufer, Sirenenprogramm, ...)</li>
              <li>Auftragsverarbeitungsvertrag erforderlich</li>
            </ul>

            <div class="info">
              Folgende Daten werden aus Datenschutzgründen nach 3 Monaten automatisch aus dem Einsatz entfernt:
              <ul>
                <li>Einsatztext</li>
                <li>Einsatzadresse</li>
                <li>Einsatzkoordinaten</li>
                <li>Anrufer/Kontakt</li>
              </ul>
            </div>

            <div class="info" *ngIf="info?.type == 'els' && info?.status != 'disabled' && info?.status != 'active'">
              <ng-container *ngIf="info?.status == 'pending'">
                Wir haben eine E-Mail an die E-Mail-Adresse deiner Organisation mit dem erforderlichen
                Auftragsverarbeitungsvertrag gesendet. Bitte sende uns den Vertrag ausgefüllt und unterschrieben zurück.
              </ng-container>
              <ng-container *ngIf="info?.status == 'activating'">
                Wir veranlassen gerade die Aktivierung deiner Feuerwehr beim Landes-Feuerwehrkommando Oberösterreich.
              </ng-container>
              <ng-container *ngIf="info?.status == 'outage'">
                Aufgrund einer Unterbrechung ist die Anbindung derzeit nicht verfügbar.
              </ng-container>
              <ng-container *ngIf="info?.status == 'unknown'">
                Bitte kontaktiere uns für weitere Informationen.
              </ng-container>
            </div>

            <div id="form-advanced">
              <ng-container *ngIf="info?.type != 'els'">
                <div>Die folgenden Daten deiner Feuerwehr werden für die Freischaltung an das Landes-Feuerwehrkommando
                  Oberösterreich übermittelt:
                </div>

                <div id="organization-address">
                  {{ addressName }}<br>
                  {{ addressStreet }}<br>
                  {{ addressPostcode }} {{ addressCity }}
                </div>

                <mat-form-field appearance="fill" color="accent">
                  <mat-label>Feuerwehrnummer (Nummer der Dienststelle)</mat-label>
                  <input matInput type="text" placeholder="4XXXXX" [(ngModel)]="stationId"
                         [disabled]="disableStationIdInput"
                         required>
                </mat-form-field>
                <mat-form-field appearance="fill" color="accent">
                  <mat-label>Sirenencode</mat-label>
                  <input matInput type="text" placeholder="" [(ngModel)]="sirenCode" required>
                </mat-form-field>

                <div id="request-notice">
                  <label><input type="checkbox" [(ngModel)]="acceptTerms"> Ich nehme zur Kenntnis, dass die Verwendung
                    dieser Anbindung auf eigene Verantwortung erfolgt und kein Alarmierungssystem wie Sirene oder Pager
                    ersetzt, da das Landes-Feuerwehrkommando Oberösterreich keine ununterbrochene Verfügbarkeit der
                    Anbindung gewährleistet und das Internet kein ausfallsicheres Medium darstellt.</label>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="option-actions" *ngIf="info?.type != 'els'">
            <button class="btn-action btn-primary" (click)="onRequestAccessAdvancedClick($event)"
                    [disabled]="!acceptTerms || isProcessing">
              Aktivierung beantragen
            </button>
          </div>
        </li>
      </ul>
    </div>
  </ng-template>
</main>
