<main>
  <div id="map"></div>

  <div id="menu">
    <div class="loading" *ngIf="isLoading || operation == null; else menuContent">Lade Daten</div>
    <ng-template #menuContent>
      <div id="category-indicator" *ngIf="operation?.category_color != null"
           [style.background-color]="operation?.category_color ?? '#ddd'"></div>
      <h1>{{ operation?.title }}</h1>
      <div id="time">{{ (operation?.time_start ?? 0) * 1000 | date: 'dd.MM.yyyy HH:mm' }} - <span
        *ngIf="(operation?.time_end ?? 0) > 0">{{ (operation?.time_end ?? 0) * 1000 | date: 'dd.MM.yyyy HH:mm' }}</span>
      </div>
      <div id="text" *ngIf="(operation?.text ?? '') != ''">{{ operation?.text }}</div>
      <section id="address">
        <h2>Adresse</h2>
        <div [innerHTML]="preparedAddress"></div>
      </section>
      <section id="answers">
        <h2>Einsatzkräfte ({{ (operation?.answers?.length ?? 0) }})</h2>
        <div id="answers-empty" *ngIf="(operation?.answers?.length ?? 0) <= 0">Noch keine Zusagen.</div>
        <ul>
          <li *ngFor="let a of operation?.answers">{{ a.first_name }} {{ a.last_name }}</li>
        </ul>
      </section>
      <section id="actions">
        <button *ngIf="showEndOperationBtn" (click)="onEndOperationBtnClick($event)" [disabled]="isEndingOperation">
          Einsatz beenden
        </button>
      </section>
    </ng-template>
  </div>

  <div id="protocol">
    <div id="protocol-header" (click)="onProtocolHeaderClick($event)">
      <span>Protokoll (Beta) ({{ protocolMessages.length }})</span>
      <mat-icon>{{ showProtocolMessages ? 'minimize' : 'open_in_full' }}</mat-icon>
    </div>
    <ul id="protocol-list" #protocolList *ngIf="showProtocolMessages">
      <li *ngFor="let pm of protocolMessages" [class.myself]="!pm.user">
        <div class="protocol-user-icon" *ngIf="pm.user"><img src="/assets/images/user-icon.webp"></div>
        <div class="protocol-message">
          <div class="protocol-message-user" *ngIf="pm.user">{{ pm.user.first_name }} {{ pm.user.last_name }}</div>
          <div class="protocol-message-text">{{ pm.message }}</div>
          <div class="protocol-message-time">{{ pm.time * 1000 | date: 'dd.MM.yyyy HH:mm' }}</div>
        </div>
      </li>
    </ul>
    <div id="protocol-input" *ngIf="showProtocolMessages">
      <input type="text" placeholder="Nachricht schreiben" [(ngModel)]="newMessage"
             (keydown)="onProtocolMessageKeyDown($event)" [disabled]="isSendingProtocolMessage">
      <mat-icon (click)="onSendProtocolMessageClick($event)">send</mat-icon>
    </div>
  </div>
</main>
