<main>
  <h1>Mitglieder</h1>

  <div class="loading" *ngIf="isLoading; else content">
    <mat-spinner color="accent" diameter="32"></mat-spinner>
  </div>

  <ng-template #content>
    <ul class="list">
      <li *ngFor="let member of members">
        <div class="profile"><img src="../assets/images/user-icon.webp"></div>
        <div class="text">
          <div class="title">{{member.first_name}} {{member.last_name}}</div>
          <div class="time">{{member.email}}</div>
        </div>
        <div class="availability" [ngClass]="member.is_available ? 'available' : 'unavailable'">
          <mat-icon>{{member.is_available ? 'check_mark' : 'close'}}</mat-icon>
        </div>
      </li>
    </ul>

    <div *ngIf="members.length <= 0">
      Keine Mitglieder vorhanden.
    </div>
  </ng-template>
</main>
