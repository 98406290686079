import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UserService} from '../../core/services/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(public router: Router, private userService: UserService) {
  }

  ngOnInit(): void {
    if (this.userService.isSignedIn()) {
      this.router.navigateByUrl('/home');
    }
  }

}
