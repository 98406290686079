import {Component, OnDestroy, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from "@angular/router";
import {ApiToken} from "../../../../core/models/ApiToken";
import {OrganizationService} from "../../../../core/services/organization.service";
import {Api} from "../../../../core/network/Api";
import {ToolbarService} from "../../../../core/services/toolbar.service";

@Component({
  selector: 'app-interface',
  templateUrl: './interface.component.html',
  styleUrls: ['./interface.component.css']
})
export class InterfaceComponent implements OnInit, OnDestroy {

  isLoading = false;
  isSaving = false;

  tokenTitle = '';

  tokenPreview: ApiToken | null = null;

  tokens: ApiToken[] = [];

  constructor(private httpClient: HttpClient, private organizationService: OrganizationService, private router: Router, private toolbarService: ToolbarService) {
  }

  ngOnInit(): void {
    this.toolbarService.setHierarchy([
      {title: "Anbindungen", path: "/home/bindings"}
    ]);
    this.toolbarService.setPageTitle("Schnittstelle");

    this.loadData();
  }

  ngOnDestroy() {
    this.toolbarService.resetHierarchy();
  }

  private loadData() {
    this.isLoading = true;
    Api.interfaceActive(this.httpClient, {organization_id: this.organizationService.selectedOrganizationId}, (response: any) => {
      this.tokens = response.items;
    }, (error: string) => {
      // todo: handle error
    }, () => {
      this.isLoading = false;
    });
  }

  addToken(): void {
    if (this.tokenTitle.trim() === '') {
      alert('Leerer Titel! Bitte überprüfe deine Eingaben und versuche es nochmal.');
      return;
    }

    this.isSaving = true;
    Api.interfaceCreate(this.httpClient, {
      organization_id: this.organizationService.selectedOrganizationId,
      title: this.tokenTitle
    }, (response: any) => {
      this.tokenPreview = response.token;
      this.tokenTitle = '';
      this.loadData();
    }, (error: any) => {
      console.log(error);
      switch (error) {
        case 'not-licensed':
          alert("Die Anbindung über eine Schnittstelle ist in deinem Plan nicht verfügbar. Ändere deinen Plan und \"Abrechnung\".")
          break;
        default:
          alert("Unbekannter Fehler. Bitte versuche es später nochmal.");
      }
    }, () => {
      this.isSaving = false;
    });
  }

  deleteToken(id: string): void {
    if (!confirm('Schlüssel wirklich löschen?')) {
      return;
    }

    Api.interfaceDelete(this.httpClient, {
      organization_id: this.organizationService.selectedOrganizationId,
      id: id
    }, () => {
      this.loadData();
    }, (error: any) => {
      // todo
    }, () => {
      this.isSaving = false;
    });
  }

  navigateTo(route: string) {
    this.router.navigateByUrl(route);
  }
}
