import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ApiService} from "../../core/services/api.service";
import {OrganizationService} from "../../core/services/organization.service";
import {ExternalICalendar} from "../../core/network/models/ExternalICalendar";
import {Api} from "../../core/network/Api";
import {HttpClient} from "@angular/common/http";
import {EventReminderScheduleType} from "../../core/network/models/EventReminderScheduleType";
import {EventReminderScheduleTypeResponse} from "../../core/network/response/EventReminderScheduleTypeResponse";

@Component({
  selector: 'app-external-icalendar-edit-dialog',
  templateUrl: './external-icalendar-edit-dialog.component.html',
  styleUrls: ['./external-icalendar-edit-dialog.component.scss']
})
export class ExternalIcalendarEditDialogComponent implements OnInit {
  isLoading = false;
  isSaving = false;

  externalICalendar: ExternalICalendar | null = null;

  eventReminderScheduleTypes: EventReminderScheduleType[] = [];

  constructor(public dialogRef: MatDialogRef<ExternalIcalendarEditDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ExternalIcalendarEditDialogData | null,
              private apiService: ApiService, private organizationService: OrganizationService, private httpClient: HttpClient) {
    this.externalICalendar = data?.item!!;
  }

  ngOnInit(): void {
    this.loadData();
  }

  onCloseClick(event: any): void {
    this.dialogRef.close();
  }

  onDeleteClick(event: any): void {
    if (!confirm('Kalender wirklich löschen?\nAlle synchronisierten Termine bleiben auch nach dem Löschvorgang erhalten.')) {
      return;
    }

    Api.bindingsExternalICalendarsDelete(this.httpClient, {
      organization_id: `${this.organizationService.selectedOrganizationId}`,
      id: this.externalICalendar!.id
    }, () => {
      this.dialogRef.close();
    }, (error: any) => {
      // todo
    }, () => {
    });
  }

  onSaveClick(event: any): void {
    this.isSaving = true;
    /*this.externalICalendar!.options = [];
    if (this.isOptionRawContentEnabled)
      this.externalICalendar?.options.push("raw_content");
    if (this.isOptionExtendedInformationEnabled)
      this.externalICalendar?.options.push("extended_information");*/

    let eventReminderScheduleTypeKeys = this.eventReminderScheduleTypes
      .filter(x => x.isChecked)
      .map(x => x.key);

    // todo: check inputs

    this.apiService.integrationExternalICalendarUpdate(`${this.organizationService.getSelectedOrganization()?.id ?? ''}`,
      this.externalICalendar?.id ?? '',
      this.externalICalendar?.name ?? '',
      this.externalICalendar?.send_notifications ?? false,
      this.externalICalendar?.sync_tagged_events_only ?? false,
      this.externalICalendar?.user_selection_type ?? null,
      eventReminderScheduleTypeKeys,
      () => {
        this.dialogRef.close();
      }, error => {
        // todo: show error
        alert("Fehler beim Speichern! Bitte versuche es später nochmal.");
      }, () => {
        this.isSaving = false;
      });
  }


  private loadData(): void {
    this.isLoading = true;

    Api.eventReminderScheduleType(this.httpClient, (response: EventReminderScheduleTypeResponse) => {
      this.eventReminderScheduleTypes = response.items.sort((a, b) => a.sort_priority > b.sort_priority ? 1 : -1);

      this.eventReminderScheduleTypes.forEach(x => {
        if ((this.externalICalendar?.event_reminder_schedule_type_keys?.indexOf(x.key) ?? -1) >= 0) {
          x.isChecked = true;
        }
      })
    }, (error: string) => {
      // todo: handle error
    }, () => {
      this.isLoading = false;
    });
  }

  protected readonly external = external;
}

export interface ExternalIcalendarEditDialogData {
  item: ExternalICalendar;
}

