import {ChangeDetectorRef, Component, HostListener, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {UserService} from '../../core/services/user.service';
import {OrganizationService} from '../../core/services/organization.service';
import {Api} from '../../core/network/Api';
import {ToolbarService} from "../../core/services/toolbar.service";
import {ToolbarButton} from "../../core/models/ToolbarButton";
import {HierarchyItem} from "../../core/models/HierarchyItem";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  public screenWidth: any;
  public drawerSideLimit = 860;
  public isDrawerOpen = true;

  isLoading = true;
  isSigningOut = false;

  mainMenu: any = [];

  firstName = '';
  organizationType = '';

  toolbarActions: ToolbarButton[] = [];
  hierarchy: HierarchyItem[] = [];
  pageTitle: string = "";

  constructor(private router: Router,
              private httpClient: HttpClient,
              private userService: UserService,
              public organizationService: OrganizationService, public toolbarService: ToolbarService, private cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.screenWidth = window.innerWidth;
    this.isDrawerOpen = this.screenWidth >= this.drawerSideLimit;

    this.toolbarService.getActions().subscribe((value): void => {
      this.toolbarActions = value;
      this.cdr.detectChanges();
    });

    this.toolbarService.getHierarchy().subscribe((value): void => {
      this.hierarchy = value;
      this.cdr.detectChanges();
    });

    this.toolbarService.getPageTitle().subscribe((value): void => {
      this.pageTitle = value;
      this.cdr.detectChanges();
    })

    this.loadUser();

    this.firstName = localStorage.getItem('first_name') ?? '';
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.screenWidth = window.innerWidth;
    this.isDrawerOpen = this.screenWidth >= this.drawerSideLimit;
  }

  onOrganizationChange(event: any): void {
    localStorage.setItem('organizationId', String(this.organizationService.selectedOrganizationId));
    window.location.reload();
  }

  onSignOutClick(event: any): void {
    this.signOut();
  }

  onMenuItemClick(event: any): void {
    if (this.screenWidth < this.drawerSideLimit) {
      this.isDrawerOpen = false;
    }
  }

  private loadUser(): void {
    this.isLoading = true;

    Api.user(this.httpClient, response => {
      if (!response.user.is_activated) {
        alert('Dein Account ist nicht aktiviert. Schließe diesen Schritt in der App ab.')
        this.signOut();
        return;
      }

      localStorage.setItem('email', response.user.email);
      localStorage.setItem('first_name', response.user.first_name);
      localStorage.setItem('last_name', response.user.last_name);

      this.firstName = response.user.first_name;

      if (response.user.organizations.length === 0) {
        localStorage.removeItem('organizationId');
        this.router.navigateByUrl('/home/get-started');
        return;
      }

      this.organizationService.organizations = response.user.organizations;

      if (this.organizationService.getSelectedOrganization() == null || this.organizationService.selectedOrganizationId <= 0) {
        this.organizationService.selectedOrganizationId = this.organizationService.organizations[0].id;
        localStorage.setItem('organizationId', String(this.organizationService.selectedOrganizationId));
      }

      this.loadMenu();

      this.updateOrganizationType();
    }, error => {
      console.log(error);
      if (error == "device_logged_out") {
        this.signOut(true);
      } else {
        alert('Fehler beim Aktualisieren! Bitte lade die Seite neu!');
      }
    }, () => {
      this.isLoading = false;
    });
  }

  private loadMenu(): void {
    this.mainMenu = [];

    let org = this.organizationService.getSelectedOrganization();

    this.mainMenu.push({link: 'operations', icon: 'wifi_tethering', text: 'Einsätze'});
    this.mainMenu.push({link: 'events', icon: 'event', text: 'Ereignisse'});
    if (org?.is_administrator) {
      this.mainMenu.push(
        {link: 'members', icon: 'groups', text: 'Mitglieder'},
        {link: 'monitors', icon: 'tv', text: 'Monitore'},
        {link: 'bindings', icon: 'link', text: 'Anbindungen'},
        // {link: 'wasserkarte', icon: 'water', text: 'Wasserkarte'},
      );
    }
    this.mainMenu.push({link: 'news', icon: 'inbox', text: 'Neuigkeiten'});
    if (org?.is_administrator) {
      this.mainMenu.push(
        // todo: {link: 'action-notifications', icon: 'notifications', text: 'Ereignis-Benachrichtigungen'},
        {link: 'organization/update', icon: 'edit', text: 'Daten aktualisieren'},
        {link: 'privacy', icon: 'lock', text: 'Datenschutz'},
        {link: 'billing', icon: 'payments', text: 'Abrechnung'}
      );
    }
  }

  private signOut(force: boolean = false): void {
    if (!force) {
      if (this.isSigningOut) {
        console.log('Already signing out');
        return;
      }

      this.isSigningOut = true;
      this.userService.signOut(error => {
        this.isSigningOut = false;
        console.log(error);
        alert('Fehler bei der Abmeldung! Bitte versuche es später nochmal.');
      });
    } else {
      console.log('Forced sign out');
      this.userService.clearUserStorage();
      this.router.navigateByUrl('/login').then((): void => {
        this.userService.clearUserCache();
      })
    }
  }

  private updateOrganizationType(): void {
    switch (this.organizationService.getSelectedOrganization()?.organization_type_id) {
      case 4:
        this.organizationType = "Unternehmen";
        break;
      case 7:
        this.organizationType = "Privatperson";
        break;
      default:
        this.organizationType = "";
    }
  }
}
