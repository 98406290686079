import {Component, OnDestroy, OnInit} from '@angular/core';
import {ToolbarService} from "../../../../../core/services/toolbar.service";
import {ApiService} from "../../../../../core/services/api.service";
import {OrganizationService} from "../../../../../core/services/organization.service";
import {AlertComponent} from "../../../../../dialogs/alert/alert.component";
import {MatDialog} from "@angular/material/dialog";
import {Router} from "@angular/router";

@Component({
  selector: 'app-add-member-by-email',
  templateUrl: './add-member-by-email.component.html',
  styleUrls: ['./add-member-by-email.component.scss']
})
export class AddMemberByEmailComponent implements OnInit, OnDestroy {

  isProcessing = false;
  firstName = "";
  lastName = "";
  email = "";
  hasAcceptedTerms = false;

  constructor(private toolbarService: ToolbarService, private apiService: ApiService,
              private organizationService: OrganizationService, private dialog: MatDialog, private router: Router) {
  }

  ngOnInit(): void {
    this.toolbarService.setHierarchy([
      {title: "Mitglieder", path: "/home/members"},
      {title: "Mitglied hinzufügen", path: "/home/members/add"}
    ]);
    this.toolbarService.setPageTitle("Mitglied registrieren");
  }

  ngOnDestroy(): void {
    this.toolbarService.resetHierarchy();
  }

  signUpMember(): void {
    if (this.firstName.trim() == "" || this.lastName.trim() == "" || this.email.trim() == "") {
      this.showAlert("Eingabe(n) ungültig", "Bitte überprüfe deine Eingaben und versuche es nochmal.");
      return;
    }

    const organizationId = this.organizationService.getSelectedOrganization()?.id ?? 0;

    this.isProcessing = true;

    this.apiService.memberAddSignUp({
      organization_id: organizationId,
      first_name: this.firstName,
      last_name: this.lastName,
      email: this.email
    }, () => {
      this.showAlert("Mitglied registriert", "Das Mitglied wurde erfolgreich registriert!", () => {
        this.router.navigateByUrl("/home/members/add");
      });
    }, error => {

      switch (error) {
        case "permission-denied":
          this.showAlert("Zugriff verweigert", "Du hast nicht ausreichend Berechtigungen diese Aktion auszuführen.");
          break;
        case "not-licensed":
          this.showAlert("Kontingent aufgebraucht", "Dein Kontingent an Mitgliedern ist ausgeschöpft. Erweitere deinen Plan unter \"Abrechnung\".");
          break;
        case "invalid-argument":
          this.showAlert("Fehler", "Bitte überprüfe deine Eingaben und versuche es nochmal.");
          break;
        case "already-exists":
          this.showAlert("Account existiert bereits", "Es ist bereits ein Account mit der selben E-Mail-Adresse registriert. Sende eine Einladung per E-Mail, um diesen Account zu deiner Organisation hinzuzufügen.");
          break;

        default:
          this.showAlert("Unbekannter Fehler", "Bitte versuche es später nochmal.");
      }
      this.isProcessing = false;
    }, () => {
    });
  }

  private showAlert(title: string, text: string, afterClosed: (() => any) | null = null) {
    this.dialog.open(AlertComponent, {
      data: {
        title: title,
        text: text
      },
      disableClose: true
    }).afterClosed().subscribe(() => {
      if (afterClosed) afterClosed();
    });
  }
}
