<main class="sp-dialog">
  <header>
    <div class="header-icon">
      <mat-icon>notifications</mat-icon>
    </div>
    <div class="header-title">Empfänger hinzufügen</div>
    <div class="header-actions">
      <mat-icon matRipple (click)="onCloseClick($event)">close</mat-icon>
    </div>
  </header>
  <div class="content">
    <mat-form-field appearance="fill" color="accent">
      <mat-label>Bemerkung</mat-label>
      <input matInput type="text" placeholder="" [(ngModel)]="actionNotificationRecipient!.notice">
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Senden als</mat-label>
      <mat-select [(ngModel)]="actionNotificationRecipient!.transmission_type">
        <mat-option [value]="'email'">E-Mail</mat-option>
        <mat-option [value]="'sms'">SMS</mat-option>
      </mat-select>
      <mat-hint *ngIf="actionNotificationRecipient!.transmission_type === 'sms'">Erfordert die Aktivierung von SMS unter
        "Anbindungen"!
      </mat-hint>
    </mat-form-field>

    <mat-form-field appearance="fill" color="accent">
      <mat-label>Adresse</mat-label>
      <input matInput type="text" placeholder="" [(ngModel)]="actionNotificationRecipient!.address">
      <mat-hint *ngIf="actionNotificationRecipient!.transmission_type === 'email'">z.B. adresse@email.com</mat-hint>
      <mat-hint *ngIf="actionNotificationRecipient!.transmission_type === 'sms'">z.B. +4366412345678</mat-hint>
    </mat-form-field>

    <mat-checkbox *ngIf="actionNotificationRecipient!.transmission_type === 'email'"
                  [(ngModel)]="isOptionRawContentEnabled">Als Klartext (ohne Formatierung)
    </mat-checkbox>
    <mat-checkbox *ngIf="actionNotificationRecipient!.transmission_type === 'email'"
                  [(ngModel)]="isOptionExtendedInformationEnabled">Erweiterte Informationen (kann ggf.
      sensible
      Daten, wie Name, Adresse, Telefonnummer usw. enthalten)
    </mat-checkbox>
  </div>
  <footer>
    <button class="btn-action btn-primary" (click)="onSaveClick($event)">Speichern</button>
  </footer>
</main>
