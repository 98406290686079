<main>
  <h1>SMS</h1>

  <div>
    Aktiviert die Möglichkeit SMS von SmartPager aus zu senden.
  </div>

  <div class="loading" *ngIf="isLoading; else content">
    <mat-spinner color="accent" diameter="32"></mat-spinner>
  </div>

  <ng-template #content>
    <div id="content">
      <div *ngIf="info != null">

        <!-- todo: taxes -->

        <div id="price">{{pricing?.price_per_unit}}/{{pricing?.unit}}</div>

        <div id="invoice-preview">
          <mat-spinner color="accent" diameter="32" *ngIf="isLoadingInvoicePreview; else invoicePreview"></mat-spinner>
          <ng-template #invoicePreview>
            <div id="subtotal">
              <div>Preis pro SMS</div>
              <div>{{pricingPreview?.subtotal}}</div>
            </div>

            <div class="discount" *ngFor="let d of pricingPreview?.discounts">
              <div>{{d.name}}</div>
              <div>{{d.amount}}</div>
            </div>

            <div id="taxes-info">
              <div *ngIf="pricingPreview?.is_reverse_charge; else taxesInfo">
                Steuer zahlbar auf Reverse-Charge-Basis
              </div>

              <ng-template #taxesInfo>
                <div>{{pricingPreview?.tax_rate_percentage}} {{pricingPreview?.tax_rate_name}}</div>
                <div>{{pricingPreview?.tax_rate_amount}}</div>
              </ng-template>
            </div>

            <div id="total">
              <div>Gesamt</div>
              <div>{{pricingPreview?.total}}</div>
            </div>
          </ng-template>
        </div>

        <div class="secondary">
          Die tatsächlichen Kosten werden <b>je Abrechnungszeitraum</b> aufgrund der Summe der gesendeten SMS im
          Nachhinein berechnet und zusätzlich auf der Rechnung angeführt.<br>
          Nachrichten mit mehr als 160 Zeichen werden technisch bedingt als mehrere SMS je Empfänger berechnet.
        </div>
        <br>
        <div id="actions">
          <button class="btn-action btn-primary" *ngIf="!info.is_enabled" (click)="onEnableClick($event)"
                  [disabled]="isProcessing">
            Kostenpflichtig aktivieren
          </button>
          <button class="btn-action btn-secondary" *ngIf="info.is_enabled" (click)="onDisableClick($event)"
                  [disabled]="isProcessing">Deaktivieren
          </button>
        </div>
      </div>
      <br><br>

      <h2>VERLAUF</h2>
      <ng-container *ngIf="smsJobHistory.length <= 0; else jobHistory">Keine Einträge gefunden</ng-container>

      <ng-template #jobHistory>
        <div id="job-history">
          <table>
            <thead>
            <tr>
              <th>Zeit</th>
              <th>Typ</th>
              <th>Empfänger</th>
              <th>SMS</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let j of smsJobHistory">
              <td>{{j.time_queued * 1000 | date:'dd.MM.yyyy HH:mm:ss'}}</td>
              <td>{{j.type}}</td>
              <td>{{j.recipient_count}}</td>
              <td>{{j.sms_count}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </ng-template>
    </div>
  </ng-template>
</main>
