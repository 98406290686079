import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css']
})
export class AlertComponent implements OnInit {
  title = '';
  text = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: AlertDialogData) {
    this.title = data.title;
    this.text = data.text;
  }

  ngOnInit(): void {
  }
}

export interface AlertDialogData {
  title: string;
  text: string;
}
