import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {ActionNotificationRecipient} from "../../core/models/ActionNotificationRecipient";
import {ApiService} from "../../core/services/api.service";
import {OrganizationService} from "../../core/services/organization.service";

@Component({
  selector: 'app-action-notification-recipient-list-item',
  templateUrl: './action-notification-recipient-list-item.component.html',
  styleUrls: ['./action-notification-recipient-list-item.component.scss']
})
export class ActionNotificationRecipientListItemComponent implements OnInit {

  @Input("recipient") recipient: ActionNotificationRecipient | null = null;

  @Output("deleted") deleted: EventEmitter<any> = new EventEmitter<any>();

  options: string[] = [];

  constructor(public dialog: MatDialog, private apiService: ApiService, private organizationService: OrganizationService) {
  }

  ngOnInit(): void {
    // todo: this.onClick(null);

    if ((this.recipient?.options?.indexOf("raw_content") ?? -1) >= 0)
      this.options.push("Ohne Formatierung");

    if ((this.recipient?.options?.indexOf("extended_information") ?? -1) >= 0)
      this.options.push("Erweiterte Informationen");
  }

  /*onClick(event: any): void {
    this.dialog.open(ActionNotificationRecipientEditDialogComponent, {
      maxWidth: 500,
      data: {
        key: "operation.create", // todo: load dynamically
        recipient: this.recipient
      } as ActionNotificationRecipientEditDialogData,
      panelClass: 'sp-dialog-panel',
      disableClose: true
    }).afterClosed().subscribe(() => {
      // todo: if (afterClosed) afterClosed();
    });
  }*/

  onDeleteClick(event: any) {
    if (!confirm("Diesen Empfänger wirklich löschen?"))
      return;

    let organizationId = `${this.organizationService.getSelectedOrganization()?.id ?? ''}`;
    let key = "operation.create"; // todo: load dynamically
    let id = this.recipient?.id ?? '';

    this.apiService.actionNotificationDelete(organizationId, key, id, () => {
      this.deleted.emit();
    }, error => {
      // todo: show error
    }, () => {

    })
  }
}
