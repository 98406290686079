import {Component, OnDestroy, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {OrganizationService} from '../../../../core/services/organization.service';
import {Api} from '../../../../core/network/Api';
import {BindingsLwzOoeInfo} from '../../../../core/models/BindingsLwzOoeInfo';
import {Router} from '@angular/router';
import {ToolbarService} from "../../../../core/services/toolbar.service";

@Component({
  selector: 'app-binding-lwzooe',
  templateUrl: './binding-lwzooe.component.html',
  styleUrls: ['./binding-lwzooe.component.css']
})
export class BindingLwzooeComponent implements OnInit, OnDestroy {

  isLoadingIntegration = false;
  isLoadingAddress = false;
  isProcessing = false;

  info: BindingsLwzOoeInfo | null = null;

  addressName = '';
  addressStreet = '';
  addressPostcode = '';
  addressCity = '';

  disableStationIdInput = false;
  stationId: string = "";
  sirenCode: string = "";
  acceptTerms = false;

  constructor(private httpClient: HttpClient, private organizationService: OrganizationService, private router: Router, private toolbarService: ToolbarService) {
  }

  ngOnInit(): void {
    this.toolbarService.setHierarchy([{title: "Anbindungen", path: "/home/bindings"}]);
    this.toolbarService.setPageTitle("Landes-Warnzentrale Oberösterreich");

    this.loadData();
  }

  ngOnDestroy(): void {
    this.toolbarService.resetHierarchy();
  }

  private loadData(): void {
    this.isLoadingIntegration = true;
    this.isLoadingAddress = true;

    this.info = null;

    Api.organizationInformation(this.httpClient, {organization_id: `${this.organizationService.selectedOrganizationId}`}, response => {
      this.addressName = response.address.name;
      this.addressStreet = response.address.street;
      this.addressPostcode = response.address.postcode;
      this.addressCity = response.address.city;
    }, (error: string) => {
      // todo: handle error
      this.isLoadingAddress = false;
    }, () => {
      this.isLoadingAddress = false;
    });

    Api.bindingsLwzOoeInfo(this.httpClient, {organization_id: this.organizationService.selectedOrganizationId}, response => {
      this.info = response.info;

      if (this.info.station_id != null) {
        this.stationId = this.info.station_id ?? "";
        this.disableStationIdInput = true;
      }
    }, (error: string) => {
      // todo: handle error
      this.isLoadingIntegration = false;
    }, () => {
      this.isLoadingIntegration = false;
    });
  }

  enableBinding(type: string, sirenCode: string | null = null, stationId: string | null = null): void {
    let parameter = {};
    switch (type) {
      case "public":
        parameter = {
          organization_id: this.organizationService.selectedOrganizationId,
          interface_type: type
        }
        break;
      case "els":
        parameter = {
          organization_id: this.organizationService.selectedOrganizationId,
          interface_type: type,
          siren_code: sirenCode,
          station_id: stationId
        }
        break;
      default:
        // todo: log
        return;
    }

    this.isProcessing = true;

    Api.bindingsLwzOoeEnable(this.httpClient, parameter, () => {
      this.loadData();
      this.isProcessing = false;
    }, error => {
      switch (error) {
        case "invalid-argument":
          alert("Ungültige Eingaben gefunden. Bitte überprüfe deine Eingaben und versuche es nochmal.")
          break;
        default:
          alert("Interner Fehler. Bitte versuche es später nochmal.");
      }
      // todo
      this.isProcessing = false;
    }, () => {
      // todo
    });
  }

  disableBinding(type: string): void {
    this.isProcessing = true;
    Api.bindingsLwzOoeDisable(this.httpClient, {
      organization_id: this.organizationService.selectedOrganizationId,
      interface_type: type
    }, () => {
      this.loadData();
      this.isProcessing = false;
    }, error => {
      // todo
      this.isProcessing = false;
    }, () => {
      // todo
    });
  }

  onRequestAccessAdvancedClick(event: any): void {
    let stationId = this.stationId.trim();
    let sirenCode = this.sirenCode.trim();

    if (stationId == '' || sirenCode == '') {
      alert('Ungültige Eingaben! Bitte überprüfe deine Eingaben und versuche es nochmal.');
      return;
    }

    this.enableBinding('els', sirenCode, stationId);
  }
}
