import {Injectable} from '@angular/core';
import {UserOrganization} from '../models/UserOrganization';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {

  public organizations: UserOrganization[] = [];

  public selectedOrganizationId = 0;

  constructor() {
    if (this.selectedOrganizationId <= 0 && localStorage.getItem('organizationId') != null) {
      this.selectedOrganizationId = Number(localStorage.getItem('organizationId'));
    }
  }

  public getSelectedOrganization(): UserOrganization | null {
    const orgs = this.organizations.filter(x => x.id == this.selectedOrganizationId);
    if (orgs.length <= 0) return null;
    return orgs[0];
  }
}
