import {Component, OnDestroy, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {OrganizationService} from "../../../../core/services/organization.service";
import {Router} from "@angular/router";
import {Api} from "../../../../core/network/Api";
import {BindingsConnectInfo} from "../../../../core/models/BindingsConnectInfo";
import {Clipboard} from "@angular/cdk/clipboard";
import {ApiService} from "../../../../core/services/api.service";
import {AlertComponent} from "../../../../dialogs/alert/alert.component";
import {MatDialog} from "@angular/material/dialog";
import {ToolbarService} from "../../../../core/services/toolbar.service";
import {OperationCategory} from "../../../../core/network/models/OperationCategory";
import {
  BindingsConnectInfoSettingsUpdateParams
} from "../../../../core/network/request/BindingsConnectInfoSettingsUpdateParams";

@Component({
  selector: 'app-binding-connect',
  templateUrl: './binding-connect.component.html',
  styleUrls: ['./binding-connect.component.scss']
})
export class BindingConnectComponent implements OnInit, OnDestroy {
  isLoading = false;
  isLoadingOperationCategories = false;


  info: BindingsConnectInfo | null = null;

  token: string = '';

  isUpdatingSettings = false;

  watcherOfflineNotificationDelay = 30;

  alarmSheetPrintCount = 1;
  alarmSheetOperationCategoryExceptions: string[] = [];
  alarmSheetWaterSourcesPreviewCount = 3;

  waterSourcesCount = 5;
  waterSourcesPrintCount = 1;
  waterSourcesOperationCategoryExceptions: string[] = [];

  operationCategories: OperationCategory[] = [];

  constructor(private httpClient: HttpClient, private organizationService: OrganizationService, private router: Router, private clipboard: Clipboard, private apiService: ApiService, public dialog: MatDialog, private toolbarService: ToolbarService) {
  }

  ngOnInit(): void {
    this.toolbarService.setHierarchy([
      {title: "Anbindungen", path: "/home/bindings"}
    ]);
    this.toolbarService.setPageTitle("Connect (Beta)");

    this.loadData();
  }

  ngOnDestroy(): void {
    this.toolbarService.resetHierarchy();
  }

  private loadData() {
    this.isLoading = true;
    Api.bindingsConnectInfo(this.httpClient, {organization_id: this.organizationService.selectedOrganizationId}, response => {
      this.info = response.info;
      this.alarmSheetPrintCount = response.info.settings.alarm_sheet_print_count;
      this.alarmSheetOperationCategoryExceptions = response.info.settings.alarm_sheet_operation_category_id_exceptions;
      this.alarmSheetWaterSourcesPreviewCount = response.info.settings.alarm_sheet_water_sources_preview_count;
      this.waterSourcesPrintCount = response.info.settings.water_sources_print_count;
      this.waterSourcesCount = response.info.settings.water_sources_count;
      this.waterSourcesOperationCategoryExceptions = response.info.settings.water_sources_operation_category_id_exceptions;
      this.watcherOfflineNotificationDelay = response.info.settings.watcher_offline_notification_delay;
    }, (error: string) => {
      // todo: handle error
      this.isLoading = false;
    }, () => {
      this.isLoading = false;
    });

    this.loadOperationCategories();
  }

  loadOperationCategories(): void {
    this.isLoadingOperationCategories = true;

    Api.organizationOperationCategories(this.httpClient, `${this.organizationService.selectedOrganizationId}`, response => {
      this.operationCategories = response.operation_categories.sort((a, b) => a.name > b.name ? 1 : -1);
    }, error => {
      console.log(error);
      switch (error) {
        default:
          alert('Unbekannter Fehler beim Laden! Bitte versuche es später nochmal.');
      }
    }, () => {
      this.isLoadingOperationCategories = false;
    });
  }

  updateSettings(): void {
    this.isUpdatingSettings = true;

    let parameter = {
      organization_id: this.organizationService.selectedOrganizationId,
      watcher_offline_notification_delay: this.watcherOfflineNotificationDelay,
      alarm_sheet_print_count: this.alarmSheetPrintCount,
      alarm_sheet_operation_category_id_exceptions: this.alarmSheetOperationCategoryExceptions,
      alarm_sheet_water_sources_preview_count: this.alarmSheetWaterSourcesPreviewCount,
      water_sources_print_count: this.waterSourcesPrintCount,
      water_sources_count: this.waterSourcesCount,
      water_sources_operation_category_id_exceptions: this.waterSourcesOperationCategoryExceptions
    } as BindingsConnectInfoSettingsUpdateParams;

    Api.bindingsConnectInfoSettingsUpdate(this.httpClient, parameter, () => {
      this.loadData();
    }, error => {
      alert('Fehler beim Speichern! Bitte versuche es später nochmal.');
    }, () => {
      this.isUpdatingSettings = false;
    });
  }

  printTestPage(): void {
    this.apiService.bindingConnectPrintTestPage({organization_id: `${this.organizationService.selectedOrganizationId}`}, () => {
      this.dialog.open(AlertComponent, {
        data: {
          title: "Testseite gesendet",
          text: "Deine Testseite wird in Kürze am Drucker ausgedruckt."
        },
        disableClose: true
      });
    }, error => {
      this.dialog.open(AlertComponent, {
        data: {
          title: "Fehler beim Vorbereiten",
          text: "Wegen eines Fehlers konnte keine Testseite erstellt werden. Bitte versuche es später nochmal."
        },
        disableClose: true
      });
    }, () => {

    })
  }

  navigationToWebsite(): void {
    window.open("https://www.smartpager.at/connect", "_blank");
  }
}
