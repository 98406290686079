<div id="sign-out-message" *ngIf="hasSignedOut" routerLink="/">Dein Account wurde erfolgreich abgemeldet.</div>
<mat-card>
  <header>
    <img src="/assets/images/logo.webp">
  </header>
  <main>
    <h1>Datenlöschung beantragen</h1>
    <p>Beantrage hier die Löschung deines SmartPager-Kontos oder einzelner Daten.</p>

    <mat-form-field appearance="fill">
      <mat-label>E-Mail</mat-label>
      <input matInput [formControl]="email" type="email" required [disabled]="isLoading">
    </mat-form-field>

    <b>Art der Löschung:*</b>
    <mat-radio-group
      [(ngModel)]="deletionType" [disabled]="isLoading">
      <mat-radio-button [value]="'data'">Einzelne Daten</mat-radio-button>
      <mat-radio-button [value]="'account'">Konto</mat-radio-button>
    </mat-radio-group>

    <mat-form-field appearance="fill">
      <mat-label>Bemerkung</mat-label>
      <textarea matInput [(ngModel)]="notes" [required]="deletionType == 'data'" [disabled]="isLoading"></textarea>
    </mat-form-field>

    <button mat-flat-button color="primary" (click)="requestDeletion()"
            [disabled]="isLoading || deletionType == '' || email.value == '' || (deletionType == 'data' && notes == '') ">
      LÖSCHUNG BEANTRAGEN
    </button>
  </main>
</mat-card>
<footer>
  &copy; <a href="https://www.smartpager.at" target="_blank">Elias Lexl</a> |
  <a href="https://www.smartpager.at/privacy" target="_blank">Datenschutz</a> |
  <a href="https://www.smartpager.at/terms" target="_blank">AGB</a> |
  <a href="https://www.smartpager.at/impressum" target="_blank">Impressum</a>
</footer>
