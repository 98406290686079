<main>
  <h1>Nutzung</h1>

  <div id="content">
    <div class="loading" *ngIf="isLoadingPlan || isLoadingUsage; else content">
      <mat-spinner color="accent" diameter="32"></mat-spinner>
    </div>

    <ng-template #content>
      <h2>AKTUELLER PLAN</h2>
      <div id="plan">
        <div id="plan-title">{{plan?.title ?? ''}}</div>
      </div>

      <div id="usage-info">Die Nutzung zeigt den aktuellen {{usagePeriod}}.</div>

      <ul id="usage">
        <li *ngFor="let usage of planUsage">
          <div class="usage-infos">
            <div class="usage-title">{{usage.title}}</div>
            <div class="usage-details">{{usage.now}} <span>/ {{usage.max == -1 ? "unlimitiert" : usage.max}}</span>
            </div>
          </div>
          <mat-progress-bar [value]="usage.percentage * 100" class="usage-bar" color="accent"></mat-progress-bar>
        </li>
      </ul>
    </ng-template>
  </div>
</main>
