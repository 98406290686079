<main>
  <h1>Abrechnung</h1>

  <div id="license">
    <h2>PLAN</h2>

    <div class="loading" *ngIf="isLoadingLicense; else licensesContent">
      <mat-spinner color="accent" diameter="32"></mat-spinner>
    </div>

    <ng-template #licensesContent>
      <div *ngIf="license != null; else loadLicenseError">
        <div id="license-title">{{ license.title }}</div>
        <div id="license-description" *ngIf="license.type == 'free' && license.count_used >= 0">
          {{ license.count_used }} von {{ license.count_total }} Plätzen belegt
        </div>
        <div id="license-date" [ngClass]="license.type == 'trial' || license.is_cancelled ? 'attention' : ''"
             [ngSwitch]="license!.type">
          <div *ngSwitchCase="'beta'">Dein Plan ist
            seit {{ license!.time_start * 1000 | date: 'dd. MMMM yyyy' }} aktiv.
          </div>
          <div *ngSwitchCase="'trial'">Dein Testzeitraum endet
            am {{ license!.time_end * 1000 | date: 'dd. MMMM yyyy' }}
            .
          </div>
          <div *ngSwitchCase="'free'">Dein Plan ist
            seit {{ license!.time_start * 1000 | date: 'dd. MMMM yyyy' }} aktiv.
          </div>
          <div *ngSwitchCase="'paid'">
            <span *ngIf="license!.status == 'processing'">Deine Bestellung wird verarbeitet.</span>
            <span *ngIf="license!.status == 'past_due' || license!.status == 'requires_payment_method'"
                  style="color: #ff0000; font-weight: 500;">Zahlung fehlgeschlagen.</span>
            <!--<span *ngIf="!license.is_cancelled && license.time_end == 0">Deine Bestellung wird verarbeitet.</span>-->
            <span
              *ngIf="license!.status == 'active' && !license!.is_cancelled && license!.time_end != 0 && license?.schedule?.time_scheduled == null">
              Dein Plan wird am {{ license!.time_end * 1000 | date: 'dd. MMMM yyyy' }} verlängert.
            </span>
            <span
              *ngIf="license!.status == 'active' && !license!.is_cancelled && license!.time_end != 0 && license?.schedule?.time_scheduled != null">
              Dein Plan wird am {{ license!.schedule!.time_scheduled * 1000 | date: 'dd. MMMM yyyy' }} geändert.
            </span>
            <span *ngIf="license!.status == 'active' && license!.is_cancelled">
              Dein Plan wird am {{ license!.time_end * 1000 | date: 'dd. MMMM yyyy' }} gekündigt.</span>
          </div>
          <div *ngSwitchCase="'enterprise'">
            <span>Leistungsumfang lt. Bestellung</span>
          </div>
        </div>
        <div class="plan-actions" [ngSwitch]="license!.type">
          <div *ngSwitchCase="'beta'">
            <button class="btn-action btn-primary" (click)="openUsage()">Nutzung</button>
          </div>
          <div *ngSwitchCase="'trial'">
            <button class="btn-action btn-primary" (click)="openCheckout()" *ngIf="isPaidLicenseAvailable">Plan
              upgraden
            </button>
            <button class="btn-action btn-primary" (click)="openBusinessConfirmation()" *ngIf="!isPaidLicenseAvailable">
              Plan
              upgraden
            </button>
            <button class="btn-action btn-secondary" (click)="openUsage()">Nutzung</button>
          </div>
          <div *ngSwitchCase="'free'">
            <button class="btn-action btn-primary" (click)="openCheckout()" *ngIf="isPaidLicenseAvailable">Plan
              upgraden
            </button>
            <button class="btn-action btn-primary" (click)="openBusinessConfirmation()" *ngIf="!isPaidLicenseAvailable">
              Plan upgraden
            </button>
            <button class="btn-action btn-secondary" (click)="openUsage()">Nutzung</button>
          </div>
          <div *ngSwitchCase="'paid'">
            <button class="btn-action btn-primary"
                    *ngIf="license!.status != 'past_due' && license!.status != 'requires_payment_method' && !license!.is_cancelled && license!.time_end > 0"
                    (click)="openUsage()">Nutzung
            </button>
            <button class="btn-action btn-primary"
                    *ngIf="(license!.status == 'past_due' || license!.status == 'requires_payment_method') && !license!.is_cancelled && license!.time_end > 0"
                    (click)="openPaymentPage()">Rechnung zahlen
            </button>
            <button class="btn-action btn-secondary" *ngIf="!license!.is_cancelled && license!.time_end > 0"
                    (click)="changeLicense()">Plan ändern
            </button>
            <button class="btn-action btn-primary" *ngIf="license!.is_cancelled" (click)="renewLicense()">Plan
              verlängern
            </button>
            <button class="btn-action btn-secondary" *ngIf="license!.is_cancelled" (click)="openUsage()">Nutzung
            </button>
          </div>
          <div *ngSwitchCase="'enterprise'">
            <button class="btn-action btn-primary" (click)="openUsage()">Nutzung</button>
          </div>
        </div>
      </div>

      <ng-template #loadLicenseError>
        <div>
          Fehler beim Laden! Bitte lade die Seite neu und kontaktiere unseren Support, sollte das Problem weiterhin
          bestehen.
        </div>
      </ng-template>
    </ng-template>
  </div>

  <div id="dynamic-member-usage" *ngIf="license != null && license!.type == 'paid'">
    <h2>DYNAMISCHE MITGLIEDERNUTZUNG</h2>

    <div class="loading" *ngIf="isLoadingDynamicMemberUsage; else dynamicMemberUsageContent">
      <mat-spinner color="accent" diameter="32"></mat-spinner>
    </div>

    <ng-template #dynamicMemberUsageContent>
      <dynamic-member-usage [memberUsage]="memberUsage"></dynamic-member-usage>
    </ng-template>
  </div>

  <div id="upcoming-invoice" *ngIf="license != null && license!.type == 'paid'">
    <h2>NÄCHSTE RECHNUNG</h2>

    <div class="loading" *ngIf="isLoadingUpcomingInvoice; else upcomingInvoiceContent">
      <mat-spinner color="accent" diameter="32"></mat-spinner>
    </div>

    <ng-template #upcomingInvoiceContent>
      <upcoming-invoice [upcomingInvoice]="upcomingInvoice"></upcoming-invoice>
    </ng-template>
  </div>

  <div id="invoice-recipient" *ngIf="!isLoadingLicense && isPaidLicenseAvailable">
    <h2>ZAHLUNGSMETHODE</h2>

    <div class="loading" *ngIf="isLoadingPaymentMethod; else invoiceAddressContent">
      <mat-spinner color="accent" diameter="32"></mat-spinner>
    </div>

    <ng-template #invoiceAddressContent>
      <payment-method></payment-method>
    </ng-template>
  </div>

  <div id="billing-history" *ngIf="!isLoadingLicense && isPaidLicenseAvailable">
    <h2>RECHNUNGEN</h2>

    <div class="loading" *ngIf="isLoadingInvoices; else billingHistoryContent">
      <mat-spinner color="accent" diameter="32"></mat-spinner>
    </div>

    <ng-template #billingHistoryContent>
      <billing-history [invoices]="invoices"></billing-history>
    </ng-template>
  </div>
</main>
