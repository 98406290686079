import {Component, OnDestroy, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {OrganizationService} from "../../../../core/services/organization.service";
import {Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {Api} from "../../../../core/network/Api";
import {AlertComponent} from "../../../../dialogs/alert/alert.component";
import {BindingsMapTripInfo} from "../../../../core/models/BindingsMapTripInfo";
import {ToolbarService} from "../../../../core/services/toolbar.service";

@Component({
  selector: 'app-binding-map-trip',
  templateUrl: './binding-map-trip.component.html',
  styleUrls: ['./binding-map-trip.component.scss']
})
export class BindingMapTripComponent implements OnInit, OnDestroy {
  isLoading = false;
  isProcessing = false;

  info: BindingsMapTripInfo | null = null;

  apiKey: string = "";

  constructor(private httpClient: HttpClient, private organizationService: OrganizationService, private router: Router,
              public dialog: MatDialog, private toolbarService: ToolbarService) {
  }

  ngOnInit(): void {
    this.toolbarService.setHierarchy([
      {title: "Anbindungen", path: "/home/bindings"}
    ]);
    this.toolbarService.setPageTitle("MapTrip");

    this.loadData();
  }

  ngOnDestroy() {
    this.toolbarService.resetHierarchy();
  }

  private loadData() {
    this.isLoading = true;
    Api.bindingsMapTripInfo(this.httpClient, {organization_id: this.organizationService.selectedOrganizationId}, response => {
      this.info = response.info;
    }, (error: string) => {
      // todo: handle error
      this.isLoading = false;
    }, () => {
      this.isLoading = false;
    });
  }

  enableBinding() {
    let token = this.apiKey.trim();

    if (token == '') {
      this.showAlert('Ungültige Eingabe', 'Bitte überprüfe deine Eingaben und versuche es nochmal.');
      return;
    }

    this.isProcessing = true;

    Api.bindingsMapTripEnable(this.httpClient, {
      organization_id: this.organizationService.selectedOrganizationId,
      api_key: this.apiKey
    }, () => {
      this.apiKey = "";

      this.loadData();
      this.isProcessing = false;
    }, error => {
      console.log(error);
      switch (error) {
        // todo
        case 'invalid-credentials':
          this.showAlert('Zugriffsschlüssel ungültig', 'Der Zugriffsschlüssel ist ungültig. Bitte überprüfe deine Eingabe und versuche es nochmal.');
          break;
        case 'internal':
          this.showAlert('Interner Fehler', 'Aufgrund eines internen Fehlers konnte die Anbindung nicht aktiviert werden. Bitte versuche es später nochmal.');
          break;
        default:
          this.showAlert('Unbekannter Fehler', 'Aufgrund eines ungekannten Fehlers konnte die Anbindung nicht aktiviert werden. Bitte kontaktiere unseren Support für weitere Informationen.');
      }
      this.isProcessing = false;
    }, () => {
      // todo
    });
  }

  disableBinding() {
    this.isProcessing = true;

    Api.bindingsMapTripDisable(this.httpClient, {organization_id: this.organizationService.selectedOrganizationId}, () => {
      this.loadData();
      this.isProcessing = false;
    }, error => {
      // todo
      this.isProcessing = false;
    }, () => {
      // todo
    });
  }

  navigateTo(route: string) {
    this.router.navigateByUrl(route);
  }

  private showAlert(title: string, text: string, afterClosed: (() => any) | null = null) {
    this.dialog.open(AlertComponent, {
      data: {
        title: title,
        text: text
      },
      disableClose: true
    }).afterClosed().subscribe(() => {
      if (afterClosed) afterClosed();
    });
  }

}
