<div id="sign-out-message" *ngIf="hasSignedOut" routerLink="/">Dein Account wurde erfolgreich abgemeldet.</div>
<mat-card>
  <header>
    <img src="/assets/images/logo.webp">
  </header>
  <main *ngIf="!isSigningIn">
    <p>Mit dem SmartPager-Konto anmelden.</p>

    <mat-form-field appearance="fill">
      <mat-label>E-Mail</mat-label>
      <input matInput [formControl]="email" type="email" required>
    </mat-form-field>
    <br>
    <mat-form-field appearance="fill">
      <mat-label>Passwort</mat-label>
      <input matInput [type]="hide ? 'password' : 'text'" [(ngModel)]="passwordVal" (keydown.enter)="signIn()" required>
      <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Passwort ausblenden'"
              [attr.aria-pressed]="hide">
        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
      </button>
    </mat-form-field>

    <button mat-flat-button color="primary" (click)="signIn()">ANMELDEN</button>
    <button mat-stroked-button color="accent" routerLink="sign-up">REGISTRIEREN</button>
  </main>
  <main *ngIf="isSigningIn" style="text-align: center;">
    Anmeldung läuft ...
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </main>
</mat-card>
<footer>
  &copy; <a href="https://www.smartpager.at" target="_blank">Elias Lexl</a> |
  <a href="https://www.smartpager.at/privacy" target="_blank">Datenschutz</a> |
  <a href="https://www.smartpager.at/terms" target="_blank">AGB</a> |
  <a href="https://www.smartpager.at/impressum" target="_blank">Impressum</a>
</footer>
