<main>
  <img src="assets/images/client.webp" id="img-header">
  <h1>"{{organizationName}}" ist ein Unternehmen?</h1>
  <p>Die Bestellung eines kostenpflichtigen Plans steht Privatpersonen zum jetzigen Zeitpunkt nicht zur Verfügung.
    Privatpersonen können SmartPager eingeschränkt kostenlos nutzen.</p>

  <div id="actions-title">Ich bin ein(e)</div>
  <div id="actions">
    <button class="btn-action btn-secondary" [disabled]="isSaving" (click)="save(true)">
      Unternehmen
    </button>
    <button class="btn-action btn-primary" [disabled]="isSaving" (click)="save(false)">
      Privatperson
    </button>
  </div>
  <div id="actions-footer">Wähle deine entsprechende Organisationsform.</div>
</main>
