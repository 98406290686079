import {Component, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../../core/services/user.service';
import {Api} from '../../../core/network/Api';
import {OrganizationService} from '../../../core/services/organization.service';
import {AlertComponent} from "../../../dialogs/alert/alert.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})
export class SignInComponent implements OnInit {

  isSigningIn = false;
  hasSignedOut = false;

  email = new FormControl('', [Validators.required, Validators.email]);
  passwordVal = '';
  hide = true;

  constructor(private httpClient: HttpClient, private router: Router, private userService: UserService, private organizationService: OrganizationService, private route: ActivatedRoute, public dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.hasSignedOut = params['signout'] || false;
    });

    this.email.setValue(localStorage.getItem('email') ?? '');
  }

  getErrorMessage(): string {
    if (this.email.hasError('required')) {
      return 'Die E-Mail-Adresse ist ein Pflichtfeld.';
    }

    return this.email.hasError('email') ? 'E-Mail-Adresse ist ungültig' : '';
  }

  signIn(): void {
    if (this.email.value === '' || this.passwordVal === '') {
      alert('Überprüfe deine Eingaben und versuche es nochmal.')
      return;
    }

    this.isSigningIn = true;

    Api.signIn(this.httpClient, {email: this.email.value, password: this.passwordVal}, data => {
      this.userService.jwt = data.jwt;
      localStorage.setItem('jwt', data.jwt);

      this.router.navigateByUrl('/home');
    }, error => {
      localStorage.clear();

      console.log(error);

      switch (error) {
        case 'unauthorized':
        case 'user-not-existing':
        case 'user-password-wrong':
          this.showAlert("Anmeldedaten ungültig", "Bitte überprüfe deine Eingaben und versuche es nochmal.");
          break;
        case 'no-organizations':
          this.showAlert("Keine Organisationen gefunden", "Trete in der App einer bestehenden Organisation bei, oder registriere eine Neue.");
          break;
        case 'activation-pending':
          this.showAlert("Aktivierung ausständig", "Dein Account wurde noch nicht aktiviert. Öffne die App und tippe den 6-stelligen Zahlencode ein, den du per E-Mail erhalten hast.");
          break;
        case 'account-locked':
          this.showAlert("Account gesperrt", "Bitte kontakiere unseren Support für weitere Informationen.");
          break;
        default:
          this.showAlert("Interner Fehler", "Interner Fehler beim Anmelden! Bitte versuche es später nochmal.");
      }

      this.isSigningIn = false;
    }, () => {
      console.log('always');
    });
  }

  private showAlert(title: string, text: string, afterClosed: (() => any) | null = null) {
    this.dialog.open(AlertComponent, {
      data: {
        title: title,
        text: text
      },
      disableClose: true
    }).afterClosed().subscribe(() => {
      if (afterClosed) afterClosed();
    });
  }
}
