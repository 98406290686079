<main *ngIf="upcomingInvoice != null">
  <div *ngFor="let l of upcomingInvoice?.lines ?? []" class="position">
    <div>{{l.title}}</div>
    <div>{{l.amount}}</div>
  </div>
  <hr/>
  <div class="position discounts" *ngFor="let d of upcomingInvoice.discounts">
    <div>{{d.name}}</div>
    <div>{{d.amount}}</div>
  </div>
  <div class="position taxes" *ngIf="upcomingInvoice.taxes != ''">
    <div>Steuer (geschätzt)</div>
    <div>{{upcomingInvoice.taxes}}</div>
  </div>
  <div class="position total">
    <div>
      <div class="title">Geschätzter Gesamtbetrag</div>
      <div class="subtitle">Fällig am {{upcomingInvoice.due_on * 1000 | date: 'dd. MMMM yyyy'}}</div>
    </div>
    <div>{{upcomingInvoice.total}}</div>
  </div>
  <div class="info">{{upcomingInvoice.info}}</div>
</main>

