<main>
  <div id="site">
    <h1>Bestellung</h1>

    <div id="loading"
         *ngIf="!this.isCheckoutAvailable || this.isLoadingLicense || this.isLoadingPaymentMethods || this.isLoadingSEPASession; else content">
      <mat-spinner diameter="32" color="accent"></mat-spinner>
      <span>DATEN WERDEN GELADEN ...</span>
      <div class="clear"></div>
    </div>

    <ng-template #content>
      <div id="plan-selector">
        <div id="plan-selector-title">Plan auswählen (<a href="https://www.smartpager.at/pricing" target="_blank">Preisliste</a>)
        </div>
        <mat-button-toggle-group appearance="legacy" [(ngModel)]="selectedPlan"
                                 (change)="onPlanChanged($event)"
                                 [disabled]="isLoadingPricing || isLoadingCheckoutPreview">
          <mat-button-toggle value="smart">Smart</mat-button-toggle>
          <mat-button-toggle value="enterprise">Enterprise</mat-button-toggle>
        </mat-button-toggle-group>
      </div>

      <div id="content" *ngIf="selectedPlan == 'smart'">
        <div id="plan">
          <div id="loading-plan" *ngIf="isLoadingPricing; else contentPlan">
            <mat-spinner diameter="40" color="accent"></mat-spinner>
          </div>

          <ng-template #contentPlan>
            <div id="plan-name">Plan "{{ price?.plan_name }}" abonnieren</div>
            <div id="plan-price">ab {{ price?.price_plan }}</div>
            <div id="plan-billing-cycle">pro {{ price?.billing_period == "month" ? "Monat" : "Jahr" }}</div>

            <div id="plan-positions">
              <div class="plan-item">
                <div>Grundtarif Plan "{{ price?.plan_name }}"</div>
                <div>{{ price?.price_plan }}</div>
              </div>

              <div id="plan-features">
                <ul>
                  <li *ngFor="let highlight of price?.highlights ?? []">
                    <mat-icon>done</mat-icon>
                    {{ highlight }}
                  </li>
                </ul>
                <a href="https://www.smartpager.at/client" target="_blank">Alle Funktionen</a>
              </div>

              <div class="plan-item">
                <div>Zusätzliche Mitglieder</div>
                <div>{{ price?.price_member }} / Mitglied</div>
              </div>
            </div>

            <div id="due-today">Heute fällig: Grundtarif Plan "{{ price?.plan_name }}"</div>

            <div id="invoice-preview">
              <div id="loading-invoice-preview" *ngIf="isLoadingCheckoutPreview; else invoicePreviewContent">
                <mat-spinner diameter="32" color="accent"></mat-spinner>
                <div>Vorschau wird geladen ...</div>
              </div>

              <ng-template #invoicePreviewContent>
                <div id="subtotal">
                  <div>Zwischensumme</div>
                  <div>{{ checkoutPreview?.subtotal }}</div>
                </div>

                <div class="discount" *ngFor="let d of checkoutPreview?.discounts">
                  <div>{{ d.name }}</div>
                  <div>{{ d.amount }}</div>
                </div>

                <div id="taxes-info">
                  <div *ngIf="checkoutPreview?.is_reverse_charge; else taxesInfo">
                    Steuer zahlbar auf Reverse-Charge-Basis
                  </div>

                  <ng-template #taxesInfo>
                    <div>{{ checkoutPreview?.tax_rate_percentage }} {{ checkoutPreview?.tax_rate_name }}</div>
                    <div>{{ checkoutPreview?.tax_rate_amount }}</div>
                  </ng-template>
                </div>

                <div id="total">
                  <div>Gesamt</div>
                  <div>{{ checkoutPreview?.total }}</div>
                </div>
              </ng-template>
            </div>
          </ng-template>
        </div>

        <div id="payment">
          <div id="payment-method">
            <div id="payment-method-title">Zahlungsmethode</div>
            <payment-method [disabled]="isCheckingOut"></payment-method>
          </div>

          <div id="billing-period">
            <div id="billing-period-title">Abrechnungszeitraum</div>
            <mat-button-toggle-group appearance="legacy" [(ngModel)]="selectedBillingPeriod"
                                     (change)="onBillingPeriodChanged($event)"
                                     [disabled]="isLoadingPricing || isLoadingCheckoutPreview">
              <mat-button-toggle value="month">Monatlich</mat-button-toggle>
              <mat-button-toggle value="year">Jährlich</mat-button-toggle>
            </mat-button-toggle-group>
            <div>Die tatsächlichen Kosten werden
              <b>{{ selectedBillingPeriod == 'month' ? 'monatlich' : 'jährlich' }}</b> aufgrund der maximalen
              Mitgliederanzahl im Nachhinein berechnet.
            </div>
          </div>

          <div id="submit">
            <div id="submit-infos">
              <div>
                <span *ngIf="paymentMethod != null">Der Betrag wird automatisch von der angegebenen
                Zahlungsmethode abgebucht.</span><span *ngIf="paymentMethod == null">Der Betrag ist innerhalb von 30 Tagen auf das auf der Rechnung angegebene Konto zu überweisen.</span>
                Die Kündigung ist zum Ende jedes Abrechnungszeitraumes möglich.
              </div>
            </div>

            <label><input type="checkbox" [(ngModel)]="isNpo" [disabled]="isCheckingOut"> Ich nehme zur Kenntnis, dass
              dieser Plan ausschließlich durch Behörden und Organisationen mit Sicherheitsaufgaben (BOS), Non-Profit
              Organisationen (NPO) und Schulsanitätsdienste bestellt werden kann</label>

            <label><input type="checkbox" [(ngModel)]="isAcceptingTerms" [disabled]="isCheckingOut"> Ich stimme den
              <a href="https://www.smartpager.at/terms" target="_blank">allgemeinen Geschäftsbedingungen</a> von
              Elias Lexl und den Bedingungen der automatischen Verlängerung zu</label>

            <button (click)="checkout()"
                    [disabled]="!isNpo || !isAcceptingTerms || isCheckingOut || isLoadingPricing || isLoadingCheckoutPreview || !price">
              {{ this.isCheckingOut ? 'Bitte warten ...' : 'Kostenpflichtig bestellen' }}
            </button>
          </div>
        </div>
      </div>

      <div id="content-enterprise" *ngIf="selectedPlan == 'enterprise'">
        <div id="payment-method2">
          <div id="payment-method-title2">Zahlungsmethode</div>
          <payment-method [disabled]="isCheckingOut"></payment-method>
        </div>

        <button (click)="onRequestOfferClick($event)">Angebot erhalten</button>
      </div>
    </ng-template>
  </div>
</main>
