import {Component, OnInit} from '@angular/core';
import {FormControl, Validators} from "@angular/forms";
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {Api} from "../../../core/network/Api";
import {AlertComponent} from "../../../dialogs/alert/alert.component";

@Component({
  selector: 'app-deletion',
  templateUrl: './deletion.component.html',
  styleUrls: ['./deletion.component.scss']
})
export class DeletionComponent implements OnInit {

  isLoading = false;
  hasSignedOut = false;

  email = new FormControl('', [Validators.required, Validators.email]);

  deletionType = '';
  notes = '';

  constructor(private httpClient: HttpClient, private route: ActivatedRoute, public dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.hasSignedOut = params['signout'] || false;
    });
  }

  requestDeletion(): void {
    if (this.email.value === '' || this.deletionType === '') {
      alert('Bitte überprüfe deine Eingaben und versuche es nochmal.')
      return;
    }

    this.isLoading = true;

    Api.privacyDeletion(this.httpClient, {
      email: this.email.value,
      type: this.deletionType,
      notes: this.notes
    }, () => {
      this.showAlert("Datenlöschung beantragt", "Dein Antrag ist bei uns eingegangen und wird in Kürze von uns geprüft.", () => {
        window.location.href = 'https://www.smartpager.at';
      });
    }, error => {
      console.log(error);

      switch (error) {
        case 'invalid-argument':
          this.showAlert("Eingabe(n) ungültig", "Bitte überprüfe deine Eingaben und versuche es nochmal.");
          break;
        default:
          this.showAlert("Interner Fehler", "Interner Fehler beim Senden! Bitte versuche es später nochmal.");
      }

      this.isLoading = false;
    }, () => {
      console.log('always');
    });
  }

  private showAlert(title: string, text: string, afterClosed: (() => any) | null = null) {
    console.log('showalert')
    this.dialog.open(AlertComponent, {
      data: {
        title: title,
        text: text
      },
      disableClose: true
    }).afterClosed().subscribe(() => {
      if (afterClosed) afterClosed();
    });
  }
}
