<main>
  <h1>{{monitor?.label ?? ''}}</h1>

  <div class="loading" *ngIf="isLoading; else content">
    <mat-spinner color="accent" diameter="32"></mat-spinner>
  </div>

  <ng-template #content>
    <div id="content">
      <p *ngIf="monitor?.is_deletable && monitor?.status != 'connected'">
        <button class="btn-action btn-primary" (click)="onOpenMonitorClick($event)">Monitor öffnen</button>
      </p>

      Status: {{monitor?.status_text}}
      <br><br>
      <div id="actions" *ngIf="monitor?.is_deletable && monitor?.status != 'connected'">
        <button class="btn-action btn-primary btn-red" (click)="onDeleteClick($event)">Löschen</button>
      </div>
    </div>
  </ng-template>
</main>
