import {Component, Input, OnInit} from '@angular/core';
import {Plan} from "../../core/models/Plan";

@Component({
  selector: 'app-plan-item',
  templateUrl: './plan-item.component.html',
  styleUrls: ['./plan-item.component.scss']
})
export class PlanItemComponent implements OnInit {
  @Input() plan: Plan | null = null;
  @Input() isSelected = false;

  constructor() {
  }

  ngOnInit(): void {
  }

}
