import {Component, OnInit} from '@angular/core';
import {ApiService} from "../../../core/services/api.service";
import {OrganizationService} from "../../../core/services/organization.service";
import {OperationListRequest} from "../../../core/network/request/OperationListRequest";
import {NewsRoadmapResponse} from "../../../core/network/response/NewsRoadmapResponse";
import {RoadmapEntry} from "../../../core/network/models/RoadmapEntry";

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {

  isLoading = false;

  entries: RoadmapEntry[] = [];

  constructor(private api: ApiService, private organizationService: OrganizationService) {
  }

  ngOnInit(): void {
    this.loadData();
  }

  private loadData(): void {
    this.isLoading = true;

    const request = {organization_id: this.organizationService.getSelectedOrganization()?.id ?? 0} as OperationListRequest;

    this.api.newsRoadmap(request, (response: NewsRoadmapResponse) => {
      this.entries = response.roadmap_entries.sort((a, b) => {
        if (b.status.sort_priority < a.status.sort_priority) return -1;
        if (b.status.sort_priority > a.status.sort_priority) return 1;
        if (b.type.title > a.type.title) return -1;
        if (b.type.title < a.type.title) return 1;
        return b.title > a.title ? -1 : 1;
      });
    }, (error: string) => {
      // todo
    }, () => {
      this.isLoading = false;
    })
  }
}
