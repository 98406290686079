<main>
  <h1>Ereignisse</h1>

  <div class="loading" *ngIf="isLoading; else content">
    <mat-spinner color="accent" diameter="32"></mat-spinner>
  </div>

  <ng-template #content>
    <ul class="list">
      <li *ngFor="let event of events">
        <div class="category-indicator"></div>
        <div class="details">
          <div class="title">{{event.title}}</div>
          <div class="time">{{event.time_start * 1000 | date: 'dd.MM.yyyy HH:mm'}}
            &ndash; {{event.time_end * 1000 | date: 'dd.MM.yyyy HH:mm'}}</div>
        </div>
        <div class="answer" *ngIf="event.answer != null">
          <mat-icon [ngClass]="'answer-' + (event?.answer ?? 'unknown')">
            {{event.answer == 'accept' ? 'done' : (event.answer == 'decline' ? 'close' : (event.answer == 'no-answer' ? 'schedule' : ''))}}
          </mat-icon>
        </div>
      </li>
    </ul>

    <div *ngIf="events.length <= 0">
      Keine Ereignisse vorhanden.
    </div>
  </ng-template>
</main>
