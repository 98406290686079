import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {OrganizationService} from "../../../core/services/organization.service";
import {HttpClient} from "@angular/common/http";
import {MatDialog} from "@angular/material/dialog";
import {Api} from "../../../core/network/Api";
import {AlertComponent} from "../../../dialogs/alert/alert.component";

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit {
  isLoadingSettings = false;

  isSaving = false;

  operationAutoDelete = '';

  isCompany = false;

  constructor(private router: Router, private organizationService: OrganizationService, private httpClient: HttpClient, public dialog: MatDialog) {
    this.isCompany = (this.organizationService.getSelectedOrganization()?.organization_type_id ?? 0) == 4;
  }

  ngOnInit(): void {
    this.loadData();
  }

  private loadData(): void {
    this.loadSettings();
  }

  private loadSettings(): void {
    this.isLoadingSettings = true;

    Api.privacyGetSetting(this.httpClient, `${this.organizationService.selectedOrganizationId}`, data => {
      this.operationAutoDelete = data.settings.find(x => x.key == 'operation-auto-delete-after')?.value ?? ''; // todo
    }, (error: string) => {
      alert(error); // todo
    }, () => {
      this.isLoadingSettings = false;
    });
  }

  save(): void {
    this.isSaving = true;

    let settings = [];

    Api.privacyPostSetting(this.httpClient, `${this.organizationService.selectedOrganizationId}`, {
      settings: [
        {key: 'operation-auto-delete-after', value: this.operationAutoDelete}
      ]
    }, (): void => {
      this.showAlert("Einstellungen gespeichert", "Die Einstellungen wurden erfolgreich gespeichert!", () => {
        this.loadData();
        this.isSaving = false;
      })
    }, (error: string) => {
      console.log(error);
      // todo
      switch (error) {
        case 'email-format':
          this.showAlert("Ungültiges E-Mail-Format", "Bitte überprüfe deine Eingaben und versuche es nochmal.");
          break;
        case 'email-duplicate':
          this.showAlert("E-Mail-Adresse in Verwendung", "Diese E-Mail-Adresse ist bereits in Verwendung. Bei Fragen schreibe uns bitte an support@smartpager.at");
          break;
        default:
          this.showAlert("Unbekannter Fehler", "Unbekannter Fehler beim Speichern! Bitte versuche es später nochmal.");
      }
      this.isSaving = false;
    }, () => {
    });
  }

  private showAlert(title: string, text: string, afterClosed: (() => any) | null = null) {
    this.dialog.open(AlertComponent, {
      data: {
        title: title,
        text: text
      },
      disableClose: true
    }).afterClosed().subscribe(() => {
      if (afterClosed) afterClosed();
    });
  }

  public sendPrivacyEmail() {
    window.location.href = "mailto:office@smartpager.at?subject=AV-Vertrag";
  }
}
