import {Component, OnDestroy, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {OrganizationService} from "../../../../core/services/organization.service";
import {Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {ToolbarService} from "../../../../core/services/toolbar.service";
import {Api} from "../../../../core/network/Api";
import {AlertComponent} from "../../../../dialogs/alert/alert.component";
import {BindingsRescueTabletInfo} from "../../../../core/models/BindingsRescueTabletInfo";

@Component({
  selector: 'app-rescue-tablet',
  templateUrl: './rescue-tablet.component.html',
  styleUrls: ['./rescue-tablet.component.scss']
})
export class RescueTabletComponent implements OnInit, OnDestroy {

  isLoading = false;
  isProcessing = false;

  info: BindingsRescueTabletInfo | null = null;

  token: string = "";

  constructor(private httpClient: HttpClient, private organizationService: OrganizationService, private router: Router,
              public dialog: MatDialog, private toolbarService: ToolbarService) {
  }

  ngOnInit(): void {
    this.toolbarService.setHierarchy([
      {title: "Anbindungen", path: "/home/bindings"}
    ]);
    this.toolbarService.setPageTitle("rescueTABLET (Beta)");

    this.loadData();
  }

  ngOnDestroy() {
    this.toolbarService.resetHierarchy();
  }

  private loadData() {
    this.isLoading = true;
    Api.bindingsRescueTabletInfo(this.httpClient, {organization_id: this.organizationService.selectedOrganizationId}, response => {
      this.info = response.info;
    }, (error: string) => {
      // todo: handle error
      this.isLoading = false;
    }, () => {
      this.isLoading = false;
    });
  }

  enableBinding(): void {
    let token = this.token.trim();

    if (token == '') {
      this.showAlert('Ungültige Eingabe', 'Bitte überprüfe deine Eingaben und versuche es nochmal.');
      return;
    }

    this.isProcessing = true;

    Api.bindingsRescueTabletEnable(this.httpClient, {
      organization_id: this.organizationService.selectedOrganizationId,
      token: this.token
    }, () => {
      this.token = "";

      this.loadData();
      this.isProcessing = false;
    }, error => {
      console.log(error);
      switch (error) {
        case 'invalid-credentials':
          this.showAlert('Zugriffsschlüssel ungültig', 'Der Zugriffsschlüssel ist ungültig. Bitte überprüfe deine Eingabe und versuche es nochmal.');
          break;
        case 'internal':
          this.showAlert('Interner Fehler', 'Aufgrund eines internen Fehlers konnte die Anbindung nicht aktiviert werden. Bitte versuche es später nochmal.');
          break;
        default:
          this.showAlert('Unbekannter Fehler', 'Aufgrund eines ungekannten Fehlers konnte die Anbindung nicht aktiviert werden. Bitte kontaktiere unseren Support für weitere Informationen.');
      }
      this.isProcessing = false;
    }, () => {
      // todo
    });
  }

  disableBinding(): void {
    this.isProcessing = true;

    Api.bindingsRescueTabletDisable(this.httpClient, {organization_id: this.organizationService.selectedOrganizationId}, () => {
      this.loadData();
      this.isProcessing = false;
    }, error => {
      // todo
      this.isProcessing = false;
    }, () => {
      // todo
    });
  }

  navigateTo(route: string): void {
    this.router.navigateByUrl(route);
  }

  private showAlert(title: string, text: string, afterClosed: (() => any) | null = null) {
    this.dialog.open(AlertComponent, {
      data: {
        title: title,
        text: text
      },
      disableClose: true
    }).afterClosed().subscribe(() => {
      if (afterClosed) afterClosed();
    });
  }
}
