import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {FormBuilder} from '@angular/forms';
import {Router} from '@angular/router';
import {OrganizationService} from '../../../core/services/organization.service';
import {Api} from '../../../core/network/Api';
import {License} from '../../../core/models/License';
import {Invoice} from '../../../core/models/Invoice';
import {UpcomingInvoice} from "../../../core/network/models/UpcomingInvoice";
import {MemberUsage} from "../../../core/network/models/MemberUsage";
import {LicenseType} from "../../../core/constants/LicenseType";

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.css']
})
export class BillingComponent implements OnInit {

  isLoadingLicense = false;
  isPaidLicenseAvailable = false;

  license: License | null = null;

  isLoadingUpcomingInvoice = false;
  upcomingInvoice: UpcomingInvoice | null = null;

  isLoadingPaymentMethod = false;

  isLoadingDynamicMemberUsage = false;
  memberUsage: MemberUsage | null = null;

  isLoadingInvoices = false;
  invoices: Invoice[] = [];

  constructor(private httpClient: HttpClient, private fb: FormBuilder, private router: Router,
              private organizationService: OrganizationService) {
  }

  ngOnInit(): void {
    this.isPaidLicenseAvailable = this.organizationService.getSelectedOrganization()?.organization_type_id == 4;
    this.loadData();
  }

  private loadData(): void {
    this.loadLicense();
  }

  private loadLicense() {
    this.isLoadingLicense = true;

    Api.license(this.httpClient, {organization_id: this.organizationService.selectedOrganizationId}, response => {
      this.license = response.license;

      if (this.license.type == "beta") {
        this.isPaidLicenseAvailable = false;
      } else if (this.isPaidLicenseAvailable) {
        this.loadInvoices();
      }

      this.isLoadingLicense = false;

      if (this.license.type == LicenseType.PAID || this.license.type == LicenseType.ENTERPRISE) {
        this.loadUpcomingInvoice();
        this.loadDynamicMemberUsage();
      }
    }, (error: string) => {
      this.isLoadingLicense = false;
    }, () => {
      this.isLoadingLicense = false;
    });
  }

  private loadInvoices() {
    this.isLoadingInvoices = true;

    Api.invoices(this.httpClient, {organization_id: `${this.organizationService.selectedOrganizationId}`}, invoices => {
      this.invoices = invoices;
    }, (error: string) => {
      // todo: show error
    }, () => {
      this.isLoadingInvoices = false;
    });
  }

  private loadUpcomingInvoice() {
    this.isLoadingUpcomingInvoice = true;

    Api.upcomingInvoice(this.httpClient, {organization_id: `${this.organizationService.selectedOrganizationId}`}, response => {
      //console.log(response.data.upcoming_invoice);
      this. upcomingInvoice = response.data.upcoming_invoice;
    }, (error: string) => {
      // todo: show error
    }, () => {
      this.isLoadingUpcomingInvoice = false;
    });
  }

  private loadDynamicMemberUsage() {
    this.isLoadingDynamicMemberUsage = true;

    Api.licenseMemberUsage(this.httpClient, {organization_id: `${this.organizationService.selectedOrganizationId}`}, response => {
      this.memberUsage = response.data.member_usage;
    }, (error: string) => {
      // todo: show error
    }, () => {
      this.isLoadingDynamicMemberUsage = false;
    });
  }

  openCheckout(): void {
    this.router.navigateByUrl('/home/billing/plan/checkout');
  }

  openBusinessConfirmation(): void {
    this.router.navigateByUrl('/home/billing/business-confirmation');
  }

  openUsage(): void {
    this.router.navigateByUrl('/home/billing/plan/usage');
  }

  openPaymentPage(): void {
    // todo: handle empty link
    window.open(this.license?.payment_url ?? "", '_blank');
  }

  openEmail(subject: string): void {
    window.location.href = `mailto:office@smartpager.at?subject=${encodeURI(subject)}`;
  }

  changeLicense() {
    this.router.navigate(['/home/billing/plan/change']);
  }

  renewLicense() {
    this.router.navigate(['/home/billing/plan/renew']);
  }

  updateLicense() {
    this.router.navigate(['/home/billing/plan/update']);
  }

  protected readonly LicenseType = LicenseType;
}
