<mat-card>
  <header>
    <img src="/assets/images/logo.webp">
  </header>
  <main>
    <p>Erstelle dir dein persönliches SmartPager-Konto in der App.</p>

    <div id="store-buttons">
      <a href="https://itunes.apple.com/at/app/smartpager/id1270250630" target="_blank">
        <img src="/assets/images/appstore.png">
      </a>
      <a href="https://play.google.com/store/apps/details?id=at.smartpager.client" target="_blank">
        <img src="/assets/images/playstore.png">
      </a>
    </div>
    <br>
    <button mat-stroked-button color="accent" routerLink="/">ZURÜCK</button>
  </main>
</mat-card>
<footer>
  &copy; <a href="https://www.smartpager.at" target="_blank">Elias Lexl</a> |
  <a href="https://www.smartpager.at/privacy" target="_blank">Datenschutz</a> |
  <a href="https://www.smartpager.at/terms" target="_blank">AGB</a> |
  <a href="https://www.smartpager.at/impressum" target="_blank">Impressum</a>
</footer>
