import {APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import * as Sentry from '@sentry/angular';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {Router} from "@angular/router";
import {AuthGuard} from "./core/guards/auth.guard";
import {OrganizationService} from "./core/services/organization.service";
import {UserService} from "./core/services/user.service";
import {MatCardModule} from "@angular/material/card";
import {DateAdapter, MatNativeDateModule, MatRippleModule} from "@angular/material/core";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatSelectModule} from "@angular/material/select";
import {MatDialogModule} from "@angular/material/dialog";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatInputModule} from "@angular/material/input";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatStepperModule} from "@angular/material/stepper";
import {MatRadioModule} from "@angular/material/radio";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {HttpClientModule} from "@angular/common/http";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {E404Component} from "./pages/errors/e404/e404.component";
import {LoginComponent} from "./pages/login/login.component";
import {HomeComponent} from "./pages/home/home.component";
import {BillingComponent} from "./pages/home/subscriptions/billing.component";
import {SignInComponent} from "./pages/login/sign-in/sign-in.component";
import {StartComponent} from "./pages/home/start/start.component";
import {CheckoutComponent} from "./pages/home/subscriptions/checkout/checkout.component";
import {SignUpComponent} from "./pages/login/sign-up/sign-up.component";
import {LicenseCancelComponent} from "./pages/home/subscriptions/license-cancel/license-cancel.component";
import {LicenseRenewComponent} from "./pages/home/subscriptions/license-renew/license-renew.component";
import {OrganizationUpdateComponent} from "./pages/home/organization/organization-update/organization-update.component";
import {BindingsComponent} from "./pages/home/bindings/bindings.component";
import {BindingLwzooeComponent} from "./pages/home/bindings/binding-lwzooe/binding-lwzooe.component";
import {UpdatePaymentMethodComponent} from "./pages/home/billing/update-payment-method/update-payment-method.component";
import {AlertComponent} from "./dialogs/alert/alert.component";
import {registerLocaleData} from "@angular/common";
import localeDE from '@angular/common/locales/de';
import {PaymentMethodComponent} from './components/payment-method/payment-method.component';
import {CheckoutSuccessComponent} from "./dialogs/checkout-success/checkout-success.component";
import {environment} from "../environments/environment";
import {UpcomingInvoiceComponent} from './components/upcoming-invoice/upcoming-invoice.component';
import {BillingHistoryComponent} from './components/billing-history/billing-history.component';
import {DynamicMemberUsageComponent} from './components/dynamic-member-usage/dynamic-member-usage.component';
import {LicenseUsageComponent} from './pages/home/billing/license-usage/license-usage.component';
import {OperationsComponent} from './pages/home/operations/operations.component';
import {CreateOperationComponent} from './pages/home/operations/create-operation/create-operation.component';
import {ApiService} from "./core/services/api.service";
import {MembersComponent} from './pages/home/members/members.component';
import {GetStartedComponent} from './pages/home/get-started/get-started.component';
import {BindingLsTirolComponent} from './pages/home/bindings/binding-ls-tirol/binding-ls-tirol.component';
import {InterfaceComponent} from "./pages/home/bindings/interface/interface.component";
import {
  BusinessConfirmationComponent
} from './pages/home/billing/business-confirmation/business-confirmation.component';
import {BindingConnectComponent} from './pages/home/bindings/binding-connect/binding-connect.component';
import {OperationDetailComponent} from './pages/home/operations/operation-detail/operation-detail.component';
import {WasserkarteComponent} from './pages/home/bindings/wasserkarte/wasserkarte.component';
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatSliderModule} from "@angular/material/slider";
import {BindingMapTripComponent} from './pages/home/bindings/binding-maptrip/binding-map-trip.component';
import {MatExpansionModule} from "@angular/material/expansion";
import {AddMemberComponent} from './pages/home/members/add-member/add-member.component';
import {
  AddMemberByEmailComponent
} from "./pages/home/members/add-member/add-member-by-email/add-member-by-email.component";
import {
  InviteMemberByEmailComponent
} from './pages/home/members/add-member/invite-member-by-email/invite-member-by-email.component';
import {EventsComponent} from './pages/home/events/events.component';
import {CreateEventComponent} from './pages/home/events/create-event/create-event.component';
import {MatDatepickerModule} from "@angular/material/datepicker";
import {EuropeDateAdapter} from "./core/adapter/EuropeDateAdapter";
import {MonitorsComponent} from './pages/home/monitors/monitors.component';
import {AddMonitorComponent} from './pages/home/monitors/add-monitor/add-monitor.component';
import {MonitorDetailsComponent} from "./pages/home/monitors/monitor-details/monitor-details.component";
import {NewsComponent} from './pages/home/news/news.component';
import {
  BindingExternalIcalendarComponent
} from './pages/home/bindings/binding-external-icalendar/binding-external-icalendar.component';
import {BindingSmsComponent} from './pages/home/bindings/binding-sms/binding-sms.component';
import { ActionNotificationsComponent } from './pages/home/action-notifications/action-notifications.component';
import { ActionNotificationDialogComponent } from './dialogs/action-notification-dialog/action-notification-dialog.component';
import { ActionNotificationRecipientListItemComponent } from './components/action-notification-recipient-listitem/action-notification-recipient-list-item.component';
import { ActionNotificationRecipientEditDialogComponent } from './dialogs/action-notification-recipient-edit-dialog/action-notification-recipient-edit-dialog.component';
import { PrivacyComponent } from './pages/home/privacy/privacy.component';
import { LwzOoeBindingComponent } from './pages/home/bindings/lwz-ooe-binding/lwz-ooe-binding.component';
import { ExternalIcalendarEditDialogComponent } from './dialogs/external-icalendar-edit-dialog/external-icalendar-edit-dialog.component';
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import { PlanItemComponent } from './components/plan-item/plan-item.component';
import { DeletionComponent } from './pages/privacy/deletion/deletion.component';
import { RescueTabletComponent } from './pages/home/bindings/rescue-tablet/rescue-tablet.component';

registerLocaleData(localeDE);

@NgModule({
  declarations: [
    AppComponent,
    E404Component,
    LoginComponent,
    HomeComponent,
    BillingComponent,
    SignInComponent,
    StartComponent,
    CheckoutComponent,
    CheckoutSuccessComponent,
    SignUpComponent,
    LicenseCancelComponent,
    LicenseRenewComponent,
    OrganizationUpdateComponent,
    BindingsComponent,
    BindingLwzooeComponent,
    InterfaceComponent,
    UpdatePaymentMethodComponent,
    AlertComponent,
    PaymentMethodComponent,
    UpcomingInvoiceComponent,
    BillingHistoryComponent,
    DynamicMemberUsageComponent,
    LicenseUsageComponent,
    OperationsComponent,
    CreateOperationComponent,
    MembersComponent,
    GetStartedComponent,
    BindingLsTirolComponent,
    BusinessConfirmationComponent,
    BindingConnectComponent,
    OperationDetailComponent,
    WasserkarteComponent,
    BindingMapTripComponent,
    AddMemberComponent,
    AddMemberByEmailComponent,
    InviteMemberByEmailComponent,
    EventsComponent,
    CreateEventComponent,
    MonitorsComponent,
    AddMonitorComponent,
    MonitorDetailsComponent,
    NewsComponent,
    BindingExternalIcalendarComponent,
    BindingSmsComponent,
    ActionNotificationsComponent,
    ActionNotificationDialogComponent,
    ActionNotificationRecipientListItemComponent,
    ActionNotificationRecipientEditDialogComponent,
    PrivacyComponent,
    LwzOoeBindingComponent,
    ExternalIcalendarEditDialogComponent,
    PlanItemComponent,
    DeletionComponent,
    RescueTabletComponent
  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        MatCardModule,
        MatRippleModule,
        MatSidenavModule,
        MatSelectModule,
        MatDialogModule,
        MatIconModule,
        MatButtonModule,
        MatProgressBarModule,
        MatToolbarModule,
        MatTooltipModule,
        MatInputModule,
        MatFormFieldModule,
        MatStepperModule,
        MatRadioModule,
        MatProgressSpinnerModule,
        HttpClientModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        FormsModule,
        MatCheckboxModule,
        MatSlideToggleModule,
        MatSliderModule,
        MatExpansionModule,
        MatDatepickerModule,
        ReactiveFormsModule,
        MatNativeDateModule,
        MatButtonToggleModule
    ],
  providers: [
    UserService,
    OrganizationService,
    ApiService,
    AuthGuard,
    {provide: LOCALE_ID, useValue: 'de-AT'},
    {provide: DateAdapter, useClass: EuropeDateAdapter},
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
        logErrors: environment.production
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [Sentry.TraceService],
      multi: true,
    }],
  bootstrap: [AppComponent]
})
export class AppModule {
}
