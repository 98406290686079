import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import {Integrations} from '@sentry/tracing';
import * as Sentry from '@sentry/angular';

if (environment.production) {
  enableProdMode();

  Sentry.init({
    dsn: 'https://13ffd4404ac1fd8db7a714dd465dfb0c@sentryio.eliaslexl.at/19',
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: ['localhost', 'https://api.smartpager.at', 'https://api-test.smartpager.at'],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],

    tracesSampleRate: 0.10,
    enabled: environment.production
  });
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
