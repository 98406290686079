import {Component, OnDestroy, OnInit} from '@angular/core';
import {Api} from "../../../../core/network/Api";
import {HttpClient} from "@angular/common/http";
import {OrganizationService} from "../../../../core/services/organization.service";
import {AddOnSmsHistoryResponse} from "../../../../core/network/response/AddOnSmsHistoryResponse";
import {SmsJob} from "../../../../core/network/models/SmsJob";
import {AddOnSmsResponse} from "../../../../core/network/response/AddOnSmsResponse";
import {SmsAddOnInfo} from "../../../../core/network/models/SmsAddOnInfo";
import {AlertComponent} from "../../../../dialogs/alert/alert.component";
import {MatDialog} from "@angular/material/dialog";
import {ToolbarService} from "../../../../core/services/toolbar.service";
import {LicenseCheckoutPreview} from "../../../../core/models/LicenseCheckoutPreview";
import {AddOnPrice} from "../../../../core/models/AddOnPrice";
import {AddOnSmsPricingResponse} from "../../../../core/network/response/AddOnSmsPricingResponse";
import {AddOnSmsPricingPreviewResponse} from "../../../../core/network/response/AddOnSmsPricingPreviewResponse";

@Component({
  selector: 'app-binding-sms',
  templateUrl: './binding-sms.component.html',
  styleUrls: ['./binding-sms.component.scss']
})
export class BindingSmsComponent implements OnInit, OnDestroy {
  isLoading = false;
  isLoadingInvoicePreview = false;
  isProcessing = false;

  info: SmsAddOnInfo | null = null;
  smsJobHistory: SmsJob[] = [];

  pricing: AddOnPrice | null = null;

  pricingPreview: LicenseCheckoutPreview | null = null; // todo: model

  constructor(private httpClient: HttpClient, private organizationService: OrganizationService, public dialog: MatDialog, private toolbarService: ToolbarService) {
  }

  ngOnInit(): void {
    this.toolbarService.setHierarchy([
      {title: "Anbindungen", path: "/home/bindings"}
    ]);
    this.toolbarService.setPageTitle("SMS");

    this.loadInfo();
    this.loadPricing();
    this.loadPricingPreview();
    this.loadJobHistory();
  }

  ngOnDestroy(): void {
    this.toolbarService.resetHierarchy();
  }

  private loadInfo(): void {
    this.isLoading = true;

    Api.addOnSms(this.httpClient, `${this.organizationService.selectedOrganizationId}`, (response: AddOnSmsResponse) => {
      this.info = response.info;
    }, error => {
      console.log('loadInfo > error', error);
      /*switch (error) {
        // todo
        case 'invalid-credentials':
          this.showAlert('Zugriffsschlüssel ungültig', 'Der Zugriffsschlüssel ist ungültig. Bitte überprüfe deine Eingabe und versuche es nochmal.');
          break;
        case 'internal':
          this.showAlert('Interner Fehler', 'Aufgrund eines internen Fehlers konnte die Anbindung nicht aktiviert werden. Bitte versuche es später nochmal.');
          break;
        default:
          this.showAlert('Unbekannter Fehler', 'Aufgrund eines ungekannten Fehlers konnte die Anbindung nicht aktiviert werden. Bitte kontaktiere unseren Support für weitere Informationen.');
      }
      this.isProcessing = false;*/
    }, () => {
      this.isLoading = false;
    });
  }

  private loadPricing(): void {
    Api.addOnSmsPricing(this.httpClient, {organization_id: `${this.organizationService.selectedOrganizationId}`}, (response: AddOnSmsPricingResponse) => {
      this.pricing = response;
    }, error => {
      console.log('loadPricing > error', error);
      /*switch (error) {
        // todo
        case 'invalid-credentials':
          this.showAlert('Zugriffsschlüssel ungültig', 'Der Zugriffsschlüssel ist ungültig. Bitte überprüfe deine Eingabe und versuche es nochmal.');
          break;
        case 'internal':
          this.showAlert('Interner Fehler', 'Aufgrund eines internen Fehlers konnte die Anbindung nicht aktiviert werden. Bitte versuche es später nochmal.');
          break;
        default:
          this.showAlert('Unbekannter Fehler', 'Aufgrund eines ungekannten Fehlers konnte die Anbindung nicht aktiviert werden. Bitte kontaktiere unseren Support für weitere Informationen.');
      }
      this.isProcessing = false;*/
    }, () => {
      this.isLoading = false;
    });
  }

  private loadPricingPreview(): void {
    this.isLoadingInvoicePreview = true;

    Api.addOnSmsPricingPreview(this.httpClient, {organization_id: `${this.organizationService.selectedOrganizationId}`}, (response: AddOnSmsPricingPreviewResponse) => {
      this.pricingPreview = response;
    }, error => {
      console.log('loadPricing > error', error);
      /*switch (error) {
        // todo
        case 'invalid-credentials':
          this.showAlert('Zugriffsschlüssel ungültig', 'Der Zugriffsschlüssel ist ungültig. Bitte überprüfe deine Eingabe und versuche es nochmal.');
          break;
        case 'internal':
          this.showAlert('Interner Fehler', 'Aufgrund eines internen Fehlers konnte die Anbindung nicht aktiviert werden. Bitte versuche es später nochmal.');
          break;
        default:
          this.showAlert('Unbekannter Fehler', 'Aufgrund eines ungekannten Fehlers konnte die Anbindung nicht aktiviert werden. Bitte kontaktiere unseren Support für weitere Informationen.');
      }
      this.isProcessing = false;*/
    }, () => {
      this.isLoadingInvoicePreview = false;
    });
  }

  onEnableClick(event: any): void {
    this.isProcessing = true;

    Api.addOnSmsEnable(this.httpClient, {
      organization_id: `${this.organizationService.selectedOrganizationId}`
    }, () => {
      this.loadInfo();
    }, error => {
      switch (error) {
        case 'not-licensed':
          this.showAlert('Plan ungültig', 'Die Aktivierung dieser Erweiterung erfordert einen kostenpflichtigen Plan. Bitte bestelle einen Plan und versuche es nochmal.');
          break;
        case 'internal':
          this.showAlert('Interner Fehler', 'Aufgrund eines internen Fehlers konnte die Erweiterung nicht aktiviert werden. Bitte versuche es später nochmal.');
          break;
        default:
          this.showAlert('Unbekannter Fehler', 'Aufgrund eines ungekannten Fehlers konnte die Erweiterung nicht aktiviert werden. Bitte kontaktiere unseren Support für weitere Informationen.');
      }
      this.isProcessing = false;
    }, () => {
      this.isProcessing = false;
    });
  }

  onDisableClick(event: any): void {
    this.isProcessing = true;

    Api.addOnSmsDisable(this.httpClient, {
      organization_id: `${this.organizationService.selectedOrganizationId}`
    }, () => {
      this.loadInfo();
    }, error => {
      console.log('licenseAddOnSmsDisable > error', error);
      /*switch (error) {
        // todo
        case 'invalid-credentials':
          this.showAlert('Zugriffsschlüssel ungültig', 'Der Zugriffsschlüssel ist ungültig. Bitte überprüfe deine Eingabe und versuche es nochmal.');
          break;
        case 'internal':
          this.showAlert('Interner Fehler', 'Aufgrund eines internen Fehlers konnte die Anbindung nicht aktiviert werden. Bitte versuche es später nochmal.');
          break;
        default:
          this.showAlert('Unbekannter Fehler', 'Aufgrund eines ungekannten Fehlers konnte die Anbindung nicht aktiviert werden. Bitte kontaktiere unseren Support für weitere Informationen.');
      }
      this.isProcessing = false;*/
    }, () => {
      this.isProcessing = false;
    });
  }

  private loadJobHistory(): void {
    Api.addOnSmsHistory(this.httpClient, `${this.organizationService.selectedOrganizationId}`, (response: AddOnSmsHistoryResponse) => {
      this.smsJobHistory = response.history.sort((a, b) => {
        return a.time_queued <= b.time_queued ? 1 : -1;
      });
    }, error => {
      console.log('loadJobHistory > error', error);
    }, () => {
      // todo
    });
  }

  private showAlert(title: string, text: string, afterClosed: (() => any) | null = null) {
    this.dialog.open(AlertComponent, {
      data: {
        title: title,
        text: text
      },
      disableClose: true
    }).afterClosed().subscribe(() => {
      if (afterClosed) afterClosed();
    });
  }
}
