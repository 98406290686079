import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {CreateOperationModel} from "../../../../core/models/component/CreateOperationModel";
import {OperationCreateRequest} from "../../../../core/network/request/OperationCreateRequest";
import {OrganizationService} from "../../../../core/services/organization.service";
import {ApiService} from "../../../../core/services/api.service";
import {MatDialog} from "@angular/material/dialog";
import {AlertComponent} from "../../../../dialogs/alert/alert.component";
import {ToolbarService} from "../../../../core/services/toolbar.service";

@Component({
  selector: 'app-create-operation',
  templateUrl: './create-operation.component.html',
  styleUrls: ['./create-operation.component.scss']
})
export class CreateOperationComponent implements OnInit, OnDestroy {

  isLoading = false;
  isCreating = false;

  input: CreateOperationModel = {
    title: '',
    text: '',
    contact: {
      name: '',
      phone: '',
      notes: ''
    },
    alarm: {
      userSelectionTypeId: 1
    },
    address: {
      name: '',
      street: '',
      streetNumber: '',
      floor: '',
      door: '',
      postcode: '',
      city: '',
      country: '',
      freeText: '',
      latitude: 0.0,
      longitude: 0.0
    },
    isSendingEventNotifications: false,
    isPrintingAlarmSheet: false
  };


  constructor(private router: Router, private organizationService: OrganizationService, private apiService: ApiService,
              public dialog: MatDialog, private toolbarService: ToolbarService) {
  }

  ngOnInit(): void {
    console.log(this.input);

    this.toolbarService.setHierarchy([{title: "Einsätze", path: "/home/operations"}]);
    this.toolbarService.setPageTitle("Einsatz erstellen");
  }

  ngOnDestroy() {
    this.toolbarService.resetHierarchy();
  }

  create() {
    console.log('create');
    this.isCreating = true;

    let request = {
      organization_id: this.organizationService.getSelectedOrganization()?.id,
      title: this.input.title,
      user_selection_type_id: this.input.alarm.userSelectionTypeId,
      text: this.input.text,
      contact_name: this.input.contact.name,
      contact_phone: this.input.contact.phone,
      contact_notes: this.input.contact.notes,
      address_firm: this.input.address.name,
      address_street: this.input.address.street,
      address_street_number: this.input.address.streetNumber,
      address_floor: this.input.address.floor,
      address_door: this.input.address.door,
      address_postcode: this.input.address.postcode,
      address_city: this.input.address.city,
      address_country: this.input.address.country,
      address_free_text: this.input.address.freeText,
      is_triggering_com_events: this.input.isSendingEventNotifications,
      is_printable: this.input.isPrintingAlarmSheet,
      is_answerable: true // todo
    } as OperationCreateRequest;

    this.apiService.operationCreate(request, response => {
      this.router.navigateByUrl('/home/operations');
    }, error => {
      switch (error) {
        case "not-licensed":
          this.showAlert("Limit erreicht", "Das Limit für die Erstellung von Einsätzen im aktuellen Zeitraum wurde erreicht. Weitere Informationen findest du unter \"Abrechnung\".");
          break;
        default:
          this.showAlert("Unbekannter Fehler", "Unbekannter Fehler beim Erstellen des Einsatzes! Bitte versuche es später nochmal.");
      }
      this.isCreating = false;
    }, () => {
      this.isCreating = false;
    })
  }

  private showAlert(title: string, text: string, afterClosed: (() => any) | null = null) {
    this.dialog.open(AlertComponent, {
      data: {
        title: title,
        text: text
      },
      disableClose: true
    }).afterClosed().subscribe(() => {
      if (afterClosed) afterClosed();
    });
  }
}
