<main>
  <h1>Leitstelle Tirol (Beta)</h1>

  <div>
    Neue Einsätze werden automatisch über die App an alle deine Mitglieder ausgesendet.
  </div>

  <div class="loading" *ngIf="isLoading; else content">
    <mat-spinner color="accent" diameter="32"></mat-spinner>
  </div>

  <ng-template #content>
    <div id="content">
      <div id="login" *ngIf="!info?.is_active">
        <mat-form-field appearance="fill" color="accent">
          <mat-label>Benutzername</mat-label>
          <input matInput type="text" placeholder="" [(ngModel)]="username" required>
        </mat-form-field>
        <mat-form-field appearance="fill" color="accent">
          <mat-label>Passwort</mat-label>
          <input matInput type="password" placeholder="" [(ngModel)]="password" required>
        </mat-form-field>
      </div>

      <div id="status" *ngIf="info?.is_active">
        <span></span><b>Aktiv</b>
      </div>

      <div id="actions" *ngIf="info != null">
        <button class="btn-action btn-primary" *ngIf="!info?.is_active" (click)="enableBinding()">Aktivieren</button>
        <button class="btn-action btn-secondary" *ngIf="info?.is_active" (click)="disableBinding()">Anbindung
          deaktivieren
        </button>
      </div>

      <div id="alarm-groups" *ngIf="info?.alarm_groups">
        <h2>SCHLEIFEN</h2>
        <ul>
          <li *ngFor="let ag of info!.alarm_groups">
            <div class="title">{{ ag.name }}</div>
            <div class="details">Empfänger: {{ ag.user_selection_type }}, Benachrichtung: {{ ag.push_type }}</div>
          </li>
        </ul>
      </div>
    </div>
  </ng-template>
</main>
