import {Component, Input, OnInit} from '@angular/core';
import {Invoice} from "../../core/models/Invoice";

@Component({
  selector: 'billing-history',
  templateUrl: './billing-history.component.html',
  styleUrls: ['./billing-history.component.scss']
})
export class BillingHistoryComponent implements OnInit {

  @Input() invoices: Invoice[] = [];

  constructor() {
  }

  ngOnInit(): void {
  }

  openUrlInNewTab(url: string): void {
    window.open(url, '_blank');
  }
}
