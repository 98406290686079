<main>
  <h1>rescueTABLET (Beta)</h1>

  <div>
    <p>Automatische Einsatzerstellung in rescueTABLET</p>
    <p><a href="https://www.rescuetablet.de/" target="_blank">Website von rescueTABLET</a> (CheckBox Soft- und Hardware
      KG)</p>
  </div>

  <mat-expansion-panel id="privacy-info">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <mat-icon>shield</mat-icon>
        Datenschutz
      </mat-panel-title>
    </mat-expansion-panel-header>
    <p>Bei der Erstellung des Einsatzes in rescueTABLET kann es zur Übermittlung von personenbezogenen Daten kommen.
      Schließe in diesem Fall einen entsprechenden Auftragsverarbeitungsvertrag gemäß Art. 28 DSGVO mit CheckBox Soft-
      und Hardware KG ab.</p>
  </mat-expansion-panel>

  <div class="loading" *ngIf="isLoading; else content">
    <mat-spinner color="accent" diameter="32"></mat-spinner>
  </div>

  <ng-template #content>
    <div id="content">
      <div id="login" *ngIf="!info?.is_active">
        <div>Füge hier den Zugriffsschlüssel von rescueTABLET ein.</div>
        <br>

        <mat-form-field appearance="fill" color="accent">
          <mat-label>Zugriffsschlüssel</mat-label>
          <input matInput type="text" placeholder="" [(ngModel)]="token" [disabled]="isProcessing" required>
        </mat-form-field>
      </div>

      <div id="status" *ngIf="info?.is_active">
        <span></span><b>Aktiv</b>
      </div>

      <div id="actions" *ngIf="info != null">
        <button class="btn-action btn-primary" *ngIf="!info?.is_active" (click)="enableBinding()"
                [disabled]="isProcessing">Aktivieren
        </button>
        <button class="btn-action btn-secondary" *ngIf="info?.is_active" (click)="disableBinding()"
                [disabled]="isProcessing">Anbindung
          deaktivieren
        </button>
      </div>
    </div>
  </ng-template>
</main>
