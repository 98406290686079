<main>
  <h1>Einsätze</h1>

  <div class="loading" *ngIf="isLoading; else content">
    <mat-spinner color="accent" diameter="32"></mat-spinner>
  </div>

  <ng-template #content>
    <ul class="list">
      <li *ngFor="let operation of operations" (click)="open(operation.id)">
        <div class="category-indicator" [style.background-color]="operation.category_color"></div>
        <div class="details">
          <div class="title">{{operation.title}}</div>
          <div class="time">{{operation.time_start * 1000 | date: 'dd.MM.yyyy HH:mm'}}<span
            *ngIf="operation.time_end != null"> &ndash; {{operation.time_end! * 1000 | date: 'dd.MM.yyyy HH:mm'}}</span>
          </div>
        </div>
        <div class="answer" *ngIf="operation.answer != null">
          <mat-icon [ngClass]="'answer-' + (operation?.answer ?? 'unknown')">
            {{operation.answer == 'accept' ? 'done' : (operation.answer == 'decline' ? 'close' : (operation.answer == 'no-answer' ? 'schedule' : ''))}}
          </mat-icon>
        </div>
      </li>
    </ul>

    <div *ngIf="operations.length <= 0">
      Keine Einsätze vorhanden.
    </div>
  </ng-template>
</main>
