<main *ngIf="memberUsage != null">
  <div>
    <div>Inkludiert im Plan "Smart"</div>
    <div>{{memberUsage.included}} Mitglieder</div>
  </div>
  <hr>
  <div>
    <div>Aktuell</div>
    <div>{{memberUsage.current}} Mitglieder</div>
  </div>
  <div>
    <div>
      <div class="title">Maximum</div>
      <div class="subtitle">in diesem Abrechnungszeitraum</div>
    </div>
    <div>{{memberUsage.maximum}} Mitglieder</div>
  </div>
  <hr>
  <div class="total">
    <div>
      <div class="title">Zusätzlich</div>
    </div>
    <div>{{memberUsage.additional}} Mitglieder</div>
  </div>
</main>
