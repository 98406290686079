<main class="sp-dialog">
  <header>
    <div class="header-icon">
      <mat-icon>notifications</mat-icon>
    </div>
    <div class="header-title">{{actionNotification?.title}}</div>
    <div class="header-actions">
      <mat-icon matRipple (click)="onCloseClick($event)">close</mat-icon>
    </div>
  </header>
  <div class="content">
    <p>{{actionNotification?.description}}</p>
    <div class="subtitle">
      <span>EMPFÄNGER</span>
      <mat-spinner [diameter]="16" color="accent" *ngIf="isLoading"></mat-spinner>
    </div>
    <div id="recipients" *ngIf="actionNotification != null">
      <app-action-notification-recipient-list-item
        *ngFor="let r of actionNotification?.recipients" [recipient]="r" (deleted)="onRecipientDeleted($event)"></app-action-notification-recipient-list-item>
      <div id="recipient-add" (click)="onAddRecipientClick($event)">
        <div class="icon">
          <mat-icon>add</mat-icon>
        </div>
        <div class="label">EMPFÄNGER HINZUFÜGEN</div>
      </div>
    </div>
  </div>
</main>
